export const colMetricList = [
  {
    metricName: "Data Timeliness",
    tableClass: "bg-info",
    textClass: "tbl-title bg-info",
    span: "1",
    subMetrics: [
      {
        metricName: "Recency",
        tableClass: "bg-light-info",
        textClass: "",
        content: "The metric ensures that the data is timely and up-to-date.",
        // This can be enabled or disabled at the data source and  dataset level.",
      }
    ],
  },
  {
    metricName: "Data Completeness",
    tableClass: "bg-orange",
    textClass: "tbl-title bg-orange",
    span: "2",
    subMetrics: [
      {
        metricName: "Fill Rate",
        tableClass: "bg-light-orange",
        textClass: "",
        content: "This gives the (%) of non-empty values for a specific dataset or attribute.",
        //  This metric  can be enabled or disabled at the data source, dataset, or attribute level, giving you \
        //  multiple options for monitoring data completeness.",
      },
      {
        metricName: "Row Count",
        tableClass: "bg-light-orange",
        textClass: "",
        content: "This gives the number of rows in the dataset.",
        // This metric  can be enabled or disabled at the data source, dataset, or attribute level, \
        // giving you multiple options for monitoring data completeness.",
      }
    ]
  },

  {
    metricName: "Data Accuracy",
    tableClass: "bg-success",
    textClass: "tbl-title bg-success",
    span: "1",
    subMetrics: [
      {
        metricName: "Data Outlier",
        tableClass: "bg-light-success",
        textClass: "",
        content: "This is the (%) outliers or anomalies in the data of numeric attributes.",
        // You can enable or disable this metric at the data source, dataset, or Numerical  attribute level.",
      }]
  },
  {
    metricName: "Data Drift",
    tableClass: "bg-royalblue",
    textClass: "tbl-title bg-royalblue",
    span: "1",
    subMetrics: [
      {
        metricName: "Data Drift",
        tableClass: "bg-light-royalblue",
        textClass: "",
        content: "This is the (%) of  drifts in the distribution of data over time in Numerical attributes.",
        // You can enable or disable this metric at the data source, dataset, or Numerical  attribute level.",
      }]
  },
  {
    metricName: "Data Uniqueness",
    tableClass: "bg-brown",
    textClass: "tbl-title bg-brown",
    span: "1",
    subMetrics: [
      {
        metricName: "Unique Values",
        tableClass: "bg-light-brown",
        textClass: "",
        content: "This is the Number of distinct attribute values and unique rows within a dataset.",
        // You can enable or disable this metric at the data source, dataset, or  attribute level.",
      }]
  },
  {
    metricName: "Data Conformity",
    tableClass: "bg-warning",
    textClass: "tbl-title bg-warning",
    span: "9",
    subMetrics: [
      {
        metricName: "Boolean Conformity",
        tableClass: "bg-light-warning",
        textClass: "",
        content: "This is the (%) of non-Boolean values in fields meant to represent Boolean data. ",
      },
      {
        metricName: "Credit Card Conformity",
        tableClass: "bg-light-warning",
        textClass: "",
        content: "This is the (%) of invalid credit-card numbers in the fields meant to store credit card data.",
      },
      {
        metricName: "Date Conformity",
        tableClass: "bg-light-warning",
        textClass: "",
        content: "This is the (%) of invalid Date in the Fields meant to store Date Data.",
      },
      {
        metricName: "Email Conformity",
        tableClass: "bg-light-warning",
        textClass: "",
        content: "This is the (%) of invalid Email values in the  fields meant to store Email data.",
      },
      {
        metricName: "Gender Conformity",
        tableClass: "bg-light-warning",
        textClass: "",
        content: "This is the (%) of invalid Gender values in the fields meant to store Gender data.",
      },
      {
        metricName: "Http Conformity",
        tableClass: "bg-light-warning",
        textClass: "",
        content: "This is the (%) of invalid Http values in the fields meant to store Http data.",
      },
      {
        metricName: "IP Conformity",
        tableClass: "bg-light-warning",
        textClass: "",
        content: "This is the (%) of invalid IP addresses in the fields meant to store IP-addresses.",
      },
      {
        metricName: "SSN Conformity",
        tableClass: "bg-light-warning",
        textClass: "",
        content: "This is the (%) of invalid SSN Numbers in the fields meant to store SSN numbers.",
      },
      {
        metricName: "URL Conformity",
        tableClass: "bg-light-warning",
        textClass: "",
        content: "This is the (%) of invalid URLs  in the fields meant to store URL values.",
      },
    ]
  },

  {
    metricName: "Data Consistency",
    tableClass: "bg-purple",
    textClass: "tbl-title bg-purple",
    span: "3",
    subMetrics: [
      {
        metricName: "Categorical Encode",
        tableClass: "bg-light-purple",
        textClass: "",
        content: "This is the (%) of values in the target dataset that match categories in the base dataset.",
      },
      {
        metricName: "Data Type Consistency",
        tableClass: "bg-light-purple",
        textClass: "",
        content: "This is the (%) of inconsistent data-types between Target and Base datasets.",
      },
      {
        metricName: "Normalization Consistency",
        tableClass: "bg-light-purple",
        textClass: "",
        content: "This is the (%) of numeric attributes when not scaled similarly between base & target datasets.",
      }
    ]
  },
]

export const metricClassMapping = {
  "Fill Rate": { "tableClass": "bg-light-orange" },
  "Row Count": { "tableClass": "bg-light-orange" },
  "Data Consistency": { "tableClass": "bg-light-purple" },
  "Categorical Encode": { "tableClass": "bg-light-purple" },
  "Data Type Consistency": { "tableClass": "bg-light-purple" },
  "Normalization Consistency": { "tableClass": "bg-light-purple" },
  "Data Timeliness": { "tableClass": "bg-light-info" },
  "Recency": { "tableClass": "bg-light-info" },
  "Data Conformity": { "tableClass": "bg-light-warning" },
  "Boolean Conformity": { "tableClass": "bg-light-warning" },
  "Credit Card Conformity": { "tableClass": "bg-light-warning" },
  "Date Conformity": { "tableClass": "bg-light-warning" },
  "Email Conformity": { "tableClass": "bg-light-warning" },
  "Gender Conformity": { "tableClass": "bg-light-warning" },
  "Http Conformity": { "tableClass": "bg-light-warning" },
  "IP Conformity": { "tableClass": "bg-light-warning" },
  "SSN Conformity": { "tableClass": "bg-light-warning" },
  "URL Conformity": { "tableClass": "bg-light-warning" },
  "Data Accuracy": { "tableClass": "bg-light-success" },
  "Data Outlier": { "tableClass": "bg-light-success" },
  "Data Drift": { "tableClass": "bg-light-royalblue" },
  "Data Uniqueness": { "tableClass": "bg-light-brown" },
  "Unique Values": { "tableClass": "bg-light-brown" },
}

export const timeRange = [
  { "label": "GMT+05:30", "value": "title"," _disabled": true, "className": "disabled" },
  { "label": '12.00 AM', "value": '00:00:00' },
  { "label": '01.00 AM', "value": '01:00:00' },
  { "label": '02.00 AM', "value": '02:00:00' },
  { "label": '03.00 AM', "value": '03:00:00' },
  { "label": '04.00 AM', "value": '04:00:00' },
  { "label": '05.00 AM', "value": '05:00:00' },
  { "label": '06.00 AM', "value": '06:00:00' },
  { "label": '07.00 AM', "value": '07:00:00' },
  { "label": '08.00 AM', "value": '08:00:00' },
  { "label": '09.00 AM', "value": '09:00:00' },
  { "label": '10.00 AM', "value": '10:00:00' },
  { "label": '11.00 AM', "value": '11:00:00' },
  { "label": '12.00 PM', "value": '12:00:00' },
  { "label": '01.00 PM', "value": '13:00:00' },
  { "label": '02.00 PM', "value": '14:00:00' },
  { "label": '03.00 PM', "value": '15:00:00' },
  { "label": '04.00 PM', "value": '16:00:00' },
  { "label": '05.00 PM', "value": '17:00:00' },
  { "label": '06.00 PM', "value": '18:00:00' },
  { "label": '07.00 PM', "value": '19:00:00' },
  { "label": '08.00 PM', "value": '20:00:00' },
  { "label": '09.00 PM', "value": '21:00:00' },
  { "label": '10.00 PM', "value": '22:00:00' },
  { "label": '11.00 PM', "value": '23:00:00' },
]


export const DAYS = [
  { "label": "MONDAY", "value": "Monday" },
  { "label": "TUESDAY", "value": "Tuesday" },
  { "label": "WEDNESDAY", "value": "Wednesday" },
  { "label": "THURSDAY", "value": "Thursday" },
  { "label": "FRIDAY", "value": "Friday" },
  { "label": "SATURDAY", "value": "Saturday" },
  { "label": "SUNDAY", "value": "Sunday" }
]

export const METRIC_ORDER = [
  "Recency",

  "Fill Rate",
  "Row Count",

  "Data Outlier",

  "Data Drift",

  "Unique Values",

  "Boolean Conformity",
  "Credit Card Conformity",
  "Date Conformity",
  "Email Conformity",
  "Gender Conformity",
  "Http Conformity",
  "IP Conformity",
  "SSN Conformity",
  "URL Conformity",

  "Categorical Encode",
  "Data Type Consistency",
  "Normalization Consistency",

]

export const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

export const FULL_DAY_FORMAT = {
  "Sun": 'Sunday',
  "Mon": 'Monday',
  "Tue": 'Tuesday',
  "Wed": 'Wednesday',
  "Thu": 'Thursday',
  "Fri": 'Friday',
  "Sat": 'Saturday'
}

export const REGEX_METRIC = ["Date Conformity", "Email Conformity", "Gender Conformity", "IP Conformity", "URL Conformity"]


export const DEFAULT_CHECKS = {
  "Fill Rate": [{
                  "name": "not_null_check",
                  "value": '"NA",""'
                }],

  "Date Conformity": [{ "name": "date_check" }],

  "Email Conformity": [{
                          "name": "email_check",
                          "value": ""
                      }],

  "Gender Conformity": [{ "name": "gender_check" }],

  "IP Conformity": [{ "name": "ip_address_check" }],

  "URL Conformity": [{ "name": "url_check" }],
}

// export const DATA_CONSISTENCY_METRICS = ["Categorical Encode", "Data Type Consistency", "Normalization Consistency"]
