import React from 'react';
import Toast from 'react-bootstrap/Toast';
import NavigationHeader from '../components/navigationHeader';
import BreadCrumb from '../components/breadCrumb';
import Tab from 'react-bootstrap/Tab';
import { getApi, postUIlogs } from "../utils/event_handling";
import { IS_NONE_CHECK } from "../utils/constant";
import Nav from 'react-bootstrap/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastObj, getFeatureAccess } from "../utils/common_utils";
import DQMetrics from "./dqMetrics";
import ModelMetrics from "./mlMetrics";
import ErrorHandler from '../components/error_500';
import { CopyRightsFooter } from '../components/copyrights';
import { postMixpanel } from '../mixpanel';
import { logoutPageRedirect } from "../utils/common_utils";


class Metrics extends React.Component {
    constructor(props) {
        super(props);
        this.getApi = getApi.bind(this);
        this.postUIlogs = postUIlogs.bind(this);
        this.showToast = this.showToast.bind(this);
        this.closeToast = this.closeToast.bind(this);
        this.handleTab = this.handleTab.bind(this);

        this.progressLoader = this.progressLoader.bind(this);
        this.state = {
            dqmData: "",
            mlmData: "",
            customMetricDqmData: null,
            featureAccess: getFeatureAccess(),
            customMetricMlmData: null,
            activateKey: "dqm",
            errorOccurred: false,
            modelOptions: null,
            enabledMetrics: [],
            isConsistencyEnabled: false,
            integration_option: null,
            details: {
                data_set: [],
                attributes: []
            },
            toastStatus: false,
            filterData: null,
            metaDataMapping: null,
            sleepInterval: "",
            latestIntegrationId: null,
            loader: false,
            disable: true

        }
    }

    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        this.postUIlogs(error, info);
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.metaDataList) !== JSON.stringify(this.props.metaDataList)) {
            this.setState({
                filterData: this.props.filterData,
                metaDataMapping: this.props.metaDataMapping,
                disable: this.props.disable
            })
        }
    }
    componentDidMount() {
        let logoutPage = logoutPageRedirect()
        if (logoutPage) {
            return ''
        }
        this.getData();
    }

    getData() {
        this.getApi("dd_sleep_time_fetch");
        this.getApi("filterData");
        this.getApi("completeMetricInfo");
        this.getApi("data_set");
        this.getApi("integrationOptions");
        this.getApi("checkConsistency");
        this.getApi("customMetricProcessInfo");

        let hideModelMetricsTab = true;

        if (!IS_NONE_CHECK.includes(this.state.featureAccess)) {
            const modelMetrics = this.state.featureAccess["models"];
            hideModelMetricsTab = modelMetrics === false;
        }

        if (hideModelMetricsTab) {
            // When models feature is disabled, no need to call model related apis.
            return;
        }

        this.getApi("models");
    }

    closeToast() {
        this.setState({ toastStatus: false });
    }

    handleTab(key) {
        postMixpanel("Metrics -> " + key + " Tab Page")
        this.setState({ activateKey: key }, console.log("metrics-key", key))
    }

    showToast(status, msg, link) {
        this.getData();
        this.setState({
            toastStatus: true,
            toastMsg: msg,
            toastClass: toastObj[status]["className"],
            toastLink: link,
            toastIcon: toastObj[status]["icon"],
            toastHeading: toastObj[status]["heading"]
        });
    }

    progressLoader() {
        this.setState({ loader: !this.state.loader })
    }
    render() {
        let logoutPage = logoutPageRedirect()
        if (logoutPage) {
            return ''
        }
        let hideModelMetricsTab = true;

        if (!IS_NONE_CHECK.includes(this.state.featureAccess)) {
            const modelMetrics = this.state.featureAccess["models"];
            hideModelMetricsTab = modelMetrics === false;
        }
        return (
            // this.state.errorOccurred ?
            //     <ErrorHandler />
                // :
                <>
                {this.state.errorOccurred ? <ErrorHandler/> : ""}
                <div id="toastMsg">
                        <Toast className={this.state.toastClass}
                            onClose={() => this.closeToast()}
                            show={this.state.toastStatus}
                            delay={3000} autohide>
                            <div className="d-flex align-items-center">
                                {this.state.toastStatus ?
                                    <i className="toast-icon">
                                        <FontAwesomeIcon icon={this.state.toastIcon} />
                                    </i>
                                    :
                                    ''
                                }

                                <Toast.Body>
                                    <strong className="toast-title">{this.state.toastHeading}</strong>
                                    <p>{this.state.toastMsg}</p>
                                </Toast.Body>
                                <button type="button" onClick={this.closeToast} className="close" data-dismiss="toast" aria-label="Close" />
                            </div>
                        </Toast>
                    </div>
                    <NavigationHeader page="metrics" loader={this.state.loader} />
                    <main>
                        <BreadCrumb icon="cube" title="Metrics" />
                        <Tab.Container defaultActiveKey="dqm" onSelect={this.handleTab}>
                            <div className="qd-tab__header">
                                <div className="container-fluid">
                                    <div className="qd-tab__header-wrapper">
                                        <div className="qd-tab__header-menu">
                                            <Nav variant="tabs">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="dqm">
                                                        <span className="qd-menu__link-text">
                                                            Data Quality Metrics
                                                        </span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                {hideModelMetricsTab ? "" : <Nav.Item>
                                                    <Nav.Link eventKey="mlm">
                                                        <span className="qd-menu__link-text">
                                                            Model Metrics
                                                        </span>
                                                    </Nav.Link>
                                                </Nav.Item>}
                                            </Nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="qd-container">
                                <div className="qd-body">
                                    <div className="qd-tab__content" id={this.state.activateKey + "Tab"}>
                                        <Tab.Content>
                                            <Tab.Pane mountOnEnter={true} eventKey="dqm">
                                                <DQMetrics key={"dqm"}
                                                    datasetInfo={this.state.details}
                                                    dqmData={this.state.dqmData}
                                                    customMetricDqmData={this.state.customMetricDqmData}
                                                    integrationOption={this.state.integration_option}
                                                    enabledMetrics={this.state.enabledMetrics}
                                                    isConsistencyEnabled={this.state.isConsistencyEnabled}
                                                    showToast={this.showToast}
                                                    progressLoader={this.progressLoader}
                                                    filterData={this.state.filterData}
                                                    metaDataMapping={this.state.metaDataMapping}
                                                    disable={this.state.disable}
                                                    sleepInterval={this.state.sleepInterval}
                                                // existingIntegrations = {this.state.existingIntegrations}
                                                // metaDataList = {this.state.metaData}
                                                // loader = {this.state.loader}

                                                />
                                            </Tab.Pane>
                                            {hideModelMetricsTab ? "" : <Tab.Pane mountOnEnter={true} eventKey="mlm">
                                                <ModelMetrics key={"mlm"}
                                                    datasetInfo={this.state.details}
                                                    modelOptions={this.state.modelOptions}
                                                    mlmData={this.state.mlmData}
                                                    customMetricMlmData={this.state.customMetricMlmData}
                                                    showToast={this.showToast} />
                                            </Tab.Pane>
                                            }
                                        </Tab.Content>
                                    </div>
                                </div>
                            </div>
                        </Tab.Container>
                    </main>
                    <CopyRightsFooter />
                </>
        );
    }
}

export default Metrics;
