import { ui_service_ip } from "./ip_details";
import {
    getCompleteEndpoint, getProtocol,
    removeLocalStorageKeys, checkExpiryPowerBI
} from "./common_utils";
import $ from 'jquery';

export function call_post_api(ip, end_point, data, token = localStorage.getItem("sessionToken")) {
    let user_id = localStorage.getItem('user_id');
    let team_id = localStorage.getItem('team_id');
    if (token !== "" && token !== null && token !== undefined) {
        const item = JSON.parse(token);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            if (checkExpiryPowerBI() === false) {
                removeLocalStorageKeys();
                window.location = '/';
            }
        }
        token = item.value;
    }
    post_api_call_cnt++;
    let protocol = getProtocol(ip);
    const reqEndPoint = getCompleteEndpoint(protocol, ip, end_point);
    return fetch(reqEndPoint, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', 'api-type': 'qualdo_db_auth', 'authToken': token, "userId": user_id, "teamId": team_id }
    }).then((response) => response.json()).then((responseData) => {
        post_api_call_complete_cnt++;
        return responseData;
    }).catch((error) => {
        post_api_call_complete_cnt++;
        console.error(end_point, "- failed")
    }
    );
}


function call_update_api(ip, end_point, data, token = localStorage.getItem("sessionToken")) {
    let user_id = localStorage.getItem('user_id');
    let team_id = localStorage.getItem('team_id');
    if (token !== "" && token !== null && token !== undefined) {
        const item = JSON.parse(token);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            if (checkExpiryPowerBI() === false) {
                removeLocalStorageKeys();
                window.location = '/';
            }
        }
        token = item.value;
    }
    let protocol = getProtocol(ip);
    const reqEndPoint = getCompleteEndpoint(protocol, ip, end_point);
    return fetch(reqEndPoint, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json', 'api-type': 'qualdo_db_auth', 'authToken': token,
            "userId": user_id, "teamId": team_id
        }
    }).then((response) => response.json()).then((responseData) => {
        return responseData;
    }).catch(error => console.error(end_point, "- failed"));
}


export let get_api_call_cnt = 0;
export let get_api_call_complete_cnt = 0;

export let post_api_call_cnt = 0;
export let post_api_call_complete_cnt = 0;


export function call_get_api(ip, end_point, data, token = localStorage.getItem("sessionToken")) {
    let session = localStorage.getItem('sessionToken');
    let user_id = localStorage.getItem('user_id');
    let team_id = localStorage.getItem('team_id');
    if (session !== "" && session !== null && session !== undefined) {
        const item = JSON.parse(session);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            if (checkExpiryPowerBI() === false) {
                localStorage.removeItem("sessionToken");
                window.location = '/';
            }
        }
        session = item.value;
    }
    get_api_call_cnt++;
    let protocol = getProtocol(ip);
    const reqEndPoint = getCompleteEndpoint(protocol, ip, end_point);
    return fetch(reqEndPoint, {
        method: 'GET',
        headers: { "data": JSON.stringify(data), 'Content-Type': 'application/json', 'api-type': 'qualdo_db_auth', 'authToken': session, "userId": user_id, "teamId": team_id }
    }).then((response) => response.json()).then((responseData) => {
        get_api_call_complete_cnt++;
        return responseData;
    }).catch((error) => {
        get_api_call_complete_cnt++;
        console.error(reqEndPoint, JSON.stringify(error));
    });
}

export function postUIlogs(error, info) {
    let session = localStorage.getItem("sessionToken");
    let postError = { "ui_logs": { "message": error.toString(), "info": info } }
    call_post_api(ui_service_ip, "/ui_logs", postError, session).then((res) => {
    });

}



export function postBulkScheduleData(data, resetForm = null) {

    let timeRange = this.state.timeRange.map(data => ({
        ...data,
        checked: false
    })
    )

    let applicableDatasets = this.state.applicableDatasets.map((data) => ({
        ...data,
        checked: false
    }))


    let session = localStorage.getItem("sessionToken");
    call_post_api(ui_service_ip, "/post_scheduled_metrics_info", data, session).then((res) => {

        if (res['code'] === 200) {
            this.setState({
                // selectedIntegration: this.state.selectedDatasourceId,
                // selectedDatasource: this.state.selectedDatasourceName,
                isOpen: false,
                selectedEnv: null,
                selectedIntegration: [],
                selectedDatasets: [],
                selectedDatasource: null,
                selectedScheduleDay: [],
                defaultSchedule: false,
                defaultSchedule1: false,
                selectedScheduleTime: [],
                startDate: this.state.startDate,
                currentDay: this.state.currentDay,
                recurrence: { "label": "Daily", "value": "Daily" },
                weekdaysClasses: {},
                timeRange: timeRange,
                bulkScheduleConfirmation: false,
                chipData: [],
                applicableDatasets: applicableDatasets,
            })

            if (resetForm !== null) {
                this.getMetricsMetadata(data['integration_id'])
                this.showToast("alert-success", "Bulk Schedule added successfully", '')
                resetForm();
            }
        }
        else {
            this.setState({ bulkScheduleConfirmation: false, isOpen: true })
            this.showToast("alert-danger", res.message, '')
        }
    })
}

export function getMetricsMetadata(id = null) {
    let data = { "tenant_id": Number(localStorage.getItem('team_id')) }

    if (id !== null) {
        data = { "tenant_id": Number(localStorage.getItem('team_id')), "integration_id": id }
    }

    this.setState({ loader: true })
    call_get_api(ui_service_ip, "/scheduled_attr_info", data).then((res) => {
        if (res !== undefined && res !== null && res['metaData'] !== undefined && res !== undefined && res !== null && res['metaData'] !== null) {
            let existingIntegrations = [];
            if (res['integrations_details'] !== undefined && res['integrations_details'] !== null && res['integrations_details'].length > 0) {
                existingIntegrations = res['integrations_details'].map((data) => { return data.value })
            }
            this.setState({
                existingIntegrations: existingIntegrations,
                metaDataList: res['metaData'],
                filteredData: [],
                loader: false,
            })
            if (id === null) {
                this.setState({
                    showDQEnableFullscreen: !this.state.showDQEnableFullscreen,
                    disable : !this.state.disable
                })
                this.props.progressLoader();
            }
        }
    });
}

// /update_scheduled_metric_values
export function postToggleAction(data) {
    call_update_api(ui_service_ip, "/update_scheduled_metric_values", data).then((res) => {
        if (res['code'] === 200) {

            if (data['update_type'] === "dataset_status") {
                let dataset = this.state.dataset;
                $(this.state.toggleId).prop("checked", data['checked']);
                if (data['all_attributes'] === true) {
                    for (let i = 0; i < dataset['attribute_list'].length; i++) {
                        let id = "#attributeToggle" + dataset['attribute_list'][i]['attribute_id'] + data['metrics_id']
                        $(id).prop("checked", data['checked']);
                    }
                }
                this.setState({
                    showConfirmation: false, allAttributesChecked: true
                })
            }
            else if (data['update_type'] === "integration_status") {
                let metaData = this.state.data
                $(this.state.toggleId).prop("checked", data['checked']);
                for (let i = 0; i < metaData['dataset_list'].length; i++) {
                    let datasetId = "#datasetToggle" + metaData['dataset_list'][i]['dataset_id'] + data['metrics_id']
                    $(datasetId).prop("checked", data['checked']);
                    for (let j = 0; j < metaData['dataset_list'][i]['attribute_list'].length; j++) {
                        let attributeId = "#attributeToggle" + metaData['dataset_list'][i]['attribute_list'][j]['attribute_id'] + data['metrics_id']
                        $(attributeId).prop("checked", data['checked']);
                    }
                }
                this.setState({
                    showConfirmation: false
                })
            }
            // if (data['update_type'] !== "attribute_status") {
            this.getMetricsMetadata(data['integration_id']);
            // }
            this.showToast("alert-success", "Successfully Updated", '')

        }
        else {

            this.showToast("alert-danger", res.message, '')
        }
    });
}

export function putThresholdData(data, className) {

    const metricEditEndPoint = "metrics/consistency";
    if(data['target_data_set_id'] !== undefined && data['target_data_set_id'] !== null ){
        let consistencyMetricData = {
                    tenant_id:data['tenant_id'],
                    metrics_list:[
                                    {
                                        metrics_id : data['metrics_id'],
                                        data_set_id : data['data_set_id'],
                                        target_data_set_id : data['target_data_set_id'],
                                        tenant_id : "1500"
                                    }
                                ]
                    }
        call_post_api(ui_service_ip, metricEditEndPoint, consistencyMetricData).then((res) => {
                console.log(".then((res) => {", res)
            }
        );
    }

    call_update_api(ui_service_ip, "/update_scheduled_metric_values", data).then((res) => {
        if (res['code'] === 200) {
            this.getMetricsMetadata(data['integration_id'])
            this.showToast("alert-success", "Schedule Updated successfully", '')

            // if (data['all_attributes'] !== true) {
            $(className).eq(0).trigger("click");
            // }

            this.setState({
                check_value: null,
                threshold_value: null,
                defaultSchecule: "",
                color: "",
                allAttributes: null,
                selectedScheduleTime: [],
                selectedScheduleDay: {},
                defaultSchedule: false,
                startDate: this.state.startDate,
                currentDay: this.state.currentDay,
                recurrence: { "label": "Daily", "value": "Daily" },
                weekdaysClasses: {},
                datasetScheduleConfirmation: false,
                checked: true,
                disableEditConfirmation: false
            })
        }
        else {
            this.setState({disableEditConfirmation: false})
            this.showToast("alert-danger", res.message, '')
        }
    });
}
