import React from 'react';
import BasicPortlet from '../components/basicPortlet';
import QualdoDataTable from '../components/bootstrapTable';
import CardGroup from "../components/cardGroup";
import { handleMetricFollow } from "../utils/event_handling";
import { getMetricsMetadata } from '../utils/metricEventHandling.js';

import {
	formMetricsCardData, getMetricsTableData, getDefaultMetricsTableData,
	getConsistencyCardDetails, checkPermissions,
	decryptForLocalUsage
} from "../utils/common_utils";
import Image from "../components/image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCloudUploadAlt, faArrowRight,faTable,
	// faLongArrowAltRight
} from "@fortawesome/free-solid-svg-icons";
import DataQualityMetricsForm from "./components/DataQualityMetricsForm";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Load from '../components/loadAction';
import Modal from "react-bootstrap/Modal";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { faCube, faCubes } from "@fortawesome/free-solid-svg-icons";
import ConsistencyMetrics from '../components/consistencyMetrics';
import { DATA_CONSISTENCY, PERMISSIONS_LOCAL_STORE_KEY } from "../utils/constant.js";
import RefreshesTable from "../components/refreshesTable";
import DQMetricsFullscreen from './dqMetricsFullScreen';
import { getApi } from "../utils/event_handling";


let activeMetrics = {}

class DQMetrics extends React.Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.showConfigureConsistency = this.showConfigureConsistency.bind(this);
		this.renderCard = this.renderCard.bind(this);
		this.handleCloseConfigure = this.handleCloseConfigure.bind(this);
		this.closeFullScreen = this.closeFullScreen.bind(this);
		this.getMetricsMetadata = getMetricsMetadata.bind(this)
        this.getApi = getApi.bind(this);
		// this.progressLoader = this.props.progressLoader(this);

		this.state = {
			activeTabKey: "dq_metrics_default",
			selected_metric_name: null,
			check: false,
			consistencyConfigure: false,
			plan_details: localStorage.getItem("plan_details"),
			title: "Data Quality Metrics",
			text: "Here, you can either edit qualdo-generated default metrics or create a user-defined data quality metric.",
			seemore_table_data: null,
			seemore_table_header: null,

			showDQEnableFullscreen: false,
			filterData:this.props.filterData,
			metaDataMapping:this.props.metaDataMapping,
			sleepInterval: "",
			metaDataList: [],
			loader: true,
			existingIntegrations:[],
			disable: this.props.disable,
			// activeMetrics:{},
		}
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps){
		if(prevProps.disable !== this.props.disable){
			this.setState({disable:!this.state.disable})
		}
	}
	handleClick() {
		if (this.state.check === true) {
			this.setState({ check: false });
		} else {
			this.setState({ check: true });
		}
	}

	handleCloseConfigure() {
		this.setState({ consistencyConfigure: false });
	}

	showConfigureConsistency(metric_name) {
		this.setState({ consistencyConfigure: true, selected_metric_name: metric_name });
	}

	getConsistencyMetricList(data) {
		let dataConsistencyMetrics = [];
		if (data !== undefined && data.length > 0) {
			let dataConsistency = data.filter(x => x.mas_name === DATA_CONSISTENCY);
			if (dataConsistency !== undefined && dataConsistency.length > 0) {
				dataConsistencyMetrics = dataConsistency[0].metrics;
				dataConsistencyMetrics = dataConsistencyMetrics.filter(x => (x.metric_type === "default" && x.data_set_name === null));
			}
		}
		return dataConsistencyMetrics;
	}

	handleDQEnableFullscreen = () => {
		// $(".qd-fullscreen").removeClass("d-none")
		// this.getApi("filterData");
        this.getApi("dd_sleep_time_fetch");
		this.props.progressLoader();
		this.getMetricsMetadata();
		this.setState({disable : !this.state.disable})

	}

	closeFullScreen() {
		// $(".qd-fullscreen").addClass("d-none")
		this.setState({showDQEnableFullscreen:!this.state.showDQEnableFullscreen})
	}
	renderCard() {
		let showToast = this.props.showToast;
		const dqmData = this.props.dqmData;
		let additionalDetails = {
			"data": dqmData,
			"datasetInfo": this.props.datasetInfo
		}
		let enabledMetrics = this.props.enabledMetrics;
		let isConsistencyEnabled = this.props.isConsistencyEnabled;
		let showConfigureConsistency = this.showConfigureConsistency;
		if (dqmData !== "") {
			let tableData_default = getDefaultMetricsTableData(this.props.dqmData);

			let table_title_seemoretable = "CONFIGURED DATA CONSISTENCY METRICS";
			let integrationOption_seemoretable = this.props.integrationOption;
			let showToast_seemoretable = this.props.showToast;
			let dqmData_seemoretable = this.props.dqmData;
			let datasetInfo_seemoretable = this.props.datasetInfo;
			let tableData_default_seemoretable = tableData_default;


			return dqmData.map(function (row, index) {
				let cardData = formMetricsCardData(row.metrics);

				cardData.map((data)=>{
					activeMetrics[data['metric_name']] = data['status_code']
					return null
				})

				// activeMetrics.push(cardData)
				if (row.mas_name === DATA_CONSISTENCY) {
					let consistencyCardDetails = getConsistencyCardDetails(dqmData)
					cardData.forEach(function (card) {
						if (isConsistencyEnabled === false || !enabledMetrics.includes(card['metric_name'])) {
							card.status_code = 0;
						}
					});
					cardData["consistency_count"] = consistencyCardDetails;

				}

				if (row.mas_name === DATA_CONSISTENCY) {
					if (tableData_default_seemoretable.data.length > 0) {
						return (<BasicPortlet className="pb-0"
							title={row.mas_name + " - Qualdo Generated Metrics"}
							key={row.mas_name}
							mas_name={row.mas_name}
							id="dqMetricsCards"
							bodyClassName="pb-0"
							showConfigureConsistency={showConfigureConsistency}
							content={
								<>
									<CardGroup
										rowNo={index}
										key={row.mas_name}
										data={cardData}
										showConfigureConsistency={showConfigureConsistency}
										showFollow={true}
										handleCardFollow={handleMetricFollow}
										showToast={showToast}
										additionalDetails={additionalDetails}
										component_name="metrics" />
									<hr />
									<h4 class="mb-3">CONFIGURED DATA CONSISTENCY METRICS</h4>
									{datasetInfo_seemoretable.data_set.length !==0 ? <QualdoDataTable
										id="dqMetricsCards"
										key={table_title_seemoretable}
										data={tableData_default_seemoretable}
										integrationOption={integrationOption_seemoretable}
										paginationSize={3}
										modalTitle={"Custom - Data Quality Metric"}
										showToast={showToast_seemoretable}
										isSeeMore={true}
										additional_details={dqmData_seemoretable}
										datasetInfo={datasetInfo_seemoretable}
										component_name="default_metrics" />: <div className="text-center text-muted py-5">
                                                       <h1><i className="opacity-50"><FontAwesomeIcon icon={faTable} /></i></h1>
                                                       <h4>No data available</h4>
                                                   </div>}
								</>

							}
						/>);
					} else {
						return (<BasicPortlet className="pb-0"
							title={row.mas_name + " - Qualdo Generated Metrics"}
							key={row.mas_name}
							mas_name={row.mas_name}
							id="dqMetricsCards"
							bodyClassName="pb-0"
							showConfigureConsistency={showConfigureConsistency}
							content={
								<CardGroup
									rowNo={index}
									key={row.mas_name}
									data={cardData}
									showConfigureConsistency={showConfigureConsistency}
									showFollow={true}
									handleCardFollow={handleMetricFollow}
									showToast={showToast}
									additionalDetails={additionalDetails}
									component_name="metrics" />

							}
						/>);

					}

				} else {

					return (<BasicPortlet className="pb-0"
						title={row.mas_name + " - Qualdo Generated Metrics"}
						key={row.mas_name}
						mas_name={row.mas_name}
						id="dqMetricsCards"
						bodyClassName="pb-0"
						showConfigureConsistency={showConfigureConsistency}
						content={
							<CardGroup
								rowNo={index}
								key={row.mas_name}
								data={cardData}
								showConfigureConsistency={showConfigureConsistency}
								showFollow={true}
								handleCardFollow={handleMetricFollow}
								showToast={showToast}
								additionalDetails={additionalDetails}
								component_name="metrics" />

						}
					/>);



				}
			});
			// this.props.getActiveMetrics(activeMetrics)

		}
	}


	renderTable(tab, allDataAvailable, tableData) {
		let title = "CONFIGURED DATA CONSISTENCY METRICS"
		if (tab === "dq_metrics_custom") {
			title = "Custom - User Defined Data Quality Metrics";
		}

		const emptyTable = tableData.data.length === 0 && this.props.dqmData !== "";

		if (emptyTable || allDataAvailable) {
			return (
				<BasicPortlet className="pb-0"
					id="dqMetricsTable"
					title={title}
					content={tableData.data.length !== 0 ? <QualdoDataTable
						id="dqMetricsTable"
						key={this.state.title}
						data={tableData}
						integrationOption={this.props.integrationOption}
						paginationSize={3}
						modalTitle={"Custom - Data Quality Metric"}
						showToast={this.props.showToast}
						additional_details={this.props.dqmData}
						datasetInfo={this.props.datasetInfo}
						component_name="metrics" />: <div className="text-center text-muted py-5">
                                            <h1><i className="opacity-50"><FontAwesomeIcon icon={faTable} /></i></h1>
                                            <h4>No data available</h4>
                                        </div>}
				/>
			);
		} else {
			if (tab === "dq_metrics_custom") {
				return (<BasicPortlet className="pb-0"
					id="dqMetricsTableLoading"
					title={"Custom - User Defined Data Quality Metrics"}
					content={<Load />}
				/>);
			}
		}
	}

	renderCustomProcess(tab) {
		if (tab === "dq_metrics_custom") {
			let tableData = this.props.customMetricDqmData;
			let emptyTable = false;
			let isData = false;
			if (tableData !== undefined && tableData !== null) {
				if (tableData.data !== undefined && tableData.data !== null) {
					emptyTable = (tableData.data.length === 0 && this.props.dqmData !== "");
					if (tableData.data.length > 0) {
						isData = true;
					}
				}
			}
			if (emptyTable || isData) {
				return (<BasicPortlet
					className="mt-3" id="datasourceRefreshes"
					title="PROCESSING ERRORS & DATA REFRESH STATUS"
					content={
						this.state.datasource_updates !== null ?
							<RefreshesTable componentName="metrics"
								data={tableData}
								page={"custom_metrics"} />
							:
							<Load />

					}
				/>);
			} else {
				return (<BasicPortlet className="pb-0"
					id="dqMetricsTableLoading"
					title={"PROCESSING ERRORS & DATA REFRESH STATUS"}
					content={<Load />}
				/>);
			}
		}
	}

	goToChoosePlan = () => {
		window.location.href = "/choose-plan";
	}

	render() {
		let permissions = decryptForLocalUsage(localStorage.getItem(PERMISSIONS_LOCAL_STORE_KEY))
		let permissionsList = [];
		if (permissions !== undefined && permissions !== null) {
			permissionsList = permissions;
		}
		let consistencyMetricsList = this.getConsistencyMetricList(this.props.dqmData);
		let hide_choose_plan_button = true;
		if (permissionsList.includes('BillingAccess')) {
			hide_choose_plan_button = false;
		} else {
			hide_choose_plan_button = true;
		}

		let disableMetricsAction = checkPermissions('Metrics')

		let show_custom_metrics = false;
		let planDetails = this.state.plan_details;
		if (planDetails !== null && planDetails !== undefined
			&& planDetails !== "null" && String(planDetails).trim().length > 0) {
			let details_json = JSON.parse(planDetails);
			let details = details_json["details"]["supported_features"];
			if (details !== undefined && details !== null) {
				for (const [key, val] of Object.entries(details)) {
					if (key !== "custom_metrics") {
						continue;
					}

					let status = val["support"];
					show_custom_metrics = status;
					break;
				}
			}
		}

		let show_custom_metrics_modal = (this.state.check === true && show_custom_metrics === false);
		let tableData_default = getDefaultMetricsTableData(this.props.dqmData);
		let tableData_custom = getMetricsTableData(this.props.dqmData);
		let dataSet = this.props.datasetInfo.data_set;
		let attributeInfo = this.props.datasetInfo.attributes;
		const allDataAvailable = this.props.dqmData !== "" &&
			dataSet !== undefined && dataSet !== null &&
			dataSet.length > 0 &&
			attributeInfo !== undefined && attributeInfo !== null &&
			this.props.integrationOption !== null;
		let emptyTable = tableData_default.data.length === 0 && this.props.dqmData !== "";
		return (
			<>
				<Tab.Container defaultActiveKey={this.state.activeTabKey} onSelect={this.changeTab}>
					<div className="tabbable-line mb-3">
						<Nav variant="tabs" id="page-nav-tabs">
							<Nav.Item>
								<Nav.Link eventKey="dq_metrics_default">
									<span className="qd-menu__link-text">
										<i>
											<FontAwesomeIcon icon={faCube} />
										</i>  Default Metrics</span>
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="dq_metrics_custom">
									<span className="qd-menu__link-text"><i>
										<FontAwesomeIcon icon={faCubes} />
									</i>   Custom Metrics</span>
								</Nav.Link>
							</Nav.Item>
						</Nav>
					</div>

					<Modal show={this.state.consistencyConfigure} size="lg"
						centered onHide={this.handleCloseConfigure}>
						<Modal.Header closeButton>
							<h5 className="modal-title" id="exampleModalLongTitle">
								Data Consistency Configuration </h5>
						</Modal.Header>
						<Modal.Body> {
							<ConsistencyMetrics closeModal={this.handleCloseConfigure}
								metricName={this.props.name}
								selected_metric_name={this.state.selected_metric_name}
								metricId={this.props.id}
								details={this.props.datasetInfo}
								consistencyMetricsList={consistencyMetricsList}
								showToast={this.props.showToast} />

						}
						</Modal.Body>
					</Modal>

					<div className="tab-content">
						<Tab.Content>
							<Tab.Pane eventKey="dq_metrics_default">
								<div className="card mb-3">
									<div className="card-body">
										<div className="row align-items-center">
											<div className="col-md-7 d-flex align-items-stretch">
												<i className="d-inline-flex p-2 mr-3">
													<Image src="icon_metric_enable" />
												</i>
												<div className="d-flex flex-row align-items-center">
													<div>
														<h4 className="h5 mb-2">Schedule DQ Metrics</h4>
														<p className="mb-0">Here, you can either edit qualdo-generated default metrics or create a user-defined data quality metrics</p>
													</div>
												</div>
											</div>
											<div className="col-12 col-md-5 mt-4 mt-md-0 text-md-right">
												<button
													type="button"
													className="btn btn-primary btn-circle mr-2 d-inline-flex align-items-center"
													disabled={this.state.disable}
													onClick={this.handleDQEnableFullscreen}
												>
													Schedule DQ Metrics
													<i className="ml-2 mr-0 d-flex align-items-center">
														<FontAwesomeIcon icon={faArrowRight} />
													</i>
												</button>
											</div>
										</div>
									</div>
								</div>
								{(this.props.dqmData !== "" && (allDataAvailable || emptyTable)) ?
									<>
										{/* {this.renderTable("dq_metrics_default", allDataAvailable, tableData_default)} */}
										{this.renderCard()}
									</>
									:
									<BasicPortlet className="pb-0"
										bodyClassName="pb-0"
										id="dqMetricsCardsLoading"
										content={this.props.datasetInfo.data_set ===""?<Load />:this.renderCard()}
									/>
								}
							</Tab.Pane>
							<Tab.Pane eventKey="dq_metrics_custom">
								<Modal show={show_custom_metrics_modal} className="modal__upgrade text-center" onHide={this.handleClick} centered >
									<Modal.Header closeButton className="border-0 justify-content-center d-block">
										<h5 className="modal-title" id="updagrade_plan">
											You are currently on a Basic Plan (Free)</h5>
										<p className="text-muted">Upgrade now to use this feature</p>
										<button type="button" className="close" data-dismiss="modal" aria-label="Close" />
									</Modal.Header>
									<Modal.Body>
										<Image src="upgradePlanIcon" />
									</Modal.Body>
									<Modal.Footer className=" border-0 justify-content-center" centered>
										<button type="button" onClick={this.goToChoosePlan}
											className="btn btn-primary btn-circle" disabled={hide_choose_plan_button}>Choose another plan <FontAwesomeIcon className="mr-0 ml-2" icon={faArrowRight} /></button>
									</Modal.Footer>
								</Modal>
								<div className="card mb-3">
									<div className="card-body">
										<div className="row align-items-center">
											<div className="col-md-7 d-flex align-items-stretch">
												<i className="d-inline-flex p-2 mr-3">
													<Image src="dataQuality" />
												</i>
												<div className="d-flex flex-row align-items-center">
													<div>
														<h4 className="h5 mb-2">{this.state.title}</h4>
														<p className="mb-0">{this.state.text}</p>
													</div>
												</div>
											</div>
											<div className="col-12 col-md-5 mt-4 mt-md-0 text-md-right">
												<button disabled={disableMetricsAction} type="button" onClick={() => this.handleClick()}
													className="btn btn-primary btn-circle mr-2">
													<FontAwesomeIcon icon={faCloudUploadAlt} />
													{"Add Custom Data Quality Metrics"}
												</button>
											</div>
										</div>
									</div>
								</div>
								{this.state.check === false || show_custom_metrics === false ? "" :
									<div className="add-dataquality-content">
										<BasicPortlet
											id="addDqMetrics"
											className="mb-3" title={"Add a Custom Data Quality Metric"} bodyClassName="pb-0"
											content={<DataQualityMetricsForm showToast={this.props.showToast}
												details={this.props.datasetInfo}
												type={"dq"}
												integrationOption={this.props.integrationOption}
												mas_data={this.props.dqmData}
												handleClick={this.handleClick}
											/>}
										/>
									</div>
								}
								{this.renderTable("dq_metrics_custom", allDataAvailable, tableData_custom)}
								{this.renderCustomProcess("dq_metrics_custom")}
							</Tab.Pane>
						</Tab.Content>
					</div>
				</Tab.Container>
				{this.state.showDQEnableFullscreen
					?
					<DQMetricsFullscreen
						metaDataList={this.state.metaDataList}
						loader={this.state.loader}
						original={this.state.original}
						closeFullScreen={this.closeFullScreen}
						metaDataMapping={this.props.metaDataMapping}
						filterData={this.props.filterData}
						sleepInterval={this.props.sleepInterval}
						existingIntegrations={this.state.existingIntegrations}
						activeMetrics={activeMetrics}
					/>
					:
					''
				}

			</>);
	}
}

export default DQMetrics;
