import $ from "jquery";
import axios from 'axios';
import {browserHistory} from 'react-router';
import {iam_ip, ui_service_ip} from "./ip_details";
import moment from 'moment';
import {
    BRAIN_TREE_AUTH_TOKEN,
    chartDataCombinations,
    PROTOCOL, VALID_QUALDO_PLANS,
    QualdoPlanVersions, NO_DATA_SET_FOUND_TEXT,
    DEFAULT_METRICS_RUN_FAILURE, MAX_NOTIFICATIONS_PER_PAGE,
    IS_NONE_CHECK, MODEL_AB_TABLE_HEADERS,
    RUN_PROGRESS_STATES, RUN_SUCCESS, POWER_BI_CONNECTOR, LOCAL_STORE_KEY,
    MCF_SKIPPED,
    dataRunStatuses, PERMISSIONS_LOCAL_STORE_KEY
} from "./constant";
import {isEmpty} from "lodash";
import {
    convertDataForListView,
    getCompleteEndpoint, getProtocol, getTrimmedData,
    isPlanChanged, removeLocalStorageKeys, checkExpiryPowerBI, decryptForLocalUsage, encryptForLocalUsage
} from "./common_utils";
import {postMixpanel} from '../mixpanel';
// import {addFollowAttributes} from "../redux/actions";
import { normalizeChildDatasetName } from "./attribute_name_utils";

//eslint-disable-next-line
export const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

export const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}



export function call_post_api(ip, end_point,data, token=localStorage.getItem("sessionToken")) {
    let user_id = localStorage.getItem('user_id');
    let team_id = localStorage.getItem('team_id');
    if (token !== "" && token !== null && token !== undefined) {
        const item = JSON.parse(token);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            if(checkExpiryPowerBI()===false){
                removeLocalStorageKeys();
                window.location = '/';
            }
        }
        token = item.value;
    }
    post_api_call_cnt++;
    let protocol = getProtocol(ip);
    const reqEndPoint = getCompleteEndpoint(protocol, ip, end_point);
    return fetch(reqEndPoint, {
            method: 'POST',
            body: JSON.stringify(data),
            headers:{ 'Content-Type': 'application/json', 'api-type':'qualdo_db_auth', 'authToken':token, "userId": user_id, "teamId": team_id}
        }).then((response) => response.json()).then((responseData) => {
            post_api_call_complete_cnt++;
              return responseData;
        }).catch( (error) =>{
            post_api_call_complete_cnt++;
            console.error(end_point, "- failed")
        }
);
}
/*export function call_post_api_test(ip, end_point,data, token="") {
    if (token !== "" && token !== null) {
        const item = JSON.parse(token);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            localStorage.removeItem('sessionToken');
            window.location = '/';
        }
        token = item.value;
    }
    return fetch("http:"+'//'+ip+'/'+end_point, {
            method: 'POST',
            body: JSON.stringify(data),
            headers:{ 'Content-Type': 'application/json', 'api-type':'qualdo_db_auth', 'authToken':token }
        }).then((response) => response.json()).then((responseData) => {
              return responseData;
        }).catch( error => console.error(end_point, "- failed"));
}*/

function storeCachedData(data, key) {
    data["storage_time"] = Date.now();
    sessionStorage.setItem(key, JSON.stringify(data));
}

function getCachedData(key, maxAge = 20) {
    // Max age of the cached data is given in minutes

    let cachedData = null;
    let cachedDetails = sessionStorage.getItem(key);
    if (cachedDetails === undefined || cachedDetails === null) {
        return cachedData;
    }

    let cachedObj = JSON.parse(cachedDetails);
    let storageTime = cachedObj["storage_time"];
    let currentTime = new Date().getTime();
    let diffInMinutes = (currentTime - storageTime) / (1000 * 60);
    if (diffInMinutes > maxAge) {
        return cachedData;
    }

    return cachedObj;
}

function cachedIntegrationData(integrations, tenant_id) {
    if (integrations === undefined || integrations === null) {
        return;
    }

    let reducedIntegrations = integrations.map(x => ({
        "integration_id": x["integration_id"],
        "integration_name": x["integration_name"],
        "env_id": x["env_id"]
    }));
    const integrationKey = `integrations_${tenant_id}`;
    const integrationsToCache = {
        "integrations": reducedIntegrations
    };
    storeCachedData(integrationsToCache, integrationKey);

    let data = {"tenant_id": tenant_id,"grouped": true}
    call_get_api(ui_service_ip,"/datasets",data).then( (response) =>{
        if (response === undefined) {
                this.setState({errorOccurred: true});
        } else if (response.code === 200) {
            const datasets = response["datasets"];
            reducedIntegrations.forEach(element => {
                let integrationId  =  element["integration_id"];
                let correspondingDatasets = datasets[integrationId];
                cacheDatasets(correspondingDatasets, tenant_id, integrationId);
            });
        }
    });


}

function cacheEnvironmentData(environments, tenantId) {
    const environmentKey = `environments_${tenantId}`;
    const environmentData = {
        "environments": environments
    };
    storeCachedData(environmentData, environmentKey)
}

function cacheDatasets(datasets, tenantId, integrationID) {
    const datasetKey = `datasets_${tenantId}_${integrationID}`;
    if (datasets === undefined || datasets === null){
        return;
    }


    let reducedDatasets = [];
    if (datasets.length > 0) {
        reducedDatasets = datasets.map(x => ({
            "data_set_id": x["data_set_id"],
            "data_set_name": x["data_set_name"]
        }));
    }

    const datasetInfo = {
        "datasets": reducedDatasets
    };
    storeCachedData(datasetInfo, datasetKey)
}

function cacheMlModels(mlModels, tenantId) {
    const mlModelKey = `mlModels_${tenantId}`;
    if (mlModels === undefined || mlModels === null){
        return;
    }

    let reducedMlModels = mlModels.map(x => ({
        "ml_model_id": x["ml_model_id"],
        "model_name": x["model_name"]
    }));
    const mlInfo = {
        "mlModels": reducedMlModels
    };
    storeCachedData(mlInfo, mlModelKey)
}

function getCachedModels(tenantId) {
    const mlModelKey = `mlModels_${tenantId}`;
    let modelObj = getCachedData(mlModelKey);
    if (modelObj === null) {
        return modelObj
    }
    return modelObj["mlModels"];
}

function getCachedDatasets(tenantId, integrationID) {
    const datasetKey = `datasets_${tenantId}_${integrationID}`;
    let datasetObj = getCachedData(datasetKey);
    if (datasetObj === null) {
        return datasetObj
    }

    return datasetObj["datasets"];
}

function getCachedIntegrations(tenantId) {
    const integrationKey = `integrations_${tenantId}`;
    let integrationObj = getCachedData(integrationKey);
    if (integrationObj === null) {
        return integrationObj
    }

    return integrationObj["integrations"];
}


function getCachedEnvironments(tenantId) {
    const environmentKey = `environments_${tenantId}`;
    let environmentObj = getCachedData(environmentKey);
    if (environmentObj === null) {
        return environmentObj
    }

    return environmentObj["environments"];
}

function call_update_api(ip, end_point,data, token=localStorage.getItem("sessionToken")) {
    let user_id = localStorage.getItem('user_id');
    let team_id = localStorage.getItem('team_id');
    if (token !== "" && token !== null && token !== undefined) {
        const item = JSON.parse(token);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            if(checkExpiryPowerBI()===false){
                removeLocalStorageKeys();
                window.location = '/';
            }
        }
        token = item.value;
    }
    let protocol = getProtocol(ip);
    const reqEndPoint = getCompleteEndpoint(protocol, ip, end_point);
    return fetch(reqEndPoint, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers:{ 'Content-Type': 'application/json', 'api-type':'qualdo_db_auth', 'authToken':token,
            "userId": user_id, "teamId": team_id}
        }).then((response) => response.json()).then((responseData) => {
              return responseData;
        }).catch( error => console.error(end_point, "- failed"));
}

function call_delete_api(ip, end_point,data={}, token=localStorage.getItem("sessionToken")) {
    let user_id = localStorage.getItem('user_id');
    let team_id = localStorage.getItem('team_id');
    if (token !== "" && token !== null && token !== undefined) {
        const item = JSON.parse(token);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            if(checkExpiryPowerBI()===false){
                removeLocalStorageKeys();
                window.location = '/';
            }

        }
        token = item.value;
    }
    let protocol = getProtocol(ip);
    const reqEndPoint = getCompleteEndpoint(protocol, ip, end_point);
    return fetch(reqEndPoint, {
            method: 'DELETE',
            headers:{ 'data':JSON.stringify(data),'Content-Type': 'application/json', 'api-type':'qualdo_db_auth', 'authToken':token, "userId": user_id, "teamId": team_id }
        }).then((response) => response.json()).then((responseData) => {
              return responseData;
        }).catch( error => console.error(end_point, "- failed"));
}

function call_file_post_api(ip, end_point, form_data, token="") {
    let user_id = localStorage.getItem('user_id');
    let team_id = localStorage.getItem('team_id');
     if (token !== "" && token !== null) {
        const item = JSON.parse(token);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            if(checkExpiryPowerBI()===false){
                removeLocalStorageKeys();
                window.location = '/';
            }
        }
        token = item.value;
     }
     return axios
            .post(
                PROTOCOL+'//'+ip+'/'+end_point,
                form_data,
                { headers:
                    {
                        'content-type': 'multipart/form-data',
                        'authToken':token,
                        "userId": user_id,
                        "teamId": team_id
                    }
                }
            )
            .then(response => {
                return response["data"];
            })
            .catch(error => {
                console.error('Failed');
            });
}

export let get_api_call_cnt = 0;
export let get_api_call_complete_cnt = 0;

export let post_api_call_cnt = 0;
export let post_api_call_complete_cnt = 0;


export function call_get_api(ip, end_point, data, token=localStorage.getItem("sessionToken")) {
    let session = localStorage.getItem('sessionToken');
    let user_id = localStorage.getItem('user_id');
    let team_id = localStorage.getItem('team_id');
    if (session !== "" && session !== null && session !== undefined) {
        const item = JSON.parse(session);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            if(checkExpiryPowerBI()===false){
                localStorage.removeItem("sessionToken");
                window.location = '/';
            }
        }
        session = item.value;
    }
    get_api_call_cnt++;
    let protocol = getProtocol(ip);
    const reqEndPoint = getCompleteEndpoint(protocol, ip, end_point);
    return fetch(reqEndPoint, {
        method: 'GET',
        headers: {"data": JSON.stringify(data),'Content-Type': 'application/json', 'api-type': 'qualdo_db_auth', 'authToken': session, "userId": user_id, "teamId": team_id}
    }).then((response) => response.json()).then((responseData) => {
        get_api_call_complete_cnt++;
        return responseData;
    }).catch((error) => {
        get_api_call_complete_cnt++;
        console.error(reqEndPoint, JSON.stringify(error));
    });
}

/*export function call_get_api_test(ip, end_point, data, token = "") {
    let session = localStorage.getItem('sessionToken');
    if (session !== "" && session !== null) {
        const item = JSON.parse(session);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            localStorage.removeItem("sessionToken");
            window.location = '/';
        }
        session = item.value;
    }

    return fetch("http:"+'//' + ip + '/' + end_point, {
        method: 'GET',
        headers: {'Content-Type': 'application/json', 'api-type': 'qualdo_db_auth', 'authToken': session}
    }).then((response) => response.json()).then((responseData) => {
        return responseData;
    }).catch((error) => {
        console.error(JSON.stringify(error));
    });
}*/
export function postRecentSearch(searchText) {
       let session = localStorage.getItem("sessionToken");
       let tenant_id = localStorage.getItem('team_id')
       let data = {"searched_text": searchText, "tenant_id": tenant_id}
       call_post_api(ui_service_ip, "/discover_history", data, session).then((res) => {

           if (res === undefined) {
                this.setState({errorOccurred: true});
            }
      });

}

export function updateFollowAttributeStatus(data) {
    let session = localStorage.getItem("sessionToken");
    const team_id = localStorage.getItem('team_id');
    data["tenant_id"] = team_id;
    let end_point = "update_follow_attributes";
    call_update_api(ui_service_ip, end_point, data, session).then(
        (response) => {
            if (response === undefined) {
                this.setState({ errorOccurred: true });
            }
            else if(response.code === 200){
                this.setState({ errorOccurred: false });
                let endPoint = "follow_attributes";
                call_get_api(ui_service_ip, endPoint, data).then(
                    (response) =>{
                        if(response.code !== undefined){
                            this.setState({follow_attributes:response.follow_attributes})
                            // addFollowAttributes(response.follow_attributes);
                        }
                    }
                )
            }
        }
    );
}

export function postUIlogs(error, info) {
       let session = localStorage.getItem("sessionToken");
       let postError = {"ui_logs": {"message": error.toString(), "info": info} }
       call_post_api(ui_service_ip, "/ui_logs", postError, session).then((res) => {
      });

}

export function getDatabricksJobs() {
         let data = {"url":this.state.url,"token":this.state.token};
         if (data["url"] !== '' && data["token"] !== '') {
            call_post_api(ui_service_ip, "databricks/jobs", data).then((response) => {
                    if (response === undefined) {
                        this.setState({errorOccurred: true});
                    } else if (response.code === 200) {
                           let optionArr = [];
                           let emptyList = []
                           if (response.jobs !== emptyList) {
                                optionArr = response["jobs"].map(function (row) {
                                                return {"label": row["job_name"], "value": row["job_id"], "type": row["task_type"]};
                                            });
                           }
                           if (this.props.ex_details !== undefined) {
                                let id = this.props.ex_details.configuration.job_id
                                let databricksMatch = optionArr.filter(x => x.value === Number(id));
                                    if (databricksMatch.length > 0) {
                                             this.setState({job:databricksMatch});
                                    }
                           }
                           this.setState({options:optionArr});
                           this.setState({check:true});
                    } else {
                            this.setState({options: []});
                            this.setState({check:false});
                            this.props.showToast("alert-info","Enter the Valid Databricks","");
                    }
            });
         }
}


function construct_integration_data(integration_details) {
    let default_error_storage = false;
    let integrationDetailErrStorage = integration_details["error_storage"];
    if (integrationDetailErrStorage === '' || integrationDetailErrStorage === undefined) {
        default_error_storage = true;
    } else {
        integrationDetailErrStorage = integrationDetailErrStorage.trim();
        integration_details["error_storage"] = integrationDetailErrStorage;
    }
    if (integration_details["integration_type"] === "azure_blob") {
        let integration_data = {
            integration_name: getTrimmedData(integration_details["integration_name"]),
            tenant_id: localStorage.getItem('team_id'),
            env_id: integration_details['env_id'],
            integration_type: integration_details["integration_type"],
            data_store_type: integration_details["data_store_type"],
            integration_config: {
                "storage_name": getTrimmedData(integration_details["storage_name"]),
                "bucket": getTrimmedData(integration_details["bucket_name"]),
                "sub_path": getTrimmedData(integration_details["sub_path"]),
                "error_storage": integrationDetailErrStorage,
                "default_error_storage": default_error_storage,
                "row_delimiter": "\\n",
                "column_delimiter": ",",
                "filetype": integration_details['filetype'],
                "headers": integration_details["headers"],
                "refresh_type": integration_details["refresh_type"],
                "version_format": integration_details["version_format"],
                "version_type": integration_details["version_type"],
                "version_occurence": integration_details["version_occurence"]
            },
            auth_config: {"secret_key": integration_details["blob_secret"]}
        }
        return integration_data;
    }
    else if (integration_details["integration_type"] === "azure_data_lake_gen2") {
        let integration_data = {
            integration_name: getTrimmedData(integration_details["integration_name"]),
            tenant_id: localStorage.getItem('team_id'),
            env_id: integration_details['env_id'],
            integration_type: integration_details["integration_type"],
            data_store_type: integration_details["data_store_type"],
            integration_config: {
                "storage_name": getTrimmedData(integration_details["storage_name"]),
                "bucket": getTrimmedData(integration_details["bucket_name"]),
                "sub_path": getTrimmedData(integration_details["sub_path"]),
                "error_storage": integrationDetailErrStorage,
                "default_error_storage": default_error_storage,
                "row_delimiter": "\\n",
                "column_delimiter": ",",
                "filetype": integration_details['filetype'],
                "headers": integration_details["headers"],
                "refresh_type": integration_details["refresh_type"],
                "version_format": integration_details["version_format"],
                "version_type": integration_details["version_type"],
                "version_occurence": integration_details["version_occurence"]
            },
            auth_config: {"secret_key": integration_details["blob_secret"]}
        }
        return integration_data;
    }
    else if (integration_details["integration_type"] === "azure_data_lake") {
        let integration_data = {
            integration_name: getTrimmedData(integration_details["integration_name"]),
            tenant_id: localStorage.getItem('team_id'),
            // env_id: localStorage.getItem("env_id"),
            env_id: integration_details['env_id'],
            integration_type: "azure_data_lake",
            data_store_type: integration_details["data_store_type"],
            integration_config: {
                "bucket": getTrimmedData(integration_details["bucket_name"]),
                "sub_path": getTrimmedData(integration_details["sub_path"]),
                "error_storage": integrationDetailErrStorage,
                "default_error_storage": default_error_storage,
                "row_delimiter": "\\n",
                "column_delimiter": ",",
                "filetype": integration_details['filetype'],
                "headers": integration_details["headers"],
                "refresh_type": integration_details["refresh_type"],
                "version_format": integration_details["version_format"],
                "version_type": integration_details["version_type"],
                "version_occurence": integration_details["version_occurence"]
            },
            auth_config: {
                "tenant_id": integration_details["adl_tenant_id"],
                "adl_client_id": integration_details["adl_client_id"],
                "secret_key": integration_details['secret_key']
            }
        }
        return integration_data;
    }
    else if (integration_details["integration_type"] === "azure_synapse_ds") {
        let createdTime = integration_details["created_time"];
        if (createdTime === undefined || createdTime === null ||
            String(createdTime).trim().length === 0) {
            createdTime = undefined;
        }
        let integration_data = {
            integration_name: getTrimmedData(integration_details["integration_name"]),
            tenant_id: localStorage.getItem('team_id'),
            // env_id: localStorage.getItem("env_id"),
            env_id: integration_details['env_id'],
            integration_type: "azure_synapse_ds",
            data_store_type: integration_details["data_store_type"],
            integration_config: {
                "database_name": getTrimmedData(integration_details["database_name"]),
                "schema": getTrimmedData(integration_details["schema"]),
                "synapse_table_name": getTrimmedData(integration_details["synapse_table_name"]),
                "synapse_sql_host": getTrimmedData(integration_details["synapse_sql_host"]),
                "created_time": createdTime,
                "error_storage": integrationDetailErrStorage,
                "default_error_storage": default_error_storage,
                "partitions":integration_details["partitions"],
                "table_names":integration_details["table_names"],
                "datasetToBeProcessed":integration_details["datasetToBeProcessed"]
            },
            auth_config: {
                "synapse_user": integration_details['synapse_user'],
                "synapse_password": integration_details['synapse_password'],
            }
        }
        return integration_data;
    }
    else if(integration_details["integration_type"] === "s3"){
        let integration_data = {
            integration_name: getTrimmedData(integration_details["integration_name"]),
            tenant_id: localStorage.getItem('team_id'),
            // env_id: localStorage.getItem("env_id"),
            env_id: integration_details['env_id'],
            integration_type: integration_details["integration_type"],
            data_store_type: integration_details["data_store_type"],
            integration_config: {
                "storage_name": getTrimmedData(integration_details["storage_name"]),
                "bucket": getTrimmedData(integration_details["bucket_name"]),
                "sub_path": getTrimmedData(integration_details["sub_path"]),
                "error_storage": integrationDetailErrStorage,
                "default_error_storage": default_error_storage,
                "row_delimiter": "\\n",
                "column_delimiter": ",",
                "filetype": integration_details['filetype'],
                "headers": integration_details["headers"],
                "refresh_type": integration_details["refresh_type"],
                "version_format": integration_details["version_format"],
                "version_type": integration_details["version_type"],
                "version_occurence": integration_details["version_occurence"]
            },
            auth_config: {"secret_access_key": integration_details["secret_access_key"],
                           "access_key": integration_details["access_key"]}
        }
        return integration_data;
    }
     else if (integration_details["integration_type"] === "redshift") {
        let createdTime = integration_details["created_time"];
        if (createdTime === undefined || createdTime === null ||
            String(createdTime).trim().length === 0) {
            createdTime = undefined;
        }
        let integration_data = {
            integration_name: getTrimmedData(integration_details["integration_name"]),
            isFirstIntegration: integration_details["isFirstIntegration"],
            tenant_id: localStorage.getItem('team_id'),
            // env_id: localStorage.getItem("env_id"),
            env_id: integration_details['env_id'],
            integration_type: "redshift",
            data_store_type: integration_details["data_store_type"],
            integration_config: {
                "host_url": getTrimmedData(integration_details["host_url"]),
                "database_name": getTrimmedData(integration_details["database_name"]),
                "error_storage": integrationDetailErrStorage,
                "default_error_storage": default_error_storage,
                "schema_name": getTrimmedData(integration_details["schema_name"]),
                "table_name": getTrimmedData(integration_details["table_name"]),
                "created_time": createdTime,
                "partitions":integration_details["partitions"],
                "table_names":integration_details["table_names"],
                "datasetToBeProcessed":integration_details["datasetToBeProcessed"]
            },
            auth_config: {
                "user_name": integration_details["user_name"],
                "password": integration_details['password'],
            }
        }
            return integration_data;
    }
    else if(integration_details["integration_type"] === "athena"){
        let createdTime = integration_details["created_time"];
        if (createdTime === undefined || createdTime === null ||
            String(createdTime).trim().length === 0) {
            createdTime = undefined;
        }
        let integration_data = {
            integration_name: getTrimmedData(integration_details["integration_name"]),
            tenant_id: localStorage.getItem('team_id'),
            // env_id: localStorage.getItem("env_id"),
            env_id: integration_details['env_id'],
            integration_type: integration_details["integration_type"],
            data_store_type: integration_details["data_store_type"],
            integration_config: {
                "region_name": getTrimmedData(integration_details["region"]),
                "output_location":getTrimmedData(integration_details["output_location"]),
                "error_storage": integrationDetailErrStorage,
                "default_error_storage": default_error_storage,
                "database_name": getTrimmedData(integration_details["database_name"]),
                "catalog": getTrimmedData(integration_details["catalog"]),
                "table_name": getTrimmedData(integration_details["table_name"]),
                "created_time": createdTime,
                "partitions":integration_details["partitions"],
                "table_names":integration_details["table_names"],
                "datasetToBeProcessed":integration_details["datasetToBeProcessed"]
            },
            auth_config: {"secret_access_key": integration_details["secretAccessKey"],
                           "access_key": integration_details["accessKey"]}
        }
        return integration_data;
    }
    else if (integration_details["integration_type"] === "snow_flake") {
        let createdTime = integration_details["created_time"];
        if (createdTime === undefined || createdTime === null ||
            String(createdTime).trim().length === 0) {
            createdTime = undefined;
        }

        let integration_data = {
            integration_name: getTrimmedData(integration_details["integration_name"]),
            tenant_id: localStorage.getItem('team_id'),
            // env_id: localStorage.getItem("env_id"),
            env_id: integration_details['env_id'],
            integration_type: "snow_flake",
            data_store_type: integration_details["data_store_type"],
            integration_config: {
                "url": getTrimmedData(integration_details["url"]),
                "database": getTrimmedData(integration_details["database"]),
                "error_storage": integrationDetailErrStorage,
                "default_error_storage": default_error_storage,
                "schema": getTrimmedData(integration_details["schema"]),
                "ware_house": getTrimmedData(integration_details["ware_house"]),
                "table_name": getTrimmedData(integration_details["table_name"]),
                "created_time": createdTime,
                "partitions":integration_details["partitions"],
                "table_names":integration_details["table_names"],
                "datasetToBeProcessed":integration_details["datasetToBeProcessed"]

            },
            auth_config: {
                "tenant_id": integration_details["tenant_id"],
                "user": integration_details["user"],
                "password": integration_details['password'],
                "private_key": integration_details['private_key'],
                "private_key_pwd": integration_details['private_key_pwd']

            }
        }
            return integration_data;
       }  else if (integration_details["integration_type"] === "big_query") {
            let createdTime = integration_details["created_time"];
            if (createdTime === undefined || createdTime === null ||
                String(createdTime).trim().length === 0) {
                createdTime = undefined;
            }

            let integration_data = {
            integration_name: getTrimmedData(integration_details["integration_name"]),
            tenant_id: localStorage.getItem('team_id'),
            // env_id: localStorage.getItem("env_id"),
            env_id: integration_details['env_id'],
            integration_type: "big_query",
            data_store_type: integration_details["data_store_type"],
            integration_config: {
                "error_storage": integrationDetailErrStorage,
                "default_error_storage": default_error_storage,
                "schema": getTrimmedData(integration_details["schema"]),
                "table_name": getTrimmedData(integration_details["table_name"]),
                "json_file_name": getTrimmedData(integration_details["json_file"]),
                "created_time": createdTime,
                "partitions":integration_details["partitions"],
                "table_names":integration_details["table_names"],
                "datasetToBeProcessed":integration_details["datasetToBeProcessed"]

            },
            auth_config: integration_details["json"]
        }
        return integration_data;
    } else if (integration_details["integration_type"] === "postgresql") {
        let createdTime = integration_details["created_time"];
        if (createdTime === undefined || createdTime === null ||
            String(createdTime).trim().length === 0) {
            createdTime = undefined;
        }
        let integration_data = {
            integration_name: getTrimmedData(integration_details["integration_name"]),
            tenant_id: localStorage.getItem('team_id'),
            // env_id: localStorage.getItem("env_id"),
            env_id: integration_details['env_id'],
            integration_type: "postgresql",
            data_store_type: integration_details["data_store_type"],
            integration_config: {
                "host_url": getTrimmedData(integration_details["host_url"]),
                "database_name": getTrimmedData(integration_details["database_name"]),
                "error_storage": integrationDetailErrStorage,
                "default_error_storage": default_error_storage,
                "schema_name": getTrimmedData(integration_details["schema_name"]),
                "table_name": getTrimmedData(integration_details["table_name"]),
                "created_time": createdTime,
                "partitions":integration_details["partitions"],
                "table_names":integration_details["table_names"],
                "datasetToBeProcessed":integration_details["datasetToBeProcessed"]
            },
            auth_config: {
                "user_name": integration_details["user_name"],
                "password": integration_details['password']
            }
        }
            return integration_data;
       }else if (integration_details["integration_type"] === "mysql") {
        let createdTime = integration_details["created_time"];
        if (createdTime === undefined || createdTime === null ||
            String(createdTime).trim().length === 0) {
            createdTime = undefined;
        }

        let integration_data = {
            integration_name: getTrimmedData(integration_details["integration_name"]),
            tenant_id: localStorage.getItem('team_id'),
            // env_id: localStorage.getItem("env_id"),
            env_id: integration_details['env_id'],
            integration_type: "mysql",
            data_store_type: integration_details["data_store_type"],
            integration_config: {
                "host": getTrimmedData(integration_details["host"]),
                "database": getTrimmedData(integration_details["database"]),
                "error_storage": integrationDetailErrStorage,
                "default_error_storage": default_error_storage,
                "table_name": getTrimmedData(integration_details["table_name"]),
                "created_time": createdTime,
                "partitions":integration_details["partitions"],
                "table_names":integration_details["table_names"],
                "datasetToBeProcessed":integration_details["datasetToBeProcessed"]
            },
            auth_config: {
                "user": integration_details["user"],
                "password": integration_details['password']
            }
        }
            return integration_data;
       }else if (integration_details["integration_type"] === "azure_sql") {
        let createdTime = integration_details["created_time"];
        if (createdTime === undefined || createdTime === null ||
            String(createdTime).trim().length === 0) {
            createdTime = undefined;
        }
        let integration_data = {
            integration_name: getTrimmedData(integration_details["integration_name"]),
            tenant_id: localStorage.getItem('team_id'),
            // env_id: localStorage.getItem("env_id"),
            env_id: integration_details['env_id'],
            integration_type: "azure_sql",
            data_store_type: integration_details["data_store_type"],
            integration_config: {
                "host": getTrimmedData(integration_details["host"]),
                "database": getTrimmedData(integration_details["database"]),
                "schema": getTrimmedData(integration_details["schema"]),
                "error_storage": integrationDetailErrStorage,
                "default_error_storage": default_error_storage,
                "table_name": getTrimmedData(integration_details["table_name"]),
                "created_time": createdTime,
                "partitions":integration_details["partitions"],
                "table_names":integration_details["table_names"],
                "datasetToBeProcessed":integration_details["datasetToBeProcessed"]
            },

            auth_config: {
                "user": integration_details["user"],
                "password": integration_details['password']
            }
        }
            return integration_data;
       }else if (integration_details["integration_type"] === "gcp_sql") {
        let createdTime = integration_details["created_time"];
        if (createdTime === undefined || createdTime === null ||
            String(createdTime).trim().length === 0) {
            createdTime = undefined;
        }
        let integration_data = {
            integration_name: getTrimmedData(integration_details["integration_name"]),
            tenant_id: localStorage.getItem('team_id'),
            // env_id: localStorage.getItem("env_id"),
            env_id: integration_details['env_id'],
            integration_type: "gcp_sql",
            data_store_type: integration_details["data_store_type"],
            integration_config: {
                "host": getTrimmedData(integration_details["host"]),
                "database": getTrimmedData(integration_details["database"]),
                "schema": getTrimmedData(integration_details["schema"]),
                "error_storage": integrationDetailErrStorage,
                "default_error_storage": default_error_storage,
                "table_name": getTrimmedData(integration_details["table_name"]),
                "created_time": createdTime,
                "partitions":integration_details["partitions"],
                "table_names":integration_details["table_names"],
                "datasetToBeProcessed":integration_details["datasetToBeProcessed"]

            },

            auth_config: {
                "user": integration_details["user"],
                "password": integration_details['password']
            }
        }
            return integration_data;
       }else if (integration_details["integration_type"] === "gcs") {
        let integration_data = {
            integration_name: getTrimmedData(integration_details["integration_name"]),
            tenant_id: localStorage.getItem('team_id'),
            // env_id: localStorage.getItem("env_id"),
            env_id: integration_details['env_id'],
            integration_type: integration_details["integration_type"],
            data_store_type: integration_details["data_store_type"],
            integration_config: {
                "bucket": getTrimmedData(integration_details["bucket_name"]),
                "sub_path": getTrimmedData(integration_details["sub_path"]),
                "error_storage": integrationDetailErrStorage,
                "default_error_storage": default_error_storage,
                "row_delimiter": "\\n",
                "column_delimiter": ",",
                "filetype": integration_details['filetype'],
                "headers": integration_details["headers"],
                "refresh_type": integration_details["refresh_type"],
                "version_format": integration_details["version_format"],
                "version_type": integration_details["version_type"],
                "version_occurence": integration_details["version_occurence"],
                "json_file_name": getTrimmedData(integration_details["json_file"]),
                "partitions":integration_details["partitions"],
                "table_names":integration_details["table_names"],
                "datasetToBeProcessed":integration_details["datasetToBeProcessed"]

            },
            auth_config: integration_details["json"]
        }
        return integration_data;
    }else if (integration_details["integration_type"] === POWER_BI_CONNECTOR) {
        let createdTime = integration_details["created_time"];
        if (createdTime === undefined || createdTime === null ||
            String(createdTime).trim().length === 0) {
            createdTime = undefined;
        }
        let integration_data = {
            integration_name: getTrimmedData(integration_details["integration_name"]),
            isFirstIntegration: integration_details["isFirstIntegration"],
            tenant_id: localStorage.getItem('team_id'),
            env_id: integration_details['env_id'],
            integration_type: POWER_BI_CONNECTOR,
            data_store_type: integration_details["data_store_type"],
            integration_config: {
                "error_storage": integrationDetailErrStorage,
                "default_error_storage": default_error_storage,
                "workspace": getTrimmedData(integration_details["workspace"]),
                "database": getTrimmedData(integration_details["database"]),
                "table_name": getTrimmedData(integration_details["table_name"]),
                "created_time": createdTime,
            },
            auth_config: {
                "user_name": integration_details["user_name"],
                "password": integration_details['password'],
            }
        }
            return integration_data;
    }
    else if (integration_details["integration_type"] === "azure_databricks_table") {
        let createdTime = integration_details["created_time"];
        if (createdTime === undefined || createdTime === null ||
            String(createdTime).trim().length === 0) {
            createdTime = undefined;
        }
        let integration_data = {
            integration_name: getTrimmedData(integration_details["integration_name"]),
            tenant_id: localStorage.getItem('team_id'),
            env_id: integration_details['env_id'],
            integration_type: "azure_databricks_table",
            data_store_type: integration_details["data_store_type"],
            integration_config: {
                "host": getTrimmedData(integration_details["host"]),
                "http_path": getTrimmedData(integration_details["http_path"]),
                "catalog": getTrimmedData(integration_details["catalog"]),
                "table": getTrimmedData(integration_details["table"]),
                "schema": getTrimmedData(integration_details["schema"]),
                "error_storage": integrationDetailErrStorage,
                "default_error_storage": default_error_storage,
                "table_name": getTrimmedData(integration_details["table_name"]),
                "filetype": integration_details['filetype'],
                "created_time": createdTime,
                "partitions":integration_details["partitions"],
                "table_names":integration_details["table_names"],
                "datasetToBeProcessed":integration_details["datasetToBeProcessed"]
            },
            auth_config: {
                "secret_key": integration_details['secret_key']
            }
        }

        return integration_data;
    }


}

function constructTestMlModelConnection(modelForm) {
    let datasets = [];
    let features = modelForm.features;
    for (let k = 0; k < features.length; k++) {
        let baselineDataset = features[k].base_line_data_set;
        let targetDataset = features[k].target_data_set;
        datasets.push(baselineDataset);
        datasets.push(targetDataset);
    }
    return datasets;

}


function mobile_validate(mobile_number) {
    const reg = /^[0]?[789]\d{9}$/;
    if (reg.test(mobile_number) === false) {
        return false;
    } else {
       return true
    }
}


export const countErrors = (errors) => {
    let count = 0;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (count = count+1)
    );
    return count;
}

export function formFieldChange(event) {
    event.preventDefault();
    const { value } = event.target;
    let error_msg = validEmailRegex.test(value) ? '' : 'Email is not valid!';
    let errors = this.state.errors
    errors.email_error = error_msg
    this.setErrorData(errors)
}


export function handleBlur(event) {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
        case 'email':
            if (value.length > 0)
                errors.email = validEmailRegex.test(value) ? '' : 'Email is not valid!';
            break;
        case 'username':
            errors.username = value.length < 6 && value.length > 0  ? 'Username should not be less than 6 characters' : '';
            break;
        case 'company':
            errors.company = value.length < 1 ? 'Company name should not be empty' : '';
            break;
        case 'password':
            errors.password = value.length < 8 && value.length > 0 ? 'Password should not be less than 8 characters' : '';
            break;
        case 'confirmPassword':
            if (this.state.password.localeCompare(value) && value.length > 7) {
                errors.confirmPassword = 'Does not matched with the password';
            } else {
                errors.confirmPassword = '';
            }
            break;
        case "adminEmail":
             if (value.length > 0)
                errors.adminEmail = validEmailRegex.test(value) ? '' : 'Email is not valid!';
             break;
        case 'phone':
            errors.phone = mobile_validate(value) ? ''  : 'Please Enter valid phone number' ;
            break;
        default:
            break;
    }
    this.setState({errors, [name]: value});
}

export function handleChange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
        case 'email':
            if (validEmailRegex.test(value))
                errors.email =  ''
            break;
        case 'username':
            if (value.length > 5)
                errors.username =''
            break;
        case 'company':
            if (value.length > 2)
                errors.company =''
            break;
        case 'password':
            if (value.length > 7)
                errors.password =''
            break;
        case 'confirmPassword':
            if (this.state.password.localeCompare(value) && value.length > 7) {
                errors.confirmPassword = 'Does not matched with the password';
            } else {
                errors.confirmPassword = '';
            }
            break;
        case 'phone':
            if (value.length === 10)
                errors.phone =''
            break;
        default:
            break;
    }
    this.setState({
      integration_data: {
        [name]: value
      }
    })
    this.setState({errors, [name]: value});
}

export function handleIntegrationChange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    let integration_data =  this.state.integration_data;
    integration_data[name] = value;
    this.props.setIntegrationData(integration_data)
}

export function handleMetricChange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    let metric_data =  this.state.metric_data;
    metric_data[name] = value;
    this.setMetricData(metric_data)
}

export function getErrorMsg(errorText, statusCode,isCustomMertics=null) {

    if (statusCode !== dataRunStatuses["failed"] && statusCode !== dataRunStatuses["skipMcf"] && !isCustomMertics)
    {
        return "-"
    }
    if(errorText.includes("Error in reading the source dataset") ) {
      return "Error in connecting to the configured data source"
    }else if(errorText.includes("'NoneType' object has no attribute 'get'")){
       return "Unexpected error occurred in our backend. Kindly report it using Help Desk"
    } else if(errorText.includes("float division by zero")){
       return "Unexpected error occurred in our backend. Kindly report it using Help Desk"
    } else if(errorText.includes("An error occurred while calling o283.count.")) {
      return "Error while processing the configured dataset.Could be due to unexpected/invalid values in the dataset or incorrect format of the dataset"
    } else if(errorText.includes("size exceeds")){
        return errorText;
    } else if(errorText.includes("New data not found for current run")) {
        return "There is no changes between previous and current dataset"
    } else if(errorText.includes("No data to compute. Total number of updated rows is 0")) {
        return "No data to compute. Total number of updated rows is 0"
    } else if (errorText.includes("could not convert string ")) {
        return "Processing error occurred in our backend. Kindly report it using Help Desk"
    } else if (errorText.includes("Cluster Not Started. run_status_code is 401")) {
        return "Insufficient Resource Quota or API to start the cluster is not available."
    } else if (errorText.includes("Incorrect Starting Index found For Value")) {
        return "Starting Index value not found/Incorrect Datatype for Starting Index."
    } else if (errorText.includes("Incorrect Starting Index found for query")) {
        return "No data found for the Starting Index query/Invalid Starting Index query."
    }else if (errorText.includes("Computation Failed due to cluster terminated")){
        return "Unexpected error encountered. Kindly report it using Help Desk."
    }
    else {
      return "Unexpected error occurred in our backend. Kindly report it using Help Desk"
    }

}

function getDatasourceDiscovery(datasetProcessing, lastDiscoveryTime, status_text=undefined) {
    if (datasetProcessing === undefined) {
        datasetProcessing = [];
    }

    if (status_text !== undefined && status_text === NO_DATA_SET_FOUND_TEXT) {
        // Fix for issue - 1333
        // No datasets present in the configured data source. So we will show process as completed
        return ["Completed", lastDiscoveryTime, "text-success", 100];
    }

    let inCompleteFilteredValues = datasetProcessing.filter(x => (x.run_status_code === DEFAULT_METRICS_RUN_FAILURE))
    if (inCompleteFilteredValues.length > 0) {
        return ["Incomplete", lastDiscoveryTime, "text-danger", 70]
    }

    let skipMcfFFilteredValues = datasetProcessing.filter(x => (x.run_status_code === MCF_SKIPPED))
    if (skipMcfFFilteredValues.length > 0) {
        return ["Incomplete", lastDiscoveryTime, "text-danger", 70];
    }

    let inProgressFilteredValues = datasetProcessing.filter(x => (RUN_PROGRESS_STATES.includes(x.run_status_code)))
    if (inProgressFilteredValues.length > 0) {
        return ["In Progress", lastDiscoveryTime, "text-warning", 30];
    }

    let completedFilteredValues = datasetProcessing.filter(x => (x.run_status_code === RUN_SUCCESS))
    if (completedFilteredValues.length > 0) {
        return ["Completed", lastDiscoveryTime, "text-success", 100];
    }
    return ["Not Yet Started", lastDiscoveryTime, "text-warning", 0];
}


function getPipelineSourceDiscovery(datasetProcessing, lastDiscoveryTime, status_text=undefined) {

    if(datasetProcessing==="inprogress"){
        return ["In Progress", lastDiscoveryTime, "text-warning", 30];
    }
    else if(datasetProcessing==="failed"){
        return ["Failed", lastDiscoveryTime, "text-warning", 30];
    }
    else if(datasetProcessing==="complete"){
        return ["Completed", lastDiscoveryTime, "text-success", 100];
    }else {
        return ["Not Yet Started", "-", "text-warning", 0];
    }

    // if (status_text !== undefined && status_text === NO_DATA_SET_FOUND_TEXT) {
    //     // Fix for issue - 1333
    //     // No datasets present in the configured data source. So we will show process as completed
    //     return ["Completed", lastDiscoveryTime, "text-success", 100];
    // }
    // if (datasetProcessing === undefined) {
    //     datasetProcessing = [];
    // }

    // if (status_text !== undefined && status_text === NO_DATA_SET_FOUND_TEXT) {
    //     // Fix for issue - 1333
    //     // No datasets present in the configured data source. So we will show process as completed
    //     return ["Completed", lastDiscoveryTime, "text-success", 100];
    // }

    // let inCompleteFilteredValues = datasetProcessing.filter(x => (x.run_status_code === DEFAULT_METRICS_RUN_FAILURE))

    // if (inCompleteFilteredValues.length > 0) {
    //     return ["Incomplete", lastDiscoveryTime, "text-danger", 70]
    // } else {
    //     let inProgressFilteredValues = datasetProcessing.filter(x => (RUN_PROGRESS_STATES.includes(x.run_status_code)))
    //     if (inProgressFilteredValues.length > 0) {
    //         return ["In Progress", lastDiscoveryTime, "text-warning", 30];
    //     } else {
    //         let completedFilteredValues = datasetProcessing.filter(x => (x.run_status_code === RUN_SUCCESS))
    //         if (completedFilteredValues.length > 0) {
    //             return ["Completed", lastDiscoveryTime, "text-success", 100];
    //         }
    //         return ["Not Yet Started", lastDiscoveryTime, "text-warning", 0];
    //     }

    // }
}




function getModelProcessStatus(datasetProcessing, lastDiscoveryTime, modelCreatedTime) {
    if (datasetProcessing === undefined) {
        datasetProcessing = [];
    }

    let inCompleteFilteredValues = datasetProcessing.filter(x =>
                                    (x.run_status_code === DEFAULT_METRICS_RUN_FAILURE));

    if (inCompleteFilteredValues.length > 0) {
        return ["Incomplete", lastDiscoveryTime, "text-danger", 70]
    } else {
        let inProgressFilteredValues = datasetProcessing.filter(x => (RUN_PROGRESS_STATES.includes(x.run_status_code)))
        let modelCreatedAt = new Date(modelCreatedTime).getTime();
        let last_profiled_time = new Date(lastDiscoveryTime).getTime();
        if (inProgressFilteredValues.length > 0 || modelCreatedAt > last_profiled_time) {
            return ["In Progress", lastDiscoveryTime, "text-warning", 30];
        } else {
            let completedFilteredValues = datasetProcessing.filter(x => (x.run_status_code === RUN_SUCCESS))
            if (completedFilteredValues.length > 0) {
                return ["Completed", lastDiscoveryTime, "text-success", 100];
            }
            return ["Not Yet Started", lastDiscoveryTime, "text-warning", 0];
        }

    }
}


function model_updates_table(inputData) {
    let headers=["Model", "Summary", "Profile Status", "Datasets", "Errors"];
    let final_op=[];
    for(let i=0; i < inputData.length; i++) {
        let row=[];
        let d = inputData[i];
        let count  = d["dataset_count"];
        let lastDiscoveryTime = getLastDiscovery(d["dataset_processing_info"])
        row.push({"value": d["model_name"], "type": "td"});
        row.push({"value": count, "type": "summary"});
        row.push({"value": getModelProcessStatus(d["dataset_processing_info"],lastDiscoveryTime, d["created_time"]), "type": "datasetDiscovery"});
        row.push({"value": d, "type": "datasets"});
        row.push({"value": d, "type": "errors"});
        final_op.push(row);
    }
    let table_data ={"headers": headers, "data": final_op};
    return table_data;
}

function getLastDiscovery(lastProcessingTimeList) {
   let max_run_end_time = '';
   if (lastProcessingTimeList === undefined){
       lastProcessingTimeList = [];
   }

   if(lastProcessingTimeList.length > 0) {
     max_run_end_time = lastProcessingTimeList[0]["run_end_time"]
     lastProcessingTimeList.map(function (row) {
                var date = new Date(row["run_end_time"]);
                var maxDate = new Date(max_run_end_time);
                if (row["run_end_time"] !== null && date > maxDate ) {
                    max_run_end_time = row["run_end_time"]

                }
                return 1;

     });

   }
   return max_run_end_time

}


export function getProgressPercentage() {
    const teamId = localStorage.getItem('team_id');
    let data = {"tenant_id":teamId, "integration_id": this.props.integration_id}
    let endPoint = "dataset_progress_info";
    call_get_api(ui_service_ip, endPoint,data).then((response) => {
        if (response === undefined) {
            this.setState({errorOccurred: true});
        } else if (response !== null && response.code === 200) {
           this.setState({progressStatus: response["progress_percentage"]});

        }
    });

}




function datasource_updates_table(datasource_updates) {
     let headers=["Datasource Name", "Summary", "Profile Status", "Datasets", "Errors"];
    let final_op=[];
    let refreshInfoFinal=[];
    if (datasource_updates === undefined){
        datasource_updates = [];
    }

    for(let i=0; i < datasource_updates.length; i++) {
        let row=[];
        let refreshInfo=[];
        let d = datasource_updates[i];
        let total = d["dataset_processing_info"].length;
        let status_text = d["status_text"];
        let lastDiscoveryTime = getLastDiscovery(d["last_run_time"])
        row.push({"value": d['datasource_name'], "type": "td"});
        row.push({"value": total, "type": "summary"});
        row.push({"value": getDatasourceDiscovery(d["dataset_processing_info"],lastDiscoveryTime, status_text), "type": "datasetDiscovery"});
        row.push({"value": d, "type": "datasets"});
        row.push({"value": d, "type": "errors"});
        refreshInfo.push({"integration_id":d['integration_id'], 'lastRefreshTime':lastDiscoveryTime})
        final_op.push(row);
        refreshInfoFinal.push(refreshInfo)
    }
    let table_data ={"headers": headers, "data": final_op};
    return table_data;
}



function pipelinesource_updates_table(pipelinesource_updates) {
    let headers=["Pipeline Source Name", "Summary", "Profile Status", "Jobs", "Errors"];
   let final_op=[];
   if (pipelinesource_updates === undefined){
    pipelinesource_updates = [];
   }

   for(let i=0; i < pipelinesource_updates.length; i++) {
       let row=[];
       let d = pipelinesource_updates[i];
       let total = d["total_count"];
       let status_text = d["total_count"];
       let lastDiscoveryTime = d["last_profiled_time"]
       row.push({"value": d['pipeline_integration_name'], "type": "td"});
       row.push({"value": total, "type": "summary"});
       row.push({"value": getPipelineSourceDiscovery(d["discovery_status"],lastDiscoveryTime, status_text), "type": "datasetDiscovery"});
       row.push({"value": d, "type": "jobs"});
       row.push({"value": d, "type": "errors"});
       final_op.push(row);
   }
   let table_data ={"headers": headers, "data": final_op};
   return table_data;
}


function get_model_ab_row_data(ab_data, model_data, model_data_2, model_data_3) {
    let row_data = [];

    if (IS_NONE_CHECK.includes(model_data) || IS_NONE_CHECK.includes(model_data_2)) {
        return null;
    }

    const ml_model_id = model_data["ml_model_id"];
    const abAuthor = ab_data["created_by"];
    const abCreatedTime = ab_data["created_time"];
    const model_ab_id = ab_data["model_ab_id"];

    const names = [model_data["model_name"], model_data_2["model_name"]];
    const modelVersions = [model_data["version"], model_data_2["version"]];
    const datasourceNames = [model_data["integration_name"], model_data_2["integration_name"]];
    const envNames = [model_data["env_name"], model_data_2["env_name"]];

    let has3Models = false;
    if (!IS_NONE_CHECK.includes(model_data_3)) {
        has3Models = true;
        names.push(model_data_3["model_name"]);
        modelVersions.push(model_data_3["version"]);
        datasourceNames.push(model_data_3["integration_name"]);
        envNames.push(model_data_3["env_name"]);
    }

    row_data.push(names);
    row_data.push(modelVersions);
    row_data.push(datasourceNames);
    row_data.push(envNames);
    row_data.push({"value": abAuthor, "has3Models": has3Models});
    row_data.push({"value": abCreatedTime, "has3Models": has3Models});

    let viewData = {
        "has3Models": has3Models,
        'model_ab_id': model_ab_id, 'order': "A",
        "ml_model_id": ml_model_id
    };

    row_data.push(viewData);

    return row_data;
}


export function prepare_model_ab_data_for_a_config(ab_config, model_info_map) {
    let model_1 = ab_config["model_1"];
    let model_data_1 = model_info_map[String(model_1)];

    let model_2 = ab_config["model_2"];
    let model_data_2 = model_info_map[String(model_2)];

    let model_3 = ab_config["model_3"];
    let model_data_3 = null;
    if (!IS_NONE_CHECK.includes(model_3)) {
        model_data_3 = model_info_map[String(model_3)];
    }

    const abRowData = get_model_ab_row_data(ab_config, model_data_1, model_data_2, model_data_3);
    return abRowData;
}


function prepare_model_ab_table_data(ab_data, model_info_map) {
    if (ab_data === undefined || ab_data === null || model_info_map === undefined) {
        return {"headers": MODEL_AB_TABLE_HEADERS, "data": [], "not_sortable": true}
    }

    let final_op = [];

    for (let i = 0; i < ab_data.length; i++) {
        let ab_config = ab_data[i];
        const res = prepare_model_ab_data_for_a_config(ab_config, model_info_map);
        if (IS_NONE_CHECK.includes(res)) {
            continue;
        }

        final_op.push(res);
    }

    let table_data = {"headers": MODEL_AB_TABLE_HEADERS, "data": final_op, "not_sortable": true};
    return table_data;
}

function customMetricsProcessUpdates(customMetricsInfo) {
    let headers=["Metric Name", "Metric Associations", "Dataset Name", "Datasource Name", "Profile Status",
    "Refresh Status", "Errors"];
    let final_op=[];
    if (customMetricsInfo === undefined){
        customMetricsInfo = [];
    }

    for(let i=0; i < customMetricsInfo.length; i++) {
        let row=[];
        let d = customMetricsInfo[i];
        row.push({"value": d['metric_name'], "type": "metric"});
        row.push({"value": d['mas_name'], "type": "masName"});
        row.push({"value": normalizeChildDatasetName(d['data_set_name']), "type": "dataset"});
        row.push({"value": d['datasource_name'], "type": "datasource"});
        let status_value = [];
        if (d['status_code'] === 1) {
            status_value = ["Completed", d['modified_time'], "text-success", 100]
        } else if (d['status_code'] === -1) {
        	status_value = ["Inprogress", d['modified_time'], "text-danger", 70]
        } else if (d['status_code'] === 2){
            status_value = ["Inqueue", d['modified_time'], "text-danger", 0]
        }
        else {
            status_value = ["Incomplete", d['modified_time'], "text-success", 70]
        }
        row.push({"value": status_value, "type": "status"});
        row.push({"value": [d['success_count'], d['failed_count']], "type": "refresh"});
        row.push({"value": d, "type": "errors"});
        final_op.push(row);
    }

    let table_data ={"headers": headers, "data": final_op};
    return table_data;
}

export function getStatus(statusCode) {
    if (statusCode === 200 || statusCode === 201){
       this.setState({textColor:"text-warning"})
       return "In Progress"
    }else if(statusCode === DEFAULT_METRICS_RUN_FAILURE){
       this.setState({textColor:"text-danger"})
       return "Failed"
    }else if(statusCode === 0) {
       this.setState({textColor:"text-warning"})
       return "In Queue"
    }else if(statusCode === 1) {
      return "Completed"
    }else{
      this.setState({textColor:"text-danger"})
      return "Failed"
    }
}

function pipeline_integration_table_data_setup(input_data) {
    let headers=["Environment", "Pipeline Source Name", "Pipeline Source Type", "Status ", "Created Date", "Actions"];
    let final_op=[];
    for(let i=0; i < input_data.length; i++) {
        let row=[];
        let d= input_data[i];
        row.push({"value": d["env_name"], "type": "td"});
        row.push({"value": d['pipeline_integration_name'], "type": "td"});
        row.push({"value": d['pipeline_type'], "type": "td"});
        row.push({"value": d['discovery_status'], "type": "td"});
        row.push({"value": d['created_time'], "type": "td"});
        row.push({"value": "", "type": "component", "pipeline_integration_data":d});
        final_op.push(row);
    }
    let table_data ={"headers": headers, "data": final_op};
    return table_data;
}

function integration_table_data_setup(input_data) {
    let headers=["Environment", "Datasource", "Datasource Type", "Schema Learning Status", "Created Date", "Actions"];
    let final_op=[];
    for(let i=0; i < input_data.length; i++) {
        let row=[];
        let d= input_data[i];
        row.push({"value": d["env_name"], "type": "td"});
        row.push({"value": d['integration_name'], "type": "td"});
        row.push({"value": d['integration_type'], "type": "td"});
        row.push({"value": d['discovery_status'], "type": "td"});
        row.push({"value": d['created_time'], "type": "td"});
        row.push({"value": "", "type": "component", "integration_data":d});
        final_op.push(row);
    }
    let table_data ={"headers": headers, "data": final_op};
    return table_data;
}

function mlTableDataSetup(input_data) {
    let headers = ["Model", "Datasource", "Environment", "Created By", "Created Time", "ML type", "Model Version", "Actions"];
    let finalOp = [];
    for (let i = 0; i < input_data.length; i++) {
        let row = [];
        let d = input_data[i];
        row.push({ "value": d["model_name"], "type": "td" });
        row.push({ "value": d["integration_name"], "type": "td" });
        row.push({ "value": d["env_name"], "type": "td" });
        row.push({ "value": d["author"], "type": "td" });
        row.push({ "value": d['created_time'], "type": "td" });
        row.push({ "value": d['ml_model_type'], "type": "td" });
        row.push({ "value": d['version'], "type": "td" });
        row.push({ "ml_model_data": d, "type": "component" });
        finalOp.push(row);
    }
    let tableData = { "headers": headers, "data": finalOp };
    return tableData;
}

function pipeline_table(input_data){
   let headers=["Pipeline Channel Name", "Pipeline Channel Type","URL", "Created by", "Created Date", "Actions"];
    let final_op=[];
    for(let i=0; i < input_data.length; i++) {
        let row=[];
        const pipeline_data = input_data[i];
        let d= pipeline_data["configuration"]
        row.push({"value": pipeline_data["pipeline_trigger_name"], "type": "td"})
        row.push({"value": pipeline_data["pipeline_trigger_type"], "type": "td"})
        row.push({"value": d["url"], "type": "td"})
        row.push({ "value": localStorage.getItem('user_name'), "type": "td" });
        row.push({ "value": pipeline_data['created_time'], "type": "td" });
        row.push({"consume": pipeline_data, "type": "component"})
        final_op.push(row)
    }
    let table_data ={"headers": headers, "data": final_op}
    return table_data;
}
function alert_table(input_data) {
    let headers=["Alert Name","Alert Type", "Created by", "Created Date", "Actions"];
    let final_op=[];

    for(let i=0; i < input_data.length; i++) {
        let row=[];
        const alert_data = input_data[i];
        row.push({"value": alert_data["alert_name"], "type": "td"})
        row.push({"value": alert_data["alert_type"], "type": "td"})
        row.push({ "value": localStorage.getItem('user_name'), "type": "td" });
        row.push({ "value": alert_data['created_time'], "type": "td" });
        row.push({"alert": alert_data, "type": "component"})
        final_op.push(row)
    }
    return {"headers": headers, "data": final_op};

}

function consume_group_table(input_data) {
    let headers=["Notification Name", "Notification Type", "Created by", "Created Date", "Actions"];
    let final_op=[];

    for(let i=0; i < input_data.length; i++) {
        let row=[];
        const consume_data = input_data[i];
        row.push({"value": consume_data["consume_group_name"], "type": "td"})
        row.push({"value": consume_data["consume_group_type"], "type": "td"})
        row.push({ "value": localStorage.getItem('user_name'), "type": "td" });
        row.push({ "value": consume_data['created_time'], "type": "td" });
        row.push({"consume": consume_data, "type": "component"})
        final_op.push(row)
    }

    return {"headers": headers, "data": final_op};
}

function DQM_metric_setup(type, input_data) {
    let headers=["Metric Name", "Metric Type", "Created Date", "Metric Definition", "Visibility", "Actions"]
    let final_op=[]
    for(let i=0; i < input_data.length; i++) {
        let row=[]
        let d= input_data[i]
        row.push({"value": d['metric_name'], "type": "td"})
        row.push({"value": d['metric_type'], "type": "td"})
        row.push({"value": d['created_time'], "type": "td"})
        row.push({"value": JSON.stringify(d['metrics_definition']), "type": "td"})
        row.push({"value": "My team", "type": "td"})
        row.push({"value": "", "type": "component"})
        final_op.push(row)
    }
    let table_data ={"headers": headers,
                  "data": final_op}
    return table_data;
}

function construct_card_data(input_data) {
    let final_op = [];
    for (let i = 0; i < input_data.length; i++) {
        let d = input_data[i]
        if (d['metric_type'] !== "quality_gate") {
            const cardName = d['metric_name'];
            final_op.push({
                "metric_name": cardName,
                "metric_type": d['metric_type'],
                "metrics_id": d["metrics_id"]
            })
        }
    }
    return final_op;
}

function get_table_data(mas, team_id) {
    const endPoint = "metricsassociation/metrics";
    let masData = {"mas_id":mas["mas_id"], "tenant_id":team_id};
    return call_get_api(ui_service_ip, endPoint,masData).then( (response) => {
        if (response === undefined) {
                        this.setState({errorOccurred: true});
        } else if (response.code === 200) {
            let metrics = response['metrics'];
            let finalOp=[];
            for (let i=0; i< metrics.length; i++) {
                let d = metrics[i];
                const metricsDefinition = d['metrics_definition'];
                const metricName = d['metric_name'];
                const jobId = d['job_id'];
                const isTemplate = (jobId !== undefined && jobId !== null);
                if (d['metric_type'] === "UDF" || isTemplate) {
                    let row = [];
                    let formula = metricsDefinition['formula'];
                    if (typeof formula === "object") {
                        formula = formula["type"];
                    }

                    row.push({"value": metricName, "type": "td"})
                    row.push({"value": mas["mas_name"], "type": "td"})
                    row.push({"value": localStorage.getItem('user_name'), "type": "td"});
                    row.push({"value": d["created_time"], "type": "td"})
                    row.push({"value": metricsDefinition['version'], "type": "td"})
                    row.push({"value": formula, "type": "td"})
                    row.push({"value": "", "type": "component", "metrics_data": d})
                    finalOp.push(row)
                }
            }

            let data = {"table_data":finalOp, "metrics":metrics}
            return data
        } else {
           this.setState({errorOccurred: true});
        }
    });
}


function get_data(mas, team_id) {
    const endPoint = "metricsassociation/metrics";
    let masData = {"mas_id":mas["mas_id"], "tenant_id":team_id};
    return call_get_api(ui_service_ip, endPoint,masData).then( (response) =>{
        if (response === undefined) {
                        this.setState({errorOccurred: true});
        } else if (response.code === 200) {
            let card_data = construct_card_data(response.metrics);
            let obj = {
                "mas_name": mas["mas_name"],
                "mas_id": mas["mas_id"],
                "metric_data": card_data
            };
            return obj
        } else {
            if (this === undefined) {
                return;
            }
            this.setState({errorOccurred: true});
        }
    });
}

async function DQM_widget_setup(type, input_data, team_id) {
    let metric_type = null;
    if (type === "dq") {
        metric_type = "dqm";
    } else {
        metric_type = "mlm"
    }
    const promises = [];
    for(let i=0; i < input_data.length; i++) {
        let d= input_data[i];
        if ( d['mas_type'] === metric_type) {
             let result = await Promise.resolve(get_data(d, team_id));
             promises.push(result);
        }
    }
    return promises;
}

async function fill_table(input_data, team_id) {
    let headers = ["Metric Name", "Metric Associations", "Created By","Created Date", "Version", "Dimenstion","Type of Formula", "Actions"];
    let final_op = [];
    let metrics_details = {};
    for(let i=0; i < input_data.length; i++) {
        let d= input_data[i];
        let result = await Promise.resolve(get_table_data(d, team_id));
        metrics_details[d["mas_id"]] = result["metrics"];
        final_op = [...final_op, ...result["table_data"]];
   }
    let table_data = {"headers": headers,
                  "data": final_op,
                  "metric_details":metrics_details}
    return table_data;
}


export function getProfileMLChart(integrationId, teamId){
    let session = localStorage.getItem("sessionToken");
    let startDate = moment().subtract(60, 'days').format('YYYY-MM-DD 00:00:00').toString();
    let endDate =  moment().utc().endOf('day').format('YYYY-MM-DD 23:59:59').toString();
    //this.setState({startDate: startDate, endDate:endDate});
    let reqData = {
        "data_source": [integrationId],
        "tenant_id": teamId,
        "payload_start_time":startDate,
        "payload_end_time": endDate
    };
    const endPoint = "ml/relationship/all";
    call_post_api(ui_service_ip, endPoint, reqData, session).then((res) => {
        if (res === undefined) {
            this.setState({errorOccurred: true});
        } else if (res.code === 200) {
            //const chartData = {"metrics": res["metrics"]};
            //this.setRelationship(res["relationships"], res["attributes"], chartData, selectedSource, res['models'], res["meta_data"]);
        }
    });
}


function getProfileMLData(integrations, teamId, session){
    if(integrations.length === 0){
        return;
    }

    let firstIntegration = integrations[0];
    let integrationId = firstIntegration['integration_id'];
    let selectedDataSrcName = firstIntegration["integration_name"];
    let selectedSource = {"label": selectedDataSrcName, "value": integrationId};
    let startDate = moment().subtract(29, 'days').format('YYYY-MM-DD 00:00:00').toString();
    let endDate =  moment().utc().endOf('day').format('YYYY-MM-DD 23:59:59').toString();
    this.setState({startDate: startDate, endDate:endDate});
    let reqData = {
        "data_source": [integrationId],
        "tenant_id": teamId,
        "payload_start_time":startDate,
        "payload_end_time": endDate
    };
    const endPoint = "profile/ml/charts/"
    call_post_api(ui_service_ip, endPoint, reqData, session).then((res) => {
        if (res === undefined) {
            this.setState({errorOccurred: true});
        } else if (res.code === 200) {
            const chartData = {"metrics": res["metrics"]}
            this.setRelationship(res["relationships"], res["attributes"], chartData, selectedSource, res['models'], res["meta_data"]);
        }
    });
}

function callProfileAPI(requestData, session, selectedSource, context, pageLoad) {
    const endPoint = "profile/charts/latest";
    call_post_api(ui_service_ip, endPoint, requestData, session).then((res) => {
        if (res === undefined) {
            context.setState({errorOccurred: true});
        } else if (res.code === 200) {
            const chartData = {"metrics": res["metrics"]};
            context.setRelationship(res["relationships"], res["attributes"], chartData, selectedSource, res['datasets'], res["meta_data"]);
            if (pageLoad !== "firstLoad") {
                context.showToast("alert-success","Filter added successfully",'');
            }
        } else if(res.code === 401) {
            context.showToast("alert-danger","could not add Filter",'');
        } else if(res.code === 403) {
            localStorage.removeItem("sessionToken");
        }
    });
}

export function profileFilterSubmit(data, startDate, endDate, selectedSource) {
    const teamId = localStorage.getItem('team_id');
    let session = localStorage.getItem("sessionToken");
    if (startDate !=null && endDate !=null) {
        data["payload_start_time"] = startDate;
        data["payload_end_time"] = endDate;
    } else {
        let startDate = moment().subtract(29, 'days').startOf('day').format('YYYY-MM-DD 00:00:00').toString();
        let endDate =  moment().utc().endOf('day').format('YYYY-MM-DD 23:59:59').toString();
        data["payload_start_time"] = startDate;
        data["payload_end_time"] = endDate;
    }
    data["integrations"] = data["data_source"];
    data["tenant_id"] = teamId;
    let fetchLastProfilingTime = getLastDQProfilingTime.bind(this);
    fetchLastProfilingTime(data["data_source"][0]);
    callProfileAPI(data, session, selectedSource, this, "filterLoad");
}

export function profileDropdownFilterSubmit(data, startDate, endDate, selectedDataSrcName) {
    let session = localStorage.getItem("sessionToken");
    let fetchLastProfilingTime = getLastDQProfilingTime.bind(this);
    if (startDate !== null && endDate !== null) {
               data["payload_start_time"] = startDate;
               data["payload_end_time"] = endDate;
    } else {
        let startDate = moment().subtract(29, 'days').startOf('day').format('YYYY-MM-DD 00:00:00').toString();
        let endDate =  moment().utc().endOf('day').format('YYYY-MM-DD 23:59:59').toString();
        data["payload_start_time"] = startDate;
        data["payload_end_time"] = endDate;
    }

    let integrationId = data["data_source"][0];
    fetchLastProfilingTime(integrationId);
    let selectedSource = {"label": selectedDataSrcName, "value": integrationId};
    const teamId = localStorage.getItem('team_id');
    let reqData = {
        "integrations": [integrationId],
        "tenant_id": teamId,
        "payload_start_time":startDate,
        "payload_end_time": endDate
    };
    callProfileAPI(reqData, session, selectedSource, this, "filterLoad");
}

function getProfileData(integrations, teamId, session){
    if (integrations.length === 0) {
        return;
    }

    let firstIntegration = integrations[0];
    let integrationId = firstIntegration['integration_id'];
    let selectedDataSrcName = firstIntegration["integration_name"];
    let selectedSource = {"label": selectedDataSrcName, "value": integrationId};
    let startDate = moment().subtract(29, 'days').startOf('day').format('YYYY-MM-DD 00:00:00').toString();
    let endDate =  moment().utc().endOf('day').format('YYYY-MM-DD 23:59:59').toString();
    this.setState({startDate: startDate, endDate:endDate});
    let reqData = {
        "integrations": [integrationId],
        "tenant_id": teamId,
        "payload_start_time":startDate,
        "payload_end_time": endDate
    };
    callProfileAPI(reqData, session, selectedSource, this, "firstLoad");
}

function getLastDQProfilingTime(integrationId){
    let endPoint = "metrics_store_payload_created_time";
    let data = {"integration_id":integrationId}
    call_get_api(ui_service_ip, endPoint,data).then((response) => {
        if (response === undefined) {
            this.setState({errorOccurred: true});
        } else if (response !== null && response.code === 200) {
            let results = response["results"];
            if (results !== null && results.length > 0){
                this.setState({lastProfilingTime: results[0]["last_processed_time"], isSuccessMessage: true});
            } else{
                this.setState({isSuccessMessage: false});
            }
        }
        else {
            this.setState({errorOccurred: true});
        }
    });
}

function getLastMLProfilingTime(mlMOdelId){
    let endPoint = "metrics_store_payload_created_time?ml_model_id=" + mlMOdelId;
    call_get_api(ui_service_ip, endPoint).then((response) => {
        if (response === undefined) {
            this.setState({errorOccurred: true});
        } else if (response !== null && response.code === 200) {
            let results = response["results"];
            if (results !== null && results.length > 0){
                this.setState({lastMonitorTime: results[0]["payload_created_time"], isSuccessMessage: true});
            } else{
                this.setState({isSuccessMessage: false});
            }
        }
        else {
            this.setState({errorOccurred: true});
        }
    });
}

export function setMlModel(mlModel, key, title, startDate, endDate) {
    const teamId = localStorage.getItem('team_id');
    let session = localStorage.getItem("sessionToken");
    let chartInputData = {
            "tenant_id": teamId,
            "level": "all",
            "ml_model_id": mlModel["value"],
            "metrics_id": null,
            "result_type": "chart"
        }

    if (startDate !=null && endDate !=null) {
               chartInputData["payload_start_time"] = startDate;
               chartInputData["payload_end_time"] = endDate;
    } else {
        startDate = moment().subtract(29, 'days').startOf('day').format('YYYY-MM-DD 00:00:00').toString();
        endDate =  moment().utc().endOf('day').format('YYYY-MM-DD 23:59:59').toString();
        chartInputData["payload_start_time"] = startDate;
        chartInputData["payload_end_time"] = endDate;
    }

    let firstmlModelId = mlModel["value"];
    call_get_api(ui_service_ip, "metrics_store_payload_created_time?ml_model_id=" + firstmlModelId).then((response) => {
        if (response === undefined) {
            this.setState({errorOccurred: true});
        } else if (response !== null && response.code === 200) {
            let results = response["results"];
            if (results !== null && results.length > 0){
                this.setState({lastMonitorTime: results[0]["payload_created_time"], isSuccessMessage: true});
            } else{
                this.setState({isSuccessMessage: false});
            }
        }
        else {
            this.setState({errorOccurred: true});
        }
    });

    call_post_api(ui_service_ip, "monitor/charts", chartInputData, session).then((res) => {
            if (res === undefined) {
                this.setState({errorOccurred: true});
            } else {
                this.setSelectedModelData(res, key, title, mlModel, startDate, endDate);
            }
    });
}

export function setModelPerformanceData(mlModel, key, title, startDate, endDate) {
    const teamId = localStorage.getItem('team_id');
    let session = localStorage.getItem("sessionToken");

    let chartInputData = {
        "ml_model_ids": [mlModel["value"]],
        "cross_chart_data": chartDataCombinations
    }

    // if (startDate !=null && endDate !=null) {
    //     chartInputData["payload_start_time"] = startDate;
    //     chartInputData["payload_end_time"] = endDate;
    // } else {
    //     startDate = moment().subtract(29, 'days').startOf('day').format('YYYY-MM-DD 00:00:00').toString();
    //     endDate =  moment().utc().endOf('day').format('YYYY-MM-DD 23:59:59').toString();
    //     chartInputData["payload_start_time"] = startDate;
    //     chartInputData["payload_end_time"] = endDate;
    // }

    let firstmlModelId = mlModel["value"];
    call_get_api(ui_service_ip, "metrics_store_payload_created_time?ml_model_id=" + firstmlModelId).then((response) => {
        if (response === undefined) {
            this.setState({errorOccurred: true});
        } else if (response !== null && response.code === 200) {
            let results = response["results"];
            if (results !== null && results.length > 0){
                this.setState({lastMonitorTime: results[0]["payload_created_time"]});
            }
        }
        else {
            this.setState({errorOccurred: true});
        }
    });

    const perfResEndPoint = `model_performance/results?tenant_id=${teamId}`;
    call_post_api(ui_service_ip, perfResEndPoint, chartInputData, session).then((res) => {
        if (res === undefined) {
            this.setState({errorOccurred: true});
        }
        else {
            let performanceResults = res["results"];
            this.setSelectedModelData(performanceResults, key, title, mlModel,
                startDate, endDate);
        }
    });
}



function setMonitorChartsForMlModel(models, teamId, session) {
 let startDate = moment().subtract(29, 'days').startOf('day').format('YYYY-MM-DD 00:00:00').toString();
 let endDate =  moment().utc().endOf('day').format('YYYY-MM-DD 23:59:59').toString();
 if (models.length > 0) {
        let firstModel = models[0];
        let mlModelId = firstModel['ml_model_id']
        let chartInputData = {
            "tenant_id": teamId,
            "level": "all",
            "ml_model_id": mlModelId,
            "metrics_id": null,
            "result_type": "chart",
            "payload_start_time": startDate,
            "payload_end_time": endDate
        }
        call_post_api(ui_service_ip, "monitor/charts", chartInputData, session).then((res) => {
            if (res === undefined) {
                this.setState({errorOccurred: true});
            } else {
                this.setAvailableModelsAndChartData(models, res, {"label": firstModel["model_name"], "value": mlModelId}, startDate, endDate);
            }
    });
 }
}

function setPerformanceChartsForMlModel(models, teamId, session) {
    let startDate = moment().subtract(29, 'days').startOf('day').format('YYYY-MM-DD 00:00:00').toString();
    let endDate =  moment().utc().endOf('day').format('YYYY-MM-DD 23:59:59').toString();
    if (models.length > 0) {
        let firstModel = models[0];
        let mlModelId = firstModel['ml_model_id']
        let chartInputData = {
            "ml_model_ids": [mlModelId],
            "cross_chart_data": chartDataCombinations
        }
        const perfResEndPoint = `model_performance/results?tenant_id=${teamId}`;
        call_post_api(ui_service_ip, perfResEndPoint, chartInputData, session).then((res) => {
            if (res === undefined) {
                this.setState({errorOccurred: true});
            }
            else {
                let performanceResults = res["results"];
                this.setAvailableModelsAndChartData(models, performanceResults,
                    {
                        "label": firstModel["model_name"],
                        "value": mlModelId
                    },
                    startDate,
                    endDate);
            }
        });
    }
}

function setMonitorChartDataForIntegrations(allIntegrations, teamId, session) {
     let startDate = moment().subtract(29, 'days').startOf('day').format('YYYY-MM-DD 00:00:00').toString();
     let endDate =  moment().utc().endOf('day').format('YYYY-MM-DD 23:59:59').toString();

    if (allIntegrations.length > 0) {
        let firstIntegration = allIntegrations[0];
        let integrationId = firstIntegration['integration_id']
        let chartInputData = {
            "tenant_id": teamId,
            "env_id": null,
            "integration_ids": [integrationId],
            "data_set_ids": null,
            "mas_id": null,
            "metrics_id": null,
            "level": "all",
            "payload_start_time": startDate,
            "payload_end_time": endDate,
            "integration_id": [integrationId]
        }

        call_post_api(ui_service_ip, "monitor/charts", chartInputData, session).then((res_1) => {
            if (res_1 === undefined) {
                this.setState({errorOccurred: true});
            } else {
                this.setChartData(res_1, allIntegrations, {"label": firstIntegration["integration_name"],"value": integrationId}, startDate, endDate);
            }
        });


        let datasets = getCachedDatasets(teamId, integrationId)
        if (datasets !== null) {
            this.setAvailableDataSets(datasets, null, "", {"label": firstIntegration["integration_name"],"value": integrationId})
            return;
        }
//        let endPoint = `datasets?tenant_id=${teamId}&integration_id=${integrationId}`;
        call_get_api(ui_service_ip, "/datasets" , chartInputData, session).then((res) => {
            if (res === undefined) {
                this.setState({errorOccurred: true});
            } else {
                const datasets = res["datasets"];
                this.setAvailableDataSets(datasets, null, "", {"label": firstIntegration["integration_name"],"value": integrationId});
                cacheDatasets(datasets, teamId, integrationId);
            }
        });
    }
}

function getModelDatasets(model) {
         let modelDetails = model.model_details;
         let datasets = []
         if(modelDetails.model_data_id !== undefined && modelDetails.model_data !== undefined) {
           datasets.push({"label":modelDetails.model_data, "value":modelDetails.model_data_id });
         }
         if(modelDetails.raw_data !== undefined && modelDetails.raw_data_id !== undefined) {
           datasets.push({"label":modelDetails.raw_data, "value":modelDetails.raw_data_id })
         }
         if(modelDetails.test_data !== undefined && modelDetails.test_data_id !== undefined) {
           datasets.push({"label":modelDetails.test_data, "value":modelDetails.test_data_id })
         }
         let features = model.features;
         if(features.length > 0) {
            let model_info_details = features[0]["model_info_details"]
            datasets.push({"label": features[0]["base_line_data_set"], "value": model_info_details["base_line_data_set_id"], "type": null})
            datasets.push({"label": features[0]["target_data_set"], "value": model_info_details["target_data_set"], "type": features[0]["type"]})
            model_info_details = features[2]["model_info_details"]
            datasets.push({"label": features[2]["target_data_set"], "value": model_info_details["target_data_set"], "type": features[2]["type"]})
            model_info_details = features[3]["model_info_details"]
            datasets.push({"label": features[3]["target_data_set"], "value": model_info_details["target_data_set"], "type": features[3]["type"]})
            if(features[1]["target_data_set"] !== "") {
               model_info_details = features[1]["model_info_details"]
               datasets.push({"label": features[1]["target_data_set"], "value": model_info_details["target_data_set"], "type": features[1]["type"]})
            }
         }
         let jsonObject = datasets.map(JSON.stringify);
         let uniqueSet = new Set(jsonObject);
         datasets = Array.from(uniqueSet).map(JSON.parse);

         return datasets

}

export function setIntegrationDetails(integration_details){
        let dataSources = [];
		if(integration_details !== undefined && integration_details.length > 0) {
             dataSources = integration_details.map(function(row) {
               return {"value":row['integration_id'], "label": row["integration_name"], "env_id":row["env_id"]};
             });
		}
        this.setState({dataSources: dataSources,
                       integration_details: integration_details,
        });
}

export function getPaymentDetailsTableData(cardData) {
    let allPaymentDetails = [];
    if (cardData === undefined || cardData === null) {
        return allPaymentDetails;
    }

    for (let card of cardData) {
        let cardDetail = card.details;
        let requiredCardData = {
            "customerName": cardDetail.cardholder_name,
            "isPrimary": card.is_default === 1,
            "id": card.payment_detail_id,
            "brand": cardDetail.card_type,
            "expires": `${String(cardDetail.expiration_month)}/${cardDetail.expiration_year % 100}`,
            "lastFourDigits": cardDetail.last_4,
        };
        allPaymentDetails.push(requiredCardData);
    }

    return allPaymentDetails;
}

export function getBillingAddressDetails(addressDetails) {
    let reqAddressDetails = {};
    if (addressDetails === undefined || addressDetails === null) {
        return reqAddressDetails;
    }
    for (let data of addressDetails) {
        let billingAddress = data.details;
        reqAddressDetails[data.billing_address_id] = billingAddress;
    }

    return reqAddressDetails;
}

export function getBillingHistoryTableData(billingData) {
    let headers = ["Order ID", "Plan", "Billing Date",
        "Card Number", "Amount", "Status", "Receipt"];
    let billingTableRows = [];

    let billingTableData = {
        "headers": headers,
        "data": billingTableRows
    };

    if (billingData === undefined || billingData === null) {
        return billingTableData;
    }
    let index = 0;
    for (let billing of billingData) {
        let rowData = [];
        let billingDetail = billing.details;
        rowData.push({"value": billing.order_id, "type": "td"});
        rowData.push({"value": `Qualdo ${billingDetail.plan_title}`, "type": "td"});
        // rowData.push({"value": "Aug 4, 2020", "type": "td"});
        rowData.push({"value": `${billing.created_time}`, "type": "td"});
        rowData.push({"value": `**** ${billingDetail.last_4}`, "type": "td"});
        rowData.push({"value": `${billing.currency_symbol}${billing.amount}`, "type": "td"});

        // "03 Aug 2021",
        let addOnDetail = {
            "nextDate": `${billing.next_payment_date}`,
            "showTooltip": index === 0,
        }

        addOnDetail["billing_address_id"] = billing.billing_address_id;
        rowData.push({"value": "", "billingDetails": addOnDetail, "type": "billingComponent"});
        rowData.push({"value": billing,  "type": "td"});
        billingTableRows.push(rowData);
        index = index + 1;
    }

    return billingTableData;
}

/*
function formPipelineTableData(inputData,selectedTab) {
    let headers=["Run ID","Timing","Job","Status"];
    let final_op=[];
    for(let i=0; i < inputData.runs.length; i++) {
        let row=[];
        let pipeline = inputData['runs'][i];
        let reqData = {}
        reqData['value'] = pipeline['run_started_time']
        reqData['duration'] = pipeline['duration']
        row.push({"value": pipeline, "type": "td"});
        row.push({"value": reqData, "type": "pipeline"});
        row.push({"value": pipeline, "type": "pipeline"});
        row.push({"value": pipeline['run_status_text'], "type": "td"});
        final_op.push(row);
    }
    let table_data ={"headers": headers,"data":final_op};
    if(this.state.current_tab === 'fr'){
        table_data = {"headers": headers, "data": failed_run_op}
    }
    return table_data;
}*/



export function getApi(api_name, details={}) {
    const team_id= localStorage.getItem('team_id');
    let session = localStorage.getItem("sessionToken");
    let data = {"tenant_id": team_id}
    switch(api_name) {
        case "get_pipeline_runs_stage_info":
            data.pipeline_job_id = this.state.gantt_chart_jobid;
            data.job_run_id = this.state.gantt_chart_runid;
            // data.page = 1;
            call_get_api(ui_service_ip, "/runs_stage_info", data).then((response) => {
                if (response === undefined) {
                    this.setState({progress:100,errorOccurred: true});
                } else if (response.code === 200) {
                    let json = response;
                    let tasks =[];
                    let failed_task_cnt =0,others_task_cnt=0,success_task_cnt = 0;
                    for(let loop=0;loop<json.stages.length;loop++) {

                        var depends = []

                    for(var j in json.stages[loop]["stage_details"]["depends_on"]){
                        depends.push(j)
                    }
                    depends = depends.toString().split(",")
                    let bg_color;
                    if(json.stages[loop]["stage_status_text"]==="Failed"){
                        bg_color="#F64E60";
                        failed_task_cnt++;
                    }else if(json.stages[loop]["stage_status_text"]==="Succeeded") {
                        bg_color="#4ED2CC";
                        success_task_cnt++;
                    }
                    else {
                        bg_color="#795548";
                        others_task_cnt++;
                    }
                    let obj = {
                            "start": new Date(json.stages[loop]["stage_started_time"]),
                            "end": new Date(json.stages[loop]["stage_end_time"]),
                            "name": json.stages[loop]["stage_name"],
                            "id": json.stages[loop]["stage_name"],
                            "type": "task",
                            "hideChildren": false,
                            "progress": 100,
                            "isDisabled": true,
                            //"dependencies": [depends],
                            "dependencies": depends,
                            "status":json.stages[loop]["stage_status_text"],
                            "TooltipContent":"<div>Testses</div>",
                            "styles":
                            { 'progressColor': bg_color, 'progressSelectedColor': bg_color,'backgroundColor':bg_color,'backgroundSelectedColor':bg_color},
                        }
                        tasks.push(obj)
                    }

                    console.log(tasks)
                    this.setState({progress:100,
                        gantt_chart_details: tasks,
                        gantt_chart_grid:json.stages,
                        gantt_chart_grid_temp:json.stages,
                        failed_task_cnt:failed_task_cnt,
                        others_task_cnt:others_task_cnt,
                        success_task_cnt:success_task_cnt
                    });}});
        break;
        case "get_pipeline_run_details":
            data.pipeline_job_id = this.state.detailJobId;
            data.page = 1;
            call_get_api(ui_service_ip, "/pipeline_run_info", data).then((response) => {
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                   // this.setState({errorOccurred: true});
                   // response.pipeline_metadata
                //    let tableData = formPipelineTableData(response,this.state.current_tab)
                //    this.setState({tableData:tableData})
                  this.setState({ progress:100,selectedJobRunsFullscreen: response.runs,selectedJobRuns:response.runs,selectedJobDetails: response,
                    fullscreen_api_call_loader:1
                  })
                }}
                );
                // data.pipeline_job_id = this.state.gantt_chart_jobid;
                call_get_api(ui_service_ip, "/runs_stage_info", data).then((response) => {
                    if (response === undefined) {
                        this.setState({progress:100,errorOccurred: true});
                    } else if (response.code === 200) {
                        let json = response;
                        let tasks =[];
                        for(let loop=0;loop<json.stages.length;loop++) {
                        var depends = []
                        for(var j in json.stages[loop]["stage_details"]["depends_on"]){
                               depends.push(j)
                        }
                        depends = depends.toString().split(",")
                        let bg_color;
                        if(json.stages[loop]["stage_status_text"]==="Failed"){
                            bg_color="#F64E60";
                        }else {
                            bg_color="#4ED2CC";
                        }
                        let obj = {
                                "start": new Date(2020, 1, 1, 1, 0, 0),
                                "end": new Date(2020, 1, 1, 5, 0, 0),
                                "name": json.stages[loop]["stage_name"],
                                "id": json.stages[loop]["stage_name"],
                                "type": "task",
                                "hideChildren": false,
                                "progress": 100,
                                "isDisabled": true,
                                "dependencies": depends,
                                "stage_details": json.stages[loop]["stage_details"],
                                "status":json.stages[loop]["stage_status_text"],
                                "TooltipContent":"<div>Testses</div>",
                                "styles":
                                {backgroundColor: bg_color,
                                progressColor: bg_color,
                                progressSelectedColor: bg_color,
                                fontColor: '#0000ff' // Set your custom font color here
                          }
                            }
                            tasks.push(obj)

                        }
                        this.setState({progress:100,gantt_chart_job_details: tasks});}});
            break;
            case "get_lineage_data":
                let lineageDataRequest = "";
               if(this.state.lienage_graph_type === "run_level"){
                lineageDataRequest = {
                    tenant_id: team_id,
                    job_run_id: this.state.detailru,
                    pipeline_job_id: this.state.detailJobId,
                    lineage_type:"run-level",
                    pipeline_integration_id: this.state.fullscreenselectedPipelineID,
                };
               }
               else {
                lineageDataRequest = {
                    tenant_id: team_id,
                    pipeline_integration_id: this.state.fullscreenselectedPipelineID,
                    pipeline_job_id: this.state.detailJobId,
                    lineage_type: this.state.lienage_graph_type
                };
               }
               call_get_api(ui_service_ip, "/lineage_data", lineageDataRequest).then((response) => {
                   if (response === undefined) {
                       this.setState({ errorOccurred: true });
                   } else {
                       if (response.code === 200) {
                           let lineageData = response.lineage_data;
                           this.setState({ lineageData: lineageData });
                       }
                   }
               });
            break;
        case "get_pipelineprefetch":
            let get_pipelineprefetch = [];
            let store_request_pipelineprefetch = [];
                        let pipelineintegrionlistjson = this.state.monitor_pipeline_list;
                        pipelineintegrionlistjson = pipelineintegrionlistjson.filter( element => element.pipeline_integration_id === this.state.selectedDatasource.value)
                        let pipelinelen = pipelineintegrionlistjson.length;
                        for(let loop=0;loop<pipelinelen;loop++){
                        data = {}
                        data = {"tenant_id": team_id}
                        data.pipeline_integration_id = pipelineintegrionlistjson[loop].pipeline_integration_id;
                        data.pipeline_integration_name = pipelineintegrionlistjson[loop].pipeline_integration_name;
                        data.pipeline_type = pipelineintegrionlistjson[loop].pipeline_type;
                        data.pipeline_created_date = pipelineintegrionlistjson[loop].created_time;
                        store_request_pipelineprefetch[loop] = data;
                        call_get_api(ui_service_ip, "/pipeline_quick_view", store_request_pipelineprefetch[loop]).then((response) => {
                            if (response === undefined) {
                                this.setState({errorOccurred: true});
                            } else {
                                if (response.code === 200 ){
                                    let newdata = store_request_pipelineprefetch[loop];
                                    if(response.data.length > 0){
                                        response.data[0]['last_profiled_time'] = response['last_profiled_time'];
                                        newdata.pipelinedata = response.data;
                                        get_pipelineprefetch.push(newdata)

                                    if(loop === pipelinelen-1){
                                        let detailJobId = null;
                                        let pipelineData = get_pipelineprefetch[0]["pipelinedata"];
                                        for (let i = 0; i < pipelineData.length; i++) {
                                            if (pipelineData[i]["is_parent"] === "true") {
                                                detailJobId = pipelineData[i]["pipeline_job_id"];
                                                break;
                                            }
                                        }
                                        if (detailJobId === null && pipelineData.length > 0) {
                                            detailJobId = pipelineData[0]["pipeline_job_id"];
                                        }

                                        if (details.detailscreen === 1) {
                                            this.setState({
                                                pipelinesource_data: get_pipelineprefetch,
                                                fullscreenpipelinesource_data: get_pipelineprefetch,
                                                detailJobId: detailJobId,
                                                trigger_first_click: details.detailscreen === 1 ? 1 : 0,
                                                detailJobList: pipelineData,
                                                // fullscreen_api_call_loader: 1,
                                                progress: 100,
                                                errorMessage: ""
                                            });
                                        }else{

                                            this.setState({
                                                pipelinesource_data:get_pipelineprefetch,
                                                api_call_loader:1,
                                                enableFadeLoader:false,
                                                fullscreenpipelinesource_data:get_pipelineprefetch,
                                                errorMessage: ""
                                            });
                                        }
                                    }
                                    }
                                    else {
                                        this.setState({
                                            api_call_loader:0,errorMessage: "No data available"})
                                    }
                                }
                            }
                        });
                    }

        // call_get_api(ui_service_ip, "pipelineintegrations",data).then((response) => {
        //     if (response === undefined) {
        //         this.setState({errorOccurred: true});

        //     } else {
        //         if (response.code === 200){
        //             let pipelineintegrionlistjson =  response;
        //             let pipelinelen =  pipelineintegrionlistjson.integrations.length;
        //             // pipelinelen =  1;

        //             if(pipelinelen === 0){
        //                 this.setState({pipelinesource_data:"No Integeration Found"});
        //                 return;
        //             }

        //             for(let loop=0;loop<pipelinelen;loop++){
        //                 data = {}
        //                 data = {"tenant_id": team_id}
        //                 data.pipeline_integration_id = pipelineintegrionlistjson.integrations[loop].pipeline_integration_id;
        //                 data.pipeline_integration_name = pipelineintegrionlistjson.integrations[loop].pipeline_integration_name;
        //                 // data.failed_count = pipelineintegrionlistjson.integrations[loop].latest_details.failed_count;
        //                 // data.success_count = pipelineintegrionlistjson.integrations[loop].latest_details.success_count;
        //                 // data.total_count = pipelineintegrionlistjson.integrations[loop].latest_details.total_count;
        //                 data.pipeline_type = pipelineintegrionlistjson.integrations[loop].pipeline_type;
        //                 data.pipeline_created_date = pipelineintegrionlistjson.integrations[loop].created_time;
        //                 store_request_pipelineprefetch[loop] = data;
        //              //   let datapipelineprefetch = {"tenant_id": team_id,"pipeline_integration_id":7}
        //                 call_get_api(ui_service_ip, "/pipeline_quick_view", store_request_pipelineprefetch[loop]).then((response) => {
        //                     if (response === undefined) {
        //                         this.setState({errorOccurred: true});
        //                     } else {
        //                         if (response.code === 200 ){
        //                             let newdata = store_request_pipelineprefetch[loop];
        //                             if(response.data.length > 0){
        //                                 response.data[0]['last_profiled_time'] = response['last_profiled_time'];
        //                                 newdata.pipelinedata = response.data;
        //                                 get_pipelineprefetch.push(newdata)
        //                             }
        //                             if(loop === pipelinelen-1){
        //                                 this.setState({pipelinesource_data:get_pipelineprefetch});
        //                             }
        //                         }
        //                     }
        //                 });

        //             }

        //         }
        //     }

        // });
        // alert(JSON.stringify(this.state.selectedDatasource))
        break;
        case "get_pipeline_monitor":
                call_get_api(ui_service_ip, "/pipelineintegrations", data).then((response) => {
                    if (response === undefined) {
                        this.setState({errorOccurred: true});
                    } else if (response.code === 200) {
                        let pipelinesource = [];
                        for(let loop=0;loop<response["integrations"].length;loop++){
                            pipelinesource.push({ "label": response["integrations"][loop].pipeline_integration_name, "value": response["integrations"][loop].pipeline_integration_id });
                        }
                        let lastmodifiedrecord = [];
                        if(pipelinesource.length > 0){
                            lastmodifiedrecord =  response["integrations"].sort(function(a,b){
                                var x;
                                if(a.latest_job_run_time!==null && b.latest_job_run_time!==null){
                                x = moment(a.latest_job_run_time) > moment(b.latest_job_run_time)? -1:1;
                                }
                                return x;
                            });
                        }
                        this.setState(
                            {
                                "selectedDatasource": lastmodifiedrecord.length > 0 ?  { "label": lastmodifiedrecord[0].pipeline_integration_name, "value": lastmodifiedrecord[0].pipeline_integration_id } : "",
                                "filter_pipeline_id":lastmodifiedrecord.length > 0 ? lastmodifiedrecord[0]["pipeline_integration_id"] : "",
                                "monitor_pipeline_list": response["integrations"],
                                "pipeline_source_list":pipelinesource,
                                "latest_job_run_time":lastmodifiedrecord[0]["latest_job_run_time"],
                                "pipeline_created_time":lastmodifiedrecord[0]["created_time"],
                                "pipeline_integrations":response["integrations"],
                                "clearall_pipeline_integrations_id":lastmodifiedrecord[0].pipeline_integration_id
                            },
                            () => {
                                this.getApi("get_pipelineprefetch");
                            }
                        );

                        // this.setState({
                        // monitor_pipeline_list: response["integrations"],
                        // pipeline_source_list:pipelinesource
                        // });

                    } else if(response.code === 403) {
                        localStorage.removeItem("sessionToken")
                        window.location = '/';
                    } else {
                        this.setState({errorOccurred: true});
                    }
                });
        break;
        case "get_pipeline":
                call_get_api(ui_service_ip, "/integrations", data).then((response) => {
                    if (response === undefined) {
                        this.setState({errorOccurred: true});
                    } else if (response.code === 200) {
                        let pipelinedatasourcelen =  response["data"].length;
                        let pipelinedatasource = []
                        for(let loop=0;loop<pipelinedatasourcelen;loop++){
                            pipelinedatasource.push(response["data"][loop]['metadata']['pipeline_name'])
                        }

                        localStorage.setItem("piplineinitaldata",JSON.stringify(response["data"]));

                        this.setState({
                            initial:{
                                data:response["data"]
                            },
                            pipelinedatasource:pipelinedatasource,
                            pipelinelist:response["data"],
                            filterpipelinelist:response["data"]});
                    } else if(response.code === 403) {
                        localStorage.removeItem("sessionToken")
                        window.location = '/';
                    } else {
                        this.setState({errorOccurred: true});
                    }
                });
break;
        case "dataset":
           call_get_api(ui_service_ip, "/datasets",data).then( (response) =>{
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    this.setState({datasets:response['datasets']});
                    call_get_api(ui_service_ip,  "/metadatas", data).then((res_1) =>{
                         if (res_1 === undefined) {
                            this.setState({errorOccurred: true});
                         } else {
                            this.setState({attributes: res_1["meta_data"]});
                        }
                    })
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken")
                    window.location = '/';
                } else {
                    this.setState({errorOccurred: true});
                }
            });
            break;
        case "data_set":
         call_get_api(ui_service_ip, "/datasets",data).then( (response) =>{
                if (response === undefined) {
                        this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    let details = {...this.state.details};
                    details.data_set = response['datasets'];
                    call_get_api(ui_service_ip,  "/metadatas",data).then((res_1) =>{
                         if (res_1 === undefined) {
                            this.setState({errorOccurred: true});
                         } else {
                            details.attributes = res_1["meta_data"];
                            this.setState({details})
                        }
                    })
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken")
                    window.location = '/';
                } else {
                    this.setState({errorOccurred: true});
                }
            });
            break;

        case "alert_details":
            call_get_api(ui_service_ip, "/datasets",data).then( (response) =>{
                if (response === undefined) {
                        this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    let details = {...this.state.details};
                    details.data_set = response['datasets'];
                    call_get_api(ui_service_ip,  "consume_group", data).then((res_1) =>{
                        if(res_1 === undefined){
                            this.setState({errorOccurred: true});
                            this.setState({details})
                            return;
                        }
                        details.consume_group = res_1["consume_group"];
                        this.setState({details})
                    })
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken")
                    window.location = '/';
                } else {
                   this.setState({errorOccurred: true});
                }
            });
            break;
        case "trigger":
            let notificationResponse = [];
            let pipelineResponse = [];
            call_get_api(ui_service_ip, "consume_group", data).then((response) =>{
                if (response === undefined) {
                        this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                     notificationResponse = response.consume_group;
                     this.setState({notificationOptions:notificationResponse});
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                } else {
                    this.setState({errorOccurred: true});
                }
            })
            call_get_api(ui_service_ip, "pipeline_trigger", data).then((response) =>{
                if (response === undefined) {
                        this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    pipelineResponse = response.pipeline_trigger;
                    this.setState({pipelineOptions:pipelineResponse});
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                } else {
                   this.setState({errorOccurred: true});
                }
            })

            break;
        case "get_alerts_for_tenants":
            data["mas_type"] = "dq";
            call_get_api(ui_service_ip, "alert", data).then((response) => {
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                   let res = alert_table(response.alerts);
                   this.setState({alertsDataDq:res})
                } else {
                    this.setState({errorOccurred: true});
                }
            });
            data["mas_type"] ="ml";
            call_get_api(ui_service_ip, "alert", data).then((response) => {
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                   let res = alert_table(response.alerts);
                   this.setState({alertsDataMl:res})
                } else {
                    this.setState({errorOccurred: true});
                }
            });
            break;

        case "consume_group":
            call_get_api(ui_service_ip, "consume_group", data).then((response) =>{
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    let res=consume_group_table(response.consume_group);
                    this.setState({data:res})
                    this.setState({notification_title:"AVAILABLE NOTIFICATIONS"})
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                } else {
                    this.setState({errorOccurred: true});
                }
            })
            break;

        case "pipeline_trigger":
            call_get_api(ui_service_ip, "pipeline_trigger", data).then((response) =>{
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    let res=pipeline_table(response.pipeline_trigger);
                    this.setState({pipeline_data:res})
                    this.setState({pipeline_title:"AVAILABLE PIPELINE TRIGGERS"})
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                } else {
                    this.setState({errorOccurred: true});
                }
            })
            break;
        case "monitor_ml_charts":
            let setMlMonitor = setMonitorChartsForMlModel.bind(this);
            let mlModels = getCachedModels(team_id);
            if (mlModels !== null) {
                setMlMonitor(mlModels, team_id, session);
                let firstmlModelId = mlModels[0]["ml_model_id"];
                call_get_api(ui_service_ip, "metrics_store_payload_created_time?ml_model_id=" + firstmlModelId).then((response) => {
                    if (response === undefined) {
                        this.setState({errorOccurred: true});
                    } else if (response !== null && response.code === 200) {
                        let results = response["results"];
                        if (results !== null && results.length > 0){
                            this.setState({lastMonitorTime: results[0]["payload_created_time"], isSuccessMessage: true});
                        } else{
                            this.setState({isSuccessMessage: false})
                        }
                    }
                    else {
                        this.setState({errorOccurred: true});
                    }
                });

                return
            } else {
                call_get_api(ui_service_ip, "/ml-models/list", data, session).then((mlRes) => {
                    if (mlRes === undefined) {
                        this.setState({errorOccurred: true});
                    } else if(mlRes.code === 200) {
                        const mlModels = mlRes["response"];
                        let firstmlModelId = mlModels[0]["ml_model_id"];
                        call_get_api(ui_service_ip, "metrics_store_payload_created_time?ml_model_id=" + firstmlModelId).then((response) => {
                            if (response === undefined) {
                                this.setState({errorOccurred: true});
                            } else if (response !== null && response.code === 200) {
                                let results = response["results"];
                                if (results !== null && results.length > 0){
                                    this.setState({lastMonitorTime: results[0]["payload_created_time"], isSuccessMessage: true});
                                } else{
                                    this.setState({isSuccessMessage: false})
                                }
                            }
                            else {
                                this.setState({errorOccurred: true});
                            }
                        });
                        setMlMonitor(mlModels, team_id, session);
                        cacheMlModels(mlModels, team_id);
                    }
                });
            }
            break;
        case "modelPerformance":
        {
            let setMlModelPerformance = setPerformanceChartsForMlModel.bind(this);
            let mlModels = getCachedModels(team_id);
            if (mlModels !== null) {
                setMlModelPerformance(mlModels, team_id, session);
                let firstmlModelId = mlModels[0]["ml_model_id"];
                call_get_api(ui_service_ip, "metrics_store_payload_created_time?ml_model_id=" + firstmlModelId).then((response) => {
                    if (response === undefined) {
                        this.setState({errorOccurred: true});
                    } else if (response !== null && response.code === 200) {
                        let results = response["results"]
                        if (results !== null && results.length > 0){
                            this.setState({lastMonitorTime: results[0]["payload_created_time"], isSuccessMessage: true});
                        } else{
                            this.setState({isSuccessMessage : false});
                        }

                    }
                    else {
                        this.setState({errorOccurred: true});
                    }
                });
                return;
            } else {
                call_get_api(ui_service_ip,"/ml-models/list",data, session).then((mlRes) => {
                    if (mlRes === undefined) {
                        this.setState({errorOccurred: true});
                    } else if(mlRes.code === 200) {
                        const mlModels = mlRes["response"];
                        let firstmlModelId = mlModels[0]["ml_model_id"];
                        call_get_api(ui_service_ip, "metrics_store_payload_created_time?ml_model_id=" + firstmlModelId).then((response) => {
                            if (response === undefined) {
                                this.setState({errorOccurred: true});
                            } else if (response !== null && response.code === 200) {
                                let results = response["results"];
                                if (results !== null && results.length > 0){
                                    this.setState({lastMonitorTime: results[0]["payload_created_time"], isSuccessMessage: true});
                                } else{
                                    this.setState({isSuccessMessage: false});
                                }
                            }
                            else {
                                this.setState({errorOccurred: true});
                            }
                        });
                        setMlModelPerformance(mlModels, team_id, session);
                        cacheMlModels(mlModels, team_id);
                    }
                });
            }
        }

            break;
        case "monitor_charts":
            let setMonitor = setMonitorChartDataForIntegrations.bind(this);
            let cachedIntegrations = getCachedIntegrations(team_id);
            if (cachedIntegrations !== null && cachedIntegrations.length > 0) {
                let firstCachedIntegrationId = cachedIntegrations[0]["integration_id"];
                data["integration_id"] = firstCachedIntegrationId;
                call_get_api(ui_service_ip, "metrics_store_payload_created_time",data).then((response) => {
                    if (response === undefined) {
                        this.setState({errorOccurred: true});
                    } else if (response !== null && response.code === 200) {
                        let results = response["results"];
                        if (results !== null && results.length > 0){
                            this.setState({lastMonitorTime: results[0]["last_processed_time"],isSuccessMessage: true});
                        } else{
                            this.setState({isSuccessMessage: false});
                        }

                    }
                    else {
                        this.setState({errorOccurred: true});
                    }
                });
                setMonitor(cachedIntegrations, team_id, session);
                return;
            }
            data = {tenant_id: team_id}
            call_get_api(ui_service_ip, "/integrations", data).then((response) => {
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    const integrations = response['integrations'];
                    if(integrations !==null && integrations.length > 0){

                       let integrationId = integrations[0]["integration_id"];
                       data["integration_id"] = integrationId;
                        call_get_api(ui_service_ip, "metrics_store_payload_created_time", data).then((response) => {
                            if (response === undefined) {
                                this.setState({errorOccurred: true});
                            } else if (response !== null && response.code === 200) {
                                let results = response["results"];
                                if (results !== null && results.length > 0){
                                    this.setState({lastMonitorTime: results[0]["last_processed_time"]});
                                }
                            }
                            else {
                                this.setState({errorOccurred: true});
                            }
                        });

                    }



                    setMonitor(integrations, team_id, session);
                    cachedIntegrationData(integrations, team_id);
                }
                else if (response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                }
                else {
                    this.setState({errorOccurred: true});
                }
            })
            break;
        case "profile_cards_and_charts":
            let fetchProfileData = getProfileData.bind(this);
            let fetchLastProfilingTime = getLastDQProfilingTime.bind(this);
            let integrations = getCachedIntegrations(team_id);
            if (integrations !== null && integrations.length > 0) {
                this.setIntegrationDetails(integrations);
                fetchProfileData(integrations, team_id, session);
                let firstCachedIntegrationId = integrations[0]["integration_id"];
                fetchLastProfilingTime(firstCachedIntegrationId);
                break;
            }
            data = {tenant_id: team_id}
            call_get_api(ui_service_ip, "/integrations",data).then((response) => {
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    let integrations = response['integrations'];
                    fetchProfileData(integrations, team_id, session);
                    this.setIntegrationDetails(integrations);
                    let firstCachedIntegrationId = integrations[0]["integration_id"];
                    fetchLastProfilingTime(firstCachedIntegrationId);
                    if (integrations.length > 0) {
                        cachedIntegrationData(integrations, team_id);
                    }
                }
            });
            break;
        case "profile_ml_filter":
           let fetchProfileMLData = getProfileMLData.bind(this);
            let ml_integrations = getCachedIntegrations(team_id);
            if (ml_integrations !== null && ml_integrations.length > 0) {
                fetchProfileMLData(ml_integrations, team_id, session);
                break;
            }
            data = {tenant_id: team_id}
            call_get_api(ui_service_ip, "/integrations", data).then((response) => {
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    let ml_integrations = response['integrations'];
                    if (ml_integrations.length > 0) {
                        cachedIntegrationData(ml_integrations, team_id);
                        fetchProfileMLData(ml_integrations, team_id, session);
                    }
                }
            });
            break;
        case "profile_cards_filter":
            let req_datasets = this.state.filter_data["dataset"]
            if (req_datasets === null || req_datasets === undefined || req_datasets.length === 0){
                return
            }

            let chart_input_data ={ "tenant_id": team_id,
                "env_id": null,
                "integration_ids": null,
                "data_set_ids": [req_datasets[0]],
                "mas_id": null,
                "metrics_id": null
            }
            call_post_api(ui_service_ip, "metrics/charts", chart_input_data, session).then((res_1) =>{
                this.setChartData(res_1)
            });
            break
        case "getMetrics":
            call_get_api(ui_service_ip, "metricsassociation").then((response) => {
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    DQM_widget_setup("dq", response.metrics_association, team_id).then((res) => {
                        let details = {...this.state.details};
                        details.dqMetrics=res;
                        this.setState({details});
                    });
                    DQM_widget_setup("ml", response.metrics_association, team_id).then((res) => {
                        let details = {...this.state.details};
                        details.mlMetrics=res;
                        this.setState({details});
                    });
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken")
                    window.location = '/';
                } else {
                    this.setState({errorOccurred: true});
                }
            });
            break;
        case "checkConsistency":
            let consistencyEndPoint = "check_consistency";
            call_get_api(ui_service_ip, consistencyEndPoint,data).then((response) => {
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else {
                    let isConsistencyEnabled = response.is_consistency_enabled;
                    this.setState({isConsistencyEnabled:isConsistencyEnabled});
                    if (response.code === 200){
                        this.setState({enabledMetrics:response["enabled_metrics"]});
                    }
                }

            });
            break;

        case "completeMetricInfo":
            const endPoint = "metrics/all";
            call_get_api(ui_service_ip, endPoint, data).then((response) => {
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    let data = response.data;
                    if (data === undefined || data === null){
                        return;
                    }

                    let dqmData = data.dqm;
                    let mlmData = data.mlm;
                    this.setState({
                        dqmData: dqmData,
                        mlmData: mlmData
                    });
                }
                else {

                }
            });
            break;

        case "metricsassociation":
            call_get_api(ui_service_ip, "metricsassociation").then((response) => {
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    DQM_widget_setup(this.state.details.page, response.metrics_association,
                        team_id).then((res) => {
                        if(res.length > 0) {
                            if(this.state.details.page === "dq")
                            {
                             this.setState({dqm_card_data:res});
                            }else if (this.state.details.page === "ml")
                            {
                              this.setState({mlm_card_data:res});
                            }
                            fill_table(res, team_id).then((res_1) => {
                                let details = {...this.state.details};
                                details.data[this.state.details.page] = res_1;
                                details.card_data = res;
                                this.setState({details});
                                if (this.state.details.page === "dq") {
                                    let dqmData = this.state.dqm_card_data;
                                    const all_metric_details = res_1["metric_details"];
                                    for (let i = 0; i < dqmData.length; i++) {
                                        let dqmDataValue = dqmData[i];
                                        if (dqmDataValue === undefined || dqmDataValue === null) {
                                            continue;
                                        }
                                        const dqmDataElement = dqmDataValue["metric_data"];
                                        for (let j = 0; j < dqmDataElement.length; j++) {
                                            const curr_mas_id = dqmDataValue["mas_id"];
                                            dqmDataElement[j]["metric_def"] = all_metric_details[curr_mas_id][j];
                                        }
                                    }
                                    this.setState({dqm_card_data: dqmData});
                                }
                                else if (this.state.details.page === "ml") {
                                    let mlmData = this.state.mlm_card_data;
                                    for (let i = 0; i < mlmData.length; i++) {
                                        let mlDataValue = mlmData[i];
                                        if (mlDataValue === undefined || mlDataValue === null) {
                                            continue;
                                        }
                                        for (let j = 0; j < mlDataValue["metric_data"].length; j++) {
                                            mlDataValue["metric_data"][j]["metric_def"] = res_1["metric_details"][mlDataValue["mas_id"]][j];
                                        }

                                    }
                                    this.setState({mlm_card_data: mlmData});
                                }
                            });
                        }
                    });
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken")
                    window.location = '/';
                } else {
                   this.setState({errorOccurred: true});
                }
            });
            break;
        case "environments":
            let environments = getCachedEnvironments(team_id);
            if (environments !== null && environments.length > 0) {
                let option_arr = environments.map(function (row) {
                    return {"label": row["env_name"], "value": row["env_id"]};
                });
                this.setState({environment: option_arr});
                break;
            }

            call_get_api(ui_service_ip, "/environments",data).then((response) => {
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    let option_arr = [];
                    let environments = response["environments"];
                    if (environments.length > 0) {
                        cacheEnvironmentData(environments, team_id);
                        option_arr = environments.map(function (row) {
                            return {"label": row["env_name"], "value": row["env_id"]};
                        });
                    }
                    this.setState({environment: option_arr});
                }
                else if (response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                }
                else {
                    this.setState({errorOccurred: true});
                }
            });
            break;
        case "profile_ml_minimal_integrations":
            let min_integrations = getCachedIntegrations(team_id);
            if (min_integrations !== null && min_integrations.length > 0) {
                this.setState({integration_details: min_integrations});
                break;
            }
            data = {tenant_id: team_id}
            call_get_api(ui_service_ip, "/integrations", data).then((response) => {
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    let integrations = response["integrations"];
                    this.setState({integration_details: min_integrations});
                    cachedIntegrationData(integrations, team_id);
                }
                else if (response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                }
                else {
                    this.setState({errorOccurred: true});
                }
            });
            break;
        case "datasource_updates":
            call_get_api(ui_service_ip, "data_refreshes_info", data).then((response) => {
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    let res = [];
                    let  datasource_updates = response["final"];
                    res = datasource_updates_table(datasource_updates);
                    this.setState({
                        datasource_updates: res,
                    });
                }
                else if (response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                }
                else {
                    this.setState({errorOccurred: true});
                }
            });
            break;
        case "pipelinesource_updates":
                call_get_api(ui_service_ip, "pipeline_refreshes_info", data).then((response) => {
                    if (response === undefined) {
                        this.setState({errorOccurred: true});
                    } else if (response.code === 200) {
                        let res = [];
                        let  pipelinesource_updates = response["data"];
                        res = pipelinesource_updates_table(pipelinesource_updates);
                        this.setState({
                            pipelinesource_updates: res,
                        });
                    }
                    else if (response.code === 403) {
                        localStorage.removeItem("sessionToken");
                        window.location = '/';
                    }
                    else {
                        this.setState({errorOccurred: true});
                    }
                });
                break;
        case "modelABData":
            call_get_api(ui_service_ip, "model_ab_data").then((response) => {
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                    return;
                }

                if (response.code === 200) {
                    let fetched_ab_data = response["model_ab_data"];
                    let model_info_map = response["model_info_map"];
                    let res = prepare_model_ab_table_data(fetched_ab_data, model_info_map);
                    this.setState({
                        model_ab_data: res,
                        currModelABList: fetched_ab_data
                    });
                    return;
                }

                if (response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                } else {
                    this.setState({errorOccurred: true});
                }
            });
            break;

        case "integrations_profile":
            // Getting integrations for profile page
            if (team_id !== undefined && team_id !== null) {
                let data = {tenant_id: team_id}
                call_get_api(ui_service_ip, "/integrations", data).then((response) => {
                    if (response === undefined) {
                        this.setState({errorOccurred: true});
                    } else if (response.code === 200) {
                        let integrations = response["integrations"];
                        let integrationsMap = {};
                        for (let integration of integrations) {
                            integrationsMap[integration["integration_id"]] = integration;
                        }

                        this.setState({
                            integrationsMap: integrationsMap,
                        });
                    }
                    else if (response.code === 403) {
                        localStorage.removeItem("sessionToken");
                        window.location = '/';
                    }
                    else {
                        this.setState({errorOccurred: true});
                    }
                });
            }
            break;

        case "model_updates":
            call_get_api(ui_service_ip, "model_refreshes_info", data).then((response) => {
                if (response === undefined) {
                    this.setState({ errorOccurred: true });
                    return;
                }

                if (response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                    return;
                }

                if (response.code === 200) {
                    let res = [];
                    let model_updates = response["model_dataset_processing_info"];
                    res = model_updates_table(model_updates);
                    this.setState({
                        model_updates: res,
                    });

                }
                else {
                    this.setState({ errorOccurred: true });
                }
            });
            break;
        case "alertDatasets":
         call_get_api(ui_service_ip, "/datasets",data).then( (response) =>{
                if (response === undefined) {
                        this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    let details = {...this.state.details};
                    details.data_set = response['datasets'];
                    this.setState({details: details})
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken")
                    window.location = '/';
                } else {
                    this.setState({errorOccurred: true});
                }
            });
            break;
        case "integrations":
            if (team_id !== undefined && team_id !== null) {
                let data = {tenant_id: team_id}
                call_get_api(ui_service_ip, "/integrations", data).then((response) => {
                    if (response === undefined) {
                        this.setState({errorOccurred: true});
                    } else if (response.code === 200) {
                        let res = [];
                        let int_arr = [];
                        let integrations = response["integrations"];
                        res = integration_table_data_setup(integrations);
                        int_arr = integrations.map(function (row) {
                                return {"label": row["integration_name"], "value": row["integration_id"]};
                            });
                        this.setState({
                            integration_details: integrations,
                            data: res,
                            integration_option: int_arr,
                            updateStatus:true
                        });
                    }
                    else if (response.code === 403) {
                        localStorage.removeItem("sessionToken");
                        window.location = '/';
                    }
                    else {
                        this.setState({errorOccurred: true});
                    }
                });
            }
            break;
            case "pipeline_integrations":
                if (team_id !== undefined && team_id !== null) {
                    let data = {tenant_id: team_id}
                    call_get_api(ui_service_ip, "/pipelineintegrations", data).then((response) => {
                        if (response === undefined) {
                            this.setState({errorOccurred: true});
                        } else if (response.code === 200) {
                            let res = [];
                         //   let int_arr = [];
                            let integrations = response["integrations"];
                            res = pipeline_integration_table_data_setup(integrations);
                            // int_arr = integrations.map(function (row) {
                            //         return {"label": row["integration_name"], "value": row["integration_id"]};
                            //     });
                            this.setState({
                              //  integration_details: integrations,
                                pipelinedata: res,
                              //  integration_option: int_arr
                            });
                        }
                        else if (response.code === 403) {
                            localStorage.removeItem("sessionToken");
                            window.location = '/';
                        }
                        else {
                            this.setState({errorOccurred: true});
                        }
                    });
                }
                break;
        case "integrationOptions":
            if (team_id !== undefined && team_id !== null) {
                data = {tenant_id: team_id}
                call_get_api(ui_service_ip, "/integrations", data).then((response) => {
                    if (response === undefined) {
                        this.setState({errorOccurred: true});
                    } else if (response.code === 200) {
                        let int_arr = [];
                        let integrations = response["integrations"];
                        int_arr = integrations.map(function (row) {
                                return {"label": row["integration_name"], "value": row["integration_id"]};
                            });
                        this.setState({
                            integration_option: int_arr
                        });
                    }
                    else if (response.code === 403) {
                        localStorage.removeItem("sessionToken");
                        window.location = '/';
                    }
                    else {
                        this.setState({errorOccurred: true});
                    }
                });
            }
            break;
        case "accountDetails":
            call_get_api(ui_service_ip, "account/features", data).then((res_1) => {
                    if (res_1 !== undefined && res_1.code === 200) {
                        const accPlanDetails = res_1.plan_details;
                        this.setState({
                            mlLimitExceed: accPlanDetails.ml_usage_exceeded,
                            maxModel: accPlanDetails.max_ml_model_limit,
                            planName: accPlanDetails.plan_name,
                            dataLimitExceed: accPlanDetails.usage_exceeded
                        });
                   }  else {
                        this.setState({mlLimitExceed: null, maxModel: null});
                    }
                });
            break;

            case "dd_sleep_time_fetch":
                call_get_api(ui_service_ip, "dd_sleep_time", data).then((response) => {
                    if (response !== undefined){
                        let curr_sleep_time = response['sleep_time'];
                        if (curr_sleep_time === undefined || curr_sleep_time === null) {
                            curr_sleep_time = 1;
                        }
                        if (response.code === 200) {
                            this.setState({
                                sleepComponentLoad: false,
                                sleepInterval: curr_sleep_time,
                                prevSleepInterval: curr_sleep_time
                            });
                        }
                    }
                });
                break;

            case "save_dd_sleep_time":
                    this.setState({sleepIntervalClass: "d-none"});
                    const sleep_time_val= this.state.sleepInterval;
                    let currSession = localStorage.getItem("sessionToken");
                    let sleepIntervalData = {"sleep_interval": sleep_time_val}
                    call_update_api(ui_service_ip, "dd_sleep_time", sleepIntervalData, currSession).then(
                        (response) => {
                            this.setState({sleepComponentLoad: false});
                            if(response !== undefined && response.code === 200) {
                                this.setState({
                                    prevSleepInterval: sleep_time_val,
                                    sleepIntervalClass: "d-none"
                                });
                                this.showToast("alert-success", "Scan interval update is successful", "");
                            }else{
                                this.setState({sleepIntervalClass: ""});
                                this.showToast("alert-danger", "Could Not Update scan interval", "");
                            }
                        }
                    );
                    break;

        case "increaseDataLimit":
            const inputData = {"tenant_id": team_id};
            call_update_api(ui_service_ip, "plan/status", inputData, session).then((response) => {
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                    console.error("Unable to reach console server");
                } else if (response.code === 200) {
                    this.setState({dataLimitExceed: false});
                } else {
                    console.error("Unable to update data limit")
                }
            });
            break

        case "mlmodels":
            call_get_api(ui_service_ip, "/ml-models", data).then(
                (response) => {
                    if (response === undefined) {
                        this.setState({errorOccurred: true});
                    } else if (response.code === 200) {
                        let res = mlTableDataSetup(response.ml_model);
                        this.setState({ mlDetails: response.ml_model, mlData: res,
                                        change_request_exists: response.change_request_exists});
                    } else if (response.code === 403) {
                        localStorage.removeItem("sessionToken");
                        window.location = '/';
                    }
                });
            break;
        case "models":
            call_get_api(ui_service_ip, "/ml-models", data ).then(
                (response) => {
                    if (response === undefined) {
                        this.setState({errorOccurred: true});
                    } else if (response.code === 200) {
                        let options = response["ml_model"];
                        let modelOptions = options.map(function (row) {
                                let datasets = getModelDatasets(row);
                                return {"label": row["model_name"], "value": row["ml_model_id"], "datasets": datasets};
                            });
                        this.setState({
                            modelDetails: response,
                            modelOptions: modelOptions
                        });

                    } else if (response.code === 403) {
                        localStorage.removeItem("sessionToken");
                        window.location = '/';
                    }
                });
            break;
        case "metrics":
            call_get_api(ui_service_ip, "metrics_meta/metrics",data).then( (response) =>{
                 if (response === undefined) {
                        this.setState({errorOccurred: true});
                 }else if(response.code === 200) {
                    let res=DQM_metric_setup(this.state.details.page, response.metrics_list);
                    let details = {...this.state.details};
                    details.data = res;
                    this.setState({details});
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                } else {
                     this.setState({errorOccurred: true});
                }
            });
            break;
        case "attributePage":
            let dataSetId = this.state.dataSetId === -1 ? this.props.dataModule.selectedDataSetId : this.state.dataSetId;
            if(dataSetId === undefined || dataSetId === -1){
                // Invalid data set id. So no need to call api further
                return;
            }

            data["data_set_id"] = dataSetId;

            let attributeID = this.state.attributeID === "" ? this.props.dataModule.selectedAttributeID : this.state.attributeID;
            let attributeName = this.state.attributeName === "" ? this.props.dataModule.selectedAttributeName : this.state.attributeName;
            if(attributeID !== undefined && attributeID !== "") {
                // When we have valid attribute id, we will try to fetch using attribute id
                data["attribute_id"] = attributeID;
            }else if(attributeName !== undefined && attributeName !== "") {
                // When we have valid attribute name, we will try to fetch using attribute name
                data["attribute_name"] = attributeName;
            } else {
                return;
            }

            call_get_api(ui_service_ip, "attributes/dataprofile",data).then((response) => {
                if (response === undefined) {
                        this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    let apiStatusCode = response.code;
                    this.setApiResponse(response, apiStatusCode, false);
                } else {
                    this.setState({errorOccurred: true});
                }
            });

            break;
        case "get_user_payments_and_billing_history":
            call_get_api(ui_service_ip, "customer/payment",data).then(
                (response) => {
                    if (response === undefined) {
                        this.setState({errorOccurred: true});
                    } else if (response.code === 200) {
                        let data = response.data;
                        let paymentDetails = data.payment_details;
                        let billingHistory = data.billing_history;
                        let plan_details = data.plan_details;
                        let billingAddress = data.billing_address
                        if (plan_details === undefined) {
                            plan_details = {};
                        }

                        let auto_debit_status = plan_details.auto_debit_status === 1;

                        let allDetails = this.getPaymentDetailsTableData(paymentDetails);
                        billingAddress = this.getBillingAddressDetails(billingAddress);
                        let billingHistoryTableData = this.getBillingHistoryTableData(billingHistory);
                        this.setState({
                            availableCards: allDetails,
                            billingHistory: billingHistoryTableData,
                            autoDebit: auto_debit_status,
                            disableAutoDebitToggle: false,
                            billinginitalloader:false,
                            billing_address : billingAddress,

                        });

                    } else {
                        this.setState({errorOccurred: true});
                    }

                    this.setState({cardGroupLoading: false});
                });
            break;
        case "fetchQualdoPlans":
            call_get_api(ui_service_ip, "get_all_plan",data).then( (response) =>{
                    if (response === undefined) {
                        this.setState({errorOccurred: true});
                    }else if(response.code === 200) {
                    let plans = response.qualdo_plans;
                    this.setQualdoPlans(plans);
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                } else {
                        this.setState({errorOccurred: true});
                }
            });

            break;
        case "get_payment_page_details":
            let getPaymentDetailsEndPoint = "customer/payment_page";
            let planData = {"tenant_id":team_id}
            let srcIsChoosePlan = this.state.srcIsChoosePlan;
            let planType = localStorage.getItem("qualdoPlan");
            // Check whether there is change in plan. If there is a change update the end point
            if (srcIsChoosePlan === true &&
                planType !== undefined &&
                VALID_QUALDO_PLANS.includes(planType)) {
                let qualdoPlanCycle = this.getQualdoPlanCycle();
                let planChanged = isPlanChanged(planType, qualdoPlanCycle);
                if (planChanged) {
                    if (planType === QualdoPlanVersions.FREE) {
                        qualdoPlanCycle = "monthly";
                    }
                    planData["user_plan"] = planType;
                    planData["plan_cycle"] = qualdoPlanCycle;
                } else{
                    this.showErrorMessage("You are already in the "+ qualdoPlanCycle +" plan");
                    return;
                }
            }

            call_get_api(ui_service_ip, getPaymentDetailsEndPoint,planData).then(
                (response) => {
                if (response === undefined) {
                        this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    let data = response.data;
                    if (data["error_message"] !== undefined && data["error_message"] !== null){
                        this.showErrorMessage(data["error_message"])
                    } else if(data["is_downgrade"] !== undefined && data["is_downgrade"] === true){
                        // show popup
                        this.showDowngradePopup(data)
                    }
                    else {
                        let available_cards = this.getPaymentDetailsTableData(data.available_cards);
                        data["available_cards"] = available_cards;
                        data["show_payment_details"] = true;
                        this.changePaymentPageDetails(data)
                    }
                } else {
                    this.setState({errorOccurred: true});
                }
            });
        break;
        case "customMetricProcessInfo":
            const customMetricEndPoint = "custom_metrics_process_info";
            call_get_api(ui_service_ip, customMetricEndPoint, data ).then((response) => {
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    let data = response.data;
                    if (data === undefined || data === null){
                        return;
                    }

                    let dqmData = data.dqm;
                    let mlmData = data.mlm;
                    this.setState({
                        customMetricDqmData: customMetricsProcessUpdates(dqmData),
                        customMetricMlmData: customMetricsProcessUpdates(mlmData)
                    });
                }
            });
            break;
            case "dq_reports":
                let DQData = {
                    "tenant_id": team_id,
                    "month": this.state.startDate,
                    "filter_type": this.state.filter_selected
                }
                call_post_api(ui_service_ip, 'dq_reports/chart_data', DQData ).then((response) => {
                    if (response === undefined) {
                        this.setState({errorOccurred: true});
                    } else if (response.code === 200) {
                        let data = response.data;
                        if (data === undefined || data === null){
                            return;
                        }

                        // monthviewyear: startDate.format("YYYY"),
                        // monthviewmonth: startDate.format("MMM"),
                        // filtermonthviewyear: startDate.format("YYYY"),
                        // filtermonthviewmonth: startDate.format("MMM")

                       if(data.length > 0){
                            this.setState({
                                dq_reports_data:data[0].payload,
                                apicall:"",
                                err:"",
                                filtermonthviewyear:this.state.monthviewyear,
                                filtermonthviewmonth:this.state.filtermonthviewmonth,
                            });
                        }else{
                            this.setState({
                                dq_reports_data:"",
                                err:"No Data Found",
                                apicall:"",
                                filtermonthviewyear:this.state.monthviewyear,
                                filtermonthviewmonth:this.state.filtermonthviewmonth,
                            });
                        }
                    }
                });
            break;
            case "fetchDatasetRefreshInfo":
                call_get_api(ui_service_ip, "datasets/last_processed_time",data).then( (response) =>{
                        if (response === undefined) {
                            this.setState({errorOccurred: true});
                            return;
                        }

                        if(response.code === 403) {
                            localStorage.removeItem("sessionToken");
                            window.location = '/';
                            return;
                        }

                        if(response.code === 200) {
                            let datasetRefInfo = response.results;
                            this.props.fetchLastRefreshOfDatasets(datasetRefInfo);
                            return;
                        }
                });

            break;
            case "fetchDatasourceChartData":
                call_post_api(ui_service_ip, "discover",data).then( (response) =>{
                    if (response === undefined) {
                        this.setState({errorOccurred: true});
                        return;
                    }

                    if(response.code === 403) {
                        localStorage.removeItem("sessionToken");
                        window.location = '/';
                        return;
                    }

                    if(response.code === 200) {
                        let dataSrcInfo = response.data;
                        this.props.storeDiscoverPageDataForDataSrc(dataSrcInfo);
                        return;
                    }
                });
            break;
            case "metaDataList":
                // call_post_api(ui_service_ip, "/scheduled_attr_info",data).then( (response) =>{
                //     if (response === undefined) {
                //         this.setState({errorOccurred: true});
                //         return;
                //     }

                //     if(response.code === 403) {
                //         localStorage.removeItem("sessionToken");
                //         window.location = '/';
                //         return;
                //     }

                //     if(response.code === 200) {
                //         let dataSrcInfo = response.data;
                //         this.props.storeDiscoverPageDataForDataSrc(dataSrcInfo);
                //         return;
                //     }
                // });
            break;
        case "filterData":
            data = { "tenant_id": localStorage.getItem('team_id') }
            call_get_api(ui_service_ip, "metadata_mapping", data).then(
                (result) => {
                    if (result.data !== undefined && result.data !== null) {
                        let responseData = result['data']

                        let metaDataMapping = {}
                        for (let i in responseData) {
                            responseData[i].map((data) => {
                                metaDataMapping[data["value"]] = data["label"]
                                return null
                            })
                        }
                        this.setState({
                            filterData: result['data'],
                            metaDataMapping: metaDataMapping,
                            disable : false
                        })
                    }
                });
            break;
        default:
            break;

        }
}
export function getBrainTreeAuthToken() {
    return BRAIN_TREE_AUTH_TOKEN;

    // We can get the token API when it is supported

    // call_get_api(ui_service_ip, "customer/payment_page?tenant_id="+ team_id).then(
    //     (response) => {
    //     if (response === undefined) {
    //             this.setState({errorOccurred: true});
    //     } else if (response.code === 200) {
    //         this.setState({errorOccurred: true});
    //     } else {
    //         this.setState({errorOccurred: true});
    //     }
    // });
}

export function getMetadatas(){
    let data = { "tenant_id": localStorage.getItem('team_id') }
    call_get_api(ui_service_ip, "metadata/mapping/latest",data).then(
        (result) => {
            if (result.meta_data !== undefined && result.meta_data !== null) {
                let responseData = result['meta_data']
                console.log("responseData",responseData)
                this.setState({
                    metaData: responseData,
                })
                this.getData();
            }
        });
}

export function setTimeFilter(Id, key, modelTitle, chartTitle, startDate, endDate) {
    const teamId = localStorage.getItem('team_id');
    let session = localStorage.getItem("sessionToken");
    let appliedFilters = {'startDate': startDate, "endDate": endDate};
    let showMoreEndPoint;
    let distance = null;
    let chartInputData;
    if (this.state.distance !== null) {
        distance = this.state.distance;
    }

    if (key === "mlModelPerformance") {
        chartInputData = {
            "ml_model_ids": [Id],
            "cross_chart_data": chartDataCombinations
        };
        showMoreEndPoint = `model_performance/results?tenant_id=${teamId}`;
    }
    else {
        chartInputData = {
            "tenant_id": teamId,
            "data_set_ids": [Id],
            "distance": distance,
            "level":"all",
            "result_type": "chart",
            "payload_start_time": startDate,
            "payload_end_time": endDate
        };

        if (key === "mlModel") {
            chartInputData = {
                "tenant_id": teamId,
                "level": "all",
                "ml_model_id": Id,
                "metrics_id": null,
                "result_type": "chart",
                "payload_start_time": startDate,
                "payload_end_time": endDate
            };
        }

        showMoreEndPoint = "monitor/charts";
    }

    call_post_api(ui_service_ip, showMoreEndPoint, chartInputData,
        session).then((res) => {
        this.setSelectedFilterChartData(res, key, modelTitle, chartTitle, Id, appliedFilters);
    });
}

//export function setModelPerformanceTimeFilter(Id, performanceType, startDate, endDate) {
//    const teamId = localStorage.getItem('team_id');
//    let session = localStorage.getItem("sessionToken");
//    let showMoreEndPoint;
//    let chartInputData;
//    if (Id === undefined || Id === null){
//        this.setState({loadValue: false});
//        return;
//    }
//
//    chartInputData = {
//        "ml_model_ids": [parseInt(Id)],
//        "performance_types": [performanceType],
//        "cross_chart_data": []
//    };
//    showMoreEndPoint = `model_performance/results?tenant_id=${teamId}`;
//
//    call_post_api(ui_service_ip, showMoreEndPoint, chartInputData,
//        session).then((res) => {
//        this.setModelPerformanceTimeFilterChartData(res, this.props.variant, this.state.chartID);
//    });
//}

export function profileTimeFilterSubmit(data, startDate, endDate)
{
    let session = localStorage.getItem("sessionToken");
    let team_id = localStorage.getItem('team_id');
    let fetchLastProfilingTime = getLastDQProfilingTime.bind(this);
    fetchLastProfilingTime(data["value"]);
    let d = {"data_source": [data["value"]], "payload_start_time":startDate, "payload_end_time": endDate};
    d["tenant_id"] = team_id;
    call_post_api(ui_service_ip, "relationship/all", d , session).then((response) => {
            if (response === undefined) {
                this.setState({errorOccurred: true});
            } else if (response.code === 200) {
            let attributeLink = "dataset/attributes/latest?tenant_id="+localStorage.getItem('team_id')+"&integration_id="+data["value"]+
                "&payload_start_time="+startDate+"&payload_end_time="+endDate;
            let chartInputData = { "tenant_id": localStorage.getItem('team_id'),
                                               "env_id": null,
                                               "integration_id": data["value"],
                                               "data_set_ids": null,
                                               "mas_id": null,
                                               "metrics_id": null,
                                   }
            if (startDate !=null && endDate !=null) {
                chartInputData["payload_start_time"] = startDate;
                chartInputData["payload_end_time"] = endDate;
            }
            call_get_api(ui_service_ip, attributeLink ).then((res_2) =>{
                if (res_2 !== undefined && res_2["code"] === 200) {
                    call_post_api(ui_service_ip, "metrics/charts", chartInputData, session).then((res_3) =>{
                        this.setDataInTab(response["relationships"], res_2["result"], res_3, data, startDate, endDate, true);
                        this.showToast("alert-success","Filter added successfully",'');
                        this.setState({loadValue:false});
                    });
                }
            });

        }else if(response.code === 401)
        {
            this.showToast("alert-danger","could not add Filter",'')
        }else if(response.code === 403) {
            localStorage.removeItem("sessionToken");
        }
    });
}

export function setDistanceFilter(event, Id, key, modelTitle, chartTitle) {
    // Commented the API calls, since we are not supporting distance based filters.
    // and hence to prevent unnecessary API call

    // const teamId = localStorage.getItem('team_id');
    // let session = localStorage.getItem("sessionToken");
    // let appliedFilters = {'distance': event};
    // let startDate = null;
    // let endDate = null;
    // if (this.state.startDate !== null && this.state.endDate !== null) {
    //     startDate = this.state.startDate;
    //     endDate = this.state.endDate;
    // }
    //
    // let chartInputData;
    // let showMoreEndPoint;
    // if (key === "mlModelPerformance") {
    //     chartInputData = {
    //         "ml_model_ids": [Id],
    //         "payload_start_time": startDate,
    //         "payload_end_time": endDate,
    //         "cross_chart_data": chartDataCombinations
    //     };
    //     showMoreEndPoint = `model_performance/results?tenant_id=${teamId}`;
    // } else {
    //     chartInputData = {  "tenant_id": teamId,
    //         "data_set_ids": [Id],
    //         "result_type": "chart",
    //         "distance": event,
    //         "payload_start_time":startDate,
    //         "payload_end_time": endDate
    //     };
    //     if (key === "mlModel") {
    //         chartInputData = {  "tenant_id": teamId,
    //             "level": "all",
    //             "ml_model_id": Id,
    //             "metrics_id": null,
    //             "result_type": "chart",
    //             "payload_start_time":startDate,
    //             "payload_end_time": endDate
    //         };
    //     }
    //
    //     showMoreEndPoint = "monitor/charts";
    // }


    // call_post_api(ui_service_ip,  showMoreEndPoint, chartInputData, session).then((res) => {
    //     if (res === undefined) {
    //         this.setState({errorOccurred: true});
    //     }
    //     else {
    //         this.setSelectedFilterChartData(res, key, modelTitle, chartTitle, Id, appliedFilters);
    //     }
    // });
}

export function setDataSource(dataSource, key, title, startDate, endDate) {
    const teamId = localStorage.getItem('team_id');
    let session = localStorage.getItem("sessionToken");
    let dataSourceID = dataSource["value"];
    let chartInputData = {
        "tenant_id": teamId,
        "env_id": null,
        "integration_ids": [dataSourceID],
        "data_set_ids": null,
        "level": "all",
        "mas_id": null,
        "metrics_id": null,
        "result_type": "chart"
    }

    if (startDate !=null && endDate !=null) {
               chartInputData["payload_start_time"] = startDate;
               chartInputData["payload_end_time"] = endDate;
    } else {
        let startDate = moment().subtract(29, 'days').startOf('day').format('YYYY-MM-DD 00:00:00').toString();
        let endDate =  moment().utc().endOf('day').format('YYYY-MM-DD 23:59:59').toString();
        chartInputData["payload_start_time"] = startDate;
        chartInputData["payload_end_time"] = endDate;
    }

    // API call fetch the latest monitoring time for the selected integration
    let payData ={"tenant_id":teamId};
    payData["integration_id"] = dataSourceID
    call_get_api(ui_service_ip, "metrics_store_payload_created_time",payData).then((response) => {
        if (response === undefined) {
            this.setState({errorOccurred: true});
        } else if (response !== null && response.code === 200) {
            let results = response["results"]
            if (results !== null && results.length > 0){
                this.setState({lastMonitorTime: results[0]["last_processed_time"], isSuccessMessage: true})
            } else{
                this.setState({isSuccessMessage: false});
            }
        }
        else {
            this.setState({errorOccurred: true});
        }
    });

    call_post_api(ui_service_ip, "monitor/charts", chartInputData, session).then((res) => {
        if (res === undefined) {
            this.setState({errorOccurred: true});
        } else {
            this.setSelectedChartData(res, key, title, dataSource);
        }
    });

    let datasets = getCachedDatasets(teamId, dataSourceID)
    if (datasets !== null) {
        this.setAvailableDataSets(datasets, key, title, dataSourceID)
        return;
    }

    // const endPoint = `datasets?tenant_id=${teamId}&integration_id=${dataSourceID}`;
    let data = {'tenant_id':teamId, 'integration_id':dataSourceID}
    call_get_api(ui_service_ip,"/datasets", data, session).then((res) => {
        let datasets = res["datasets"];
        this.setAvailableDataSets(datasets, key, title, dataSourceID);
        cacheDatasets(datasets, teamId, dataSourceID);
    });
}

export function setDataset(dataset, key, title) {
    const team_id = localStorage.getItem('team_id');
    let session = localStorage.getItem("sessionToken");
    let startDate = moment().subtract(29, 'days').startOf('day').format('YYYY-MM-DD 00:00:00').toString();
    let endDate =  moment().utc().endOf('day').format('YYYY-MM-DD 23:59:59').toString();
    let chart_input_data = { "tenant_id": team_id,
        "env_id": null,
        "integration_ids": null,
        "data_set_ids": [dataset["value"]],
        "mas_id": null,
        "metrics_id": null,
        "result_type": "chart",
        "payload_start_time": startDate,
        "payload_end_time": endDate,
        "level": "all"
    }
    call_post_api(ui_service_ip, "monitor/charts", chart_input_data, session).then((res) =>{
        this.setSelectedChartData(res, key, title, dataset)
    });
}

export function compareMlCharts(mlModel,mlModelId, key, modelTitle, chartTitle){
    let startDate = null;
    let endDate = null;
    const teamId = localStorage.getItem('team_id');
    let session = localStorage.getItem("sessionToken");
    if (this.state.startDate !== null && this.state.endDate !== null) {
        startDate = this.state.startDate;
        endDate = this.state.endDate;
    } else {
        startDate = moment().subtract(29, 'days').startOf('day').format('YYYY-MM-DD 00:00:00').toString();
        endDate =  moment().utc().endOf('day').format('YYYY-MM-DD 23:59:59').toString();
    }

    let appliedFilters = {'compareMlModel': mlModel};

    let chartInputData = {  "tenant_id": teamId,
//                            "level": "all",
                            "ml_model_id": mlModel["value"],
                            "metrics_id": null,
                            "result_type": "chart",
                            "payload_start_time":startDate,
                            "payload_end_time": endDate
                         };
    call_post_api(ui_service_ip, "monitor/charts", chartInputData, session).then((res) =>{
        if (res === undefined) {
             this.setState({errorOccurred: true});
        } else {
            this.setCompareChart(res, key, modelTitle, chartTitle, mlModelId, appliedFilters);
        }
    });
}

export function compareDqCharts(dataSet, dataSetId, key, modelTitle, chartTitle){
    let startDate = null;
    let endDate = null;
    const teamId = localStorage.getItem('team_id');
    let session = localStorage.getItem("sessionToken");
    if (this.state.startDate !== null && this.state.endDate !== null) {
        startDate = this.state.startDate;
        endDate = this.state.endDate;
    } else {
        startDate = moment().subtract(29, 'days').startOf('day').format('YYYY-MM-DD 00:00:00').toString();
        endDate =  moment().utc().endOf('day').format('YYYY-MM-DD 23:59:59').toString();
    }

    let appliedFilters = {'compareDataSet': dataSet};

    let chartInputData = {"tenant_id": teamId,
                            "data_set_ids": [dataSet["value"]],
                            "result_type": "chart",
//                            "level":"all",
                            "payload_start_time":startDate,
                            "payload_end_time": endDate
                            };
    call_post_api(ui_service_ip, "monitor/charts", chartInputData, session).then((res) =>{
        if (res === undefined) {
             this.setState({errorOccurred: true});
        } else {
            this.setCompareChart(res, key, modelTitle, chartTitle, dataSetId, appliedFilters);
        }
    });
}



export function formSubmit(event, props) {
    event.preventDefault();

    const { name } = event.target;
    let session = localStorage.getItem("sessionToken");
    switch(name) {
        case "defaultMetrics":
               var date = new Date();
               var currentTime =  date.getUTCFullYear() + ":" + ("0" + (date.getUTCMonth()+1)).slice(-2) + ":" + ("0" + date.getUTCDate()).slice(-2) + " " +
                                    ("0" + date.getUTCHours()).slice(-2) + ":" + ("0" + date.getUTCMinutes()).slice(-2) + ":" + ("0" + date.getUTCSeconds()).slice(-2);
               const metricData = new FormData(event.target);
               const metricsUpdated = {
                     "metric_name": metricData.get("metricName"),
                     "metrics_definition": {
                            "name": metricData.get("metricName"),
                            "version": "1",
                             "dimension": "dataset",
                             "compute_drift": true,
                             "drift_threshold": 10,
                             "checks": [{"name": this.state.selectedCheck.value, "value": metricData.get("checkValue")}],
                             "sources": [],
                             "timestamp": currentTime,
                             "formula": this.props.exMetricDetails.metrics_definition.formula,
                             "mas_type": this.props.exMetricDetails.metrics_definition.mas_type,
                             "measures": this.props.exMetricDetails.metrics_definition.measures
                     }
              };
		      const team_id = localStorage.getItem('team_id');
              metricsUpdated["tenant_id"] = team_id;
              metricsUpdated["metrics_id"] = this.props.metricId;
              let end_point = "metrics_meta/metrics";
              call_update_api(ui_service_ip, end_point, metricsUpdated, session).then(
                    (response) => {
                        if (response === undefined) {
                            this.setState({errorOccurred: true});
                        } else if (response.code === 200) {
                           this.props.closeModal();
                           this.props.showToast("alert-success", 'metrics updated successfully', "")
                        } else {
                            this.props.closeModal();
                            this.props.showToast("alert-danger", response.message, "")
                        }
                    }
              );
              break;
        case "customDefaultMetrics":
            let dataSetId = this.state.sourceDataset;
            let targetDataSetId = this.state.targetDataSetId;
            let requiresTarget = this.requiresTargetSelection();

            if (dataSetId === null || (requiresTarget && targetDataSetId === null)) {
                const err = "Select a dataset to associate with metric";
                this.props.closeModal();
                this.props.showToast("alert-danger", err, "");
                return;
            }

            if (String(dataSetId) === String(targetDataSetId)) {
                const err = "Target dataset cannot be same as current dataset";
                this.props.closeModal();
                this.props.showToast("alert-danger", err, "");
                return;
            }

            const exMetricDetails = this.props.exMetricDetails;
            const metricsId = exMetricDetails.metrics_id;
            let teamId = localStorage.getItem('team_id');

            let data = {
                "metrics_id": metricsId,
                "data_set_id": dataSetId,
                "target_data_set_id": targetDataSetId,
                "tenant_id": teamId
            }

            const isMetricsEdit = this.props.src_page === "edit_metrics";
            let editProcess = isMetricsEdit ? 'updated' : 'enabled';

            let responseHandler = (response) => {
                if (response === undefined) {
                    this.props.showToast("alert-danger", "Unable to process request", "");
                }
                else if (response.code === 200) {
                    const message = `Metric ${metricsId} is ${editProcess} for dataset ${dataSetId} successfully`;
                    this.props.showToast("alert-success", message, "");
                }
                else {
                    this.props.showToast("alert-danger", response.message, "");
                }
            };

            this.props.closeModal();
            const metricEditEndPoint = "metrics/custom";
            if (isMetricsEdit) {
                data["job_id"] = exMetricDetails.job_id;
                call_update_api(ui_service_ip, metricEditEndPoint, data, session).then(
                    responseHandler
                );
            }
            else {
                call_post_api(ui_service_ip, metricEditEndPoint, data, session).then(
                    responseHandler
                );
            }

            break;
        case "email_configure":
            const emailFormData = new FormData(event.target);
            const emailConfigureData = {
                "tenant_id": localStorage.getItem('team_id'), "consume_group_name": emailFormData.get('consume_group_name'),
                "alert_id": emailFormData.get("alertId"),
                "consume_group_type": "email", "config_details":
                {
                    "email_id": emailFormData.get("email"),
                    "repeat_count": emailFormData.get("repeat_count"),
                    "repeat_interval": emailFormData.get("repeat_interval"),
                    "repeat_on": emailFormData.getAll("repeat_on"),
                    "repeat_at": emailFormData.get("repeat_at"),
                    "type": emailFormData.get("cg_type"),
                    "time_zone": emailFormData.get("time_zone")
                }
            };
            call_post_api(ui_service_ip, "consume_group", emailConfigureData, session).then(
                (response) => {
                    if (response === undefined) {
                        this.setState({errorOccurred: true});
                    } else if (response.code === 200) {
                        this.props.showToast("alert-success", "Notification added successfully", "");
                    } else {
                        this.props.showToast("alert-danger", response.message, "");
                    }
                }
            );
            break;
        case "pipeline_trigger":
            const pipeline_data = this.create_data_to_post(event)
            const is_pipeline_edit = this.props.src_page === "edit_pipeline";
            let pipeline_success_process = is_pipeline_edit ? 'updated' : 'added'
            let pipeline_error_process = is_pipeline_edit ? 'update' : 'add'

            let pipeline_resp_handler = (response) => {
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    if (is_pipeline_edit) {
                        this.props.closeModal()
                    }
                    this.props.showToast("alert-success",
                        `${pipeline_data["pipeline_trigger_type"]} ${pipeline_success_process} successfully`,
                        "")
                } else {
                    this.props.showToast("alert-danger",
                        `could not ${pipeline_error_process} ${pipeline_data["pipeline_trigger_type"]}`,
                        "")
                }
            }

            if (is_pipeline_edit) {
                if (!this.requires_pipeline_update(pipeline_data)) {
                    this.props.showToast("alert-info", 'No changes made', "");
                    this.props.closeModal();
                    return;
                }

                // Update pipeline trigger information
                const pipeline_trigger_id = this.props.ex_details.pipeline_trigger_id
                const endPoint = "pipeline_trigger";
                pipeline_data["pipeline_trigger_id"] = pipeline_trigger_id
                call_update_api(ui_service_ip, endPoint,
                    pipeline_data, session).then(pipeline_resp_handler);
            }
            else {
                call_post_api(ui_service_ip, "pipeline_trigger",
                    pipeline_data, session).then(pipeline_resp_handler);
            }

            break;
        default:
            break;
    }
}

export function submitUsersPayment(paymentData, setPaymentStatus) {
    const teamId = localStorage.getItem('team_id');
    const session = localStorage.getItem("sessionToken");
    let data = {"tenant_id" : teamId}
    let endPoint = "customer/payment_page";
    paymentData["tenant_id"] = teamId;
    call_post_api(ui_service_ip, endPoint, paymentData, session).then((response) => {
        if (response === undefined) {
            this.setState({errorOccurred: true,
                showModal:true,
                disableSubmitBtn: false,
                failure_message : "Unable to contact server. Try after sometime"});
        } else if (response.code === 200) {

            // Update encrypted User Details
            let decrypt_data = decryptForLocalUsage(localStorage.getItem(LOCAL_STORE_KEY))
            decrypt_data["isPlanExpired"] = false;
            let encrypt_data = encryptForLocalUsage(decrypt_data)
            localStorage.setItem(LOCAL_STORE_KEY, encrypt_data)
            call_get_api(ui_service_ip, "account/features",data).then((res_1) => {
                    if (res_1.code === 200) {
                        const accountFeatures = res_1["plan_details"];
                        localStorage.setItem("plan_details", JSON.stringify(accountFeatures));
                   }
            });
            this.setState({errorOccurred: false});
            setPaymentStatus(true);
        } else {
            this.setState({
                showModal:true,
                disableSubmitBtn: false,
                failure_message : response.message
            })
        }
    });
}


export function handleClick(event){
    let session = localStorage.getItem("sessionToken");
    let name=event.target.name;
    switch(name) {
        case "logout":
            let team_id = localStorage.getItem('team_id');
            let user_id = localStorage.getItem('user_id');
            const logout_data = {token:session, team_id: team_id, user_id: user_id};
            call_post_api(iam_ip, "logout", logout_data).then((response) =>{
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    if(checkExpiryPowerBI()===false){
                        removeLocalStorageKeys();
                        window.location = '/';
                    }else {
                        let powerbiip = process.env.REACT_APP_POWERBI_BASE_URL;
                        let protocol = getProtocol(powerbiip);
                        window.location = protocol+'//'+powerbiip;
                    }
                }
            });
            postMixpanel('User logged out');
            break;
        default:
            break
    }
}

export function test() {


}

export function handleFileSubmit(event)
{
    event.preventDefault();
    let errors = this.state.errors;
    const { name } = event.target;
    if (typeof(errors) !== 'undefined' || errors != null) {
        this.setState({formValid: validateForm(this.state.errors)});
        this.setState({errorCount: countErrors(this.state.errors)});
    }
    let session = localStorage.getItem("sessionToken");
    switch(name) {
     case "upload_integration":
            if(this.state.file.length === 0)
            {
                this.setState({uploadClick:true});
            }else{
          this.setState({uploadClick:false});
          this.disableButton();
          const integration_data = new FormData();
           integration_data.append("integrations", this.state.file[0]);
            call_file_post_api(ui_service_ip, "integrations/file", integration_data,session).then((response) =>{
                if(response.code === 200) {
                 this.setState({show: false,hideSubmitButton: false,file: []})
                 let successList = [];
                 let dangerList = [];
                 for(let i=0;i<response.response.length;i++){
                     if(response.response[i]["code"] === 200) {
                        successList.push(response.response[i]["code"])
                     }else{
                        dangerList.push(response.response[i]["code"])
                     }
                 }
                 let classTitle = "alert-warning"
                 if(successList.length === 0) {
                    classTitle = "alert-danger"
                 }else if(dangerList.length === 0){
                    classTitle = "alert-success"
                 }else{
                   classTitle = "alert-warning"
                 }
                 this.props.showToast(classTitle,response.response,"","bulk");
                }else
                {
                 this.setState({show: false,hideSubmitButton: false,file: []})
                 this.props.showToast("alert-danger",response.message,"")
                }
                });
                }
            break;

          case "upload_ml_model":
                if(this.state.file.length === 0) {
                    this.setState({uploadClick:true});
                } else {
                    this.setState({uploadClick:false});
                    this.disableButton();
                    const integration_data = new FormData();
                    let end_point = "ml-models/file"
                    integration_data.append("ml_model", this.state.file[0]);
                    integration_data.append("max_model", Number(this.props.maxModel));
                    call_file_post_api(ui_service_ip, end_point, integration_data, session).then((response) =>{
                        if (response.code === 200) {
                            this.setState({show: false,hideSubmitButton: false,file: []});
                            let successList = [];
                            let dangerList = [];
                            for(let i=0;i<response.response.length;i++){
                                 if(response.response[i]["code"] === 200) {
                                    successList.push(response.response[i]["code"])
                                 }else{
                                    dangerList.push(response.response[i]["code"])
                                 }
                            }
                            let classTitle = "alert-warning"
                            if(successList.length === 0) {
                                classTitle = "alert-danger"
                            }else if(dangerList.length === 0){
                                classTitle = "alert-success"
                            }else{
                               classTitle = "alert-warning"
                            }
                            this.props.showToast(classTitle,response.response,"","bulk");
                        } else {
                            this.setState({show: false,hideSubmitButton: false,file: []});
                            this.props.showToast("alert-danger",response.message,"");
                        }
                    });
                }
                break;
          default:
            break;
    }
}

export function handleDelete(props, name, deleteClose) {
    this.setState({disableDelete: true});
    let session = localStorage.getItem("sessionToken");
    let deleteIp = null;
    let endPoint = null;
    let data = {};
    let context = '';
    const team_id = localStorage.getItem('team_id');
    let preFetchType = null;
    let details = null;
    switch (name) {
        case "delete_integration":
            let id = props.view_details['integration_id'];
            endPoint = '/integrations';
            data = {integration_id: id, tenant_id:team_id}
            deleteIp = ui_service_ip;
            context = "Datasource";
            break;
        case "delete_pipelinesourceintegration":
              let pipelinesourceid = props.view_details['pipeline_integration_id'];
              endPoint = '/pipelineintegrations';
              data = {pipeline_integration_id: pipelinesourceid, tenant_id:team_id}
              deleteIp = ui_service_ip;
              context = "Pipeline Source";
              break;
        case "delete_metric":
            const metricData = props.details.metrics_data;
            let metricsId = metricData.metrics_id;
            endPoint = "metrics_meta/metrics";
            data ={tenant_id: team_id, metrics_id: metricsId}
            deleteIp = ui_service_ip
            context = "Metric";
            break;
        case "delete_consume":
            const consume_group_id = props.view_details.consume_group_id;
            endPoint = "consume_group";
            data = {"consume_group_id":consume_group_id, "tenant_id":team_id}
            deleteIp = ui_service_ip;
            context = "Notification";
            break;
        case 'delete_pipeline':
            const consume_pipeline_id = props.view_details.pipeline_trigger_id;
            endPoint = "pipeline_trigger";
            data ={"pipeline_trigger_id":consume_pipeline_id}
            deleteIp = ui_service_ip;
            context = "Pipeline Trigger";
            break;
        case 'delete_ml_model':
            let mlModelId = props.view_details['ml_model_id'];
            endPoint = '/ml-models';
            data ={model_id:mlModelId}
            deleteIp = ui_service_ip;
            context = "Model";
            preFetchType ="Model"
            details = {"ml_model_id": mlModelId, "action_type": "delete"}
            break;
        case 'delete_alert':
            let alertId = props.view_details['alert_id'];
            endPoint = 'alert';
            data = {"alert_id":alertId, "tenant_id":team_id}
            deleteIp = ui_service_ip;
            context = "Alerts";
            break;
        default:
            break;
    }

    if (deleteIp === null || endPoint === null){
        return;
    }

    call_delete_api(deleteIp, endPoint, data, session).then((response) => {
        deleteClose();
        this.setState({disableDelete: false});
        if (response.code === 200) {
            props.handleDelete();
            props.showToast("alert-success", `${context} deleted successfully`, '');
            if  (preFetchType !== null)
            {
               UpdateReduxStore(preFetchType, props, details);
            }
        }
        else {
            props.showToast("alert-danger", `Could not delete ${context.toLowerCase()}`, '')
        }
    });
}

export function handleDeleteForModelABConfig(props) {
    this.setState({deleteButtonDisabled: true});
    let session = localStorage.getItem("sessionToken");
    let deleteIp = ui_service_ip;
    let model_ab_id = props.view_details['model_ab_id'];

    let endPoint = 'model_ab';
    let context = 'Model AB configuration';

    call_delete_api(deleteIp, endPoint,{"model_ab_id": model_ab_id} ,session).then((response) => {
        this.setState({deleteButtonDisabled: true});
        if (response.code === 200) {
            const model_ab_id = response["model_ab_id"];
            props.handleABDeleteInParent(model_ab_id);
            props.showToast("alert-success", `${context} deleted successfully`,
                '', '', false);
        } else {
            props.showToast("alert-danger", `Could not delete ${context.toLowerCase()}`,
                '', '', false);
        }
    });
}

function UpdateReduxStore(preFetchType, prefetch, details) {
    if (preFetchType === "Model") {
        let mlModelMapping =  prefetch.monitorModule.mlModelMapping;
        if (details["action_type"] === "add")
        {
            mlModelMapping[details["ml_model_id"]] = details["ml_model_name"]
            if (Object.keys(mlModelMapping).length === 1 ) {
                prefetch.setMLModel(details["ml_model_id"]);
            }
        } else if(details["action_type"] === "delete") {
            if(details["ml_model_id"] in mlModelMapping) {
                delete mlModelMapping[details["ml_model_id"]];
            }
            if (Object.keys(mlModelMapping).length === 0 ) {
                prefetch.setMLModel(-1);
            } else {
                prefetch.setMLModel(Object.keys(mlModelMapping)[0])
            }
        }
        prefetch.addMLModelMapping(mlModelMapping);
    }
}


export function updateMetricStatus(data, showToast){
    let toastMessage = "Could not process your request."
    let metricName = data["metric_name"];
    if (data["status"] === true){
        toastMessage = `${metricName} enabled successfully`
    } else if(data["status"] === false){
        toastMessage = `${metricName} disabled successfully`
    }
    call_post_api(ui_service_ip, "metrics/status", data).then((response) => {
        if (response === undefined) {
            showToast("alert-danger", `"Could not process your request."`, '');
        }
        else if (response.code === 200) {
            showToast("alert-success", toastMessage, '');
        }
        else {
            showToast("alert-danger", `${response.message}`, '');
        }
    });
}

export function handleFollowStatus(props, name, currentStatus, updateFollowStatus) {
    const newStatus = !currentStatus;
    updateFollowStatus(newStatus);

    const teamId = localStorage.getItem('team_id');

    switch (name) {
        case "metric_status":
            let data = props.details.metrics_data;
            data["tenant_id"] = teamId;
            data["status"] = newStatus;
            updateMetricStatus(data, props.showToast);
            setTimeout(() => { props.handleDelete(); }, 2000);
            break;
        default:
            break;
    }
}

export function handleMetricFollow(event) {
    const ex_metric_details = this.props.ex_metric_details;
    if (ex_metric_details === undefined || ex_metric_details === null) {
        return;
    }

    let activeStatus = this.state.active;
    let showTemplatePopup = false;
    if ( this.props.ex_metric_details !== undefined) {
        activeStatus = this.props.ex_metric_details.status_code === 1;

        const metricsDefinition = this.props.ex_metric_details.metrics_definition;
        const jobId = this.props.ex_metric_details.job_id;
        showTemplatePopup = (metricsDefinition.is_template === true) && (jobId === undefined);
    }

    if(showTemplatePopup === true && this.props.configuredCount !== false && this.props.configuredCount === 0) {
        this.props.showConfigureConsistency(ex_metric_details["metric_name"]);
        return;
    }

    if (this.state.active === null && this.props.ex_metric_details !== undefined) {
            activeStatus = this.props.ex_metric_details.status_code === 1;
    }

     let newStatus;
     if(this.state.active === null && activeStatus === false) {
        newStatus = true;
     } else if (this.state.active === null && activeStatus === true)  {
        newStatus = false;
     } else {
        newStatus = !(this.state.active)
     }

     this.setState({active: newStatus});

    // Form the required data and update the entry in the database
    ex_metric_details["tenant_id"] = localStorage.getItem('team_id');
    ex_metric_details["status"] = newStatus;
    updateMetricStatus(ex_metric_details, this.props.showToast);
}

export function setShow(key, value){
    this.setState({[key]: value})
}

export function alertFormSubmit(event) {
    let session = localStorage.getItem("sessionToken");
    this.disableButton();
    const alert_form_data = event;
    let expressions = this.state.expressions;
    let validData = true;
    let gate_type = "quality_gate";
    let quality_gate = [];
    if (expressions.length === 0) {
        validData = false;
        this.enableButton();
        this.props.showToast("alert-danger", "Add Alert Conditions",'')
        return;
    }
    else
    {
        let expression = this.state.expressions.at(-1)
        if(expression !== undefined && (([expression.alert_conditional_op,expression.alert_threshold_val,expression.alert_metrics_options].includes(""))
                                        || (this.state.expressions.length > 1 && [expression.alert_join_options].includes(""))))
        {

            this.setState({errorMsg:true});
            this.enableButton();
            return;
        }
    }
    for (let k = 0; k < expressions.length; k++) {
        let e = expressions[k];
        if (quality_gate.length > 0 && e["alert_join_options"]["value"] !== "") {
            quality_gate.push(e["alert_join_options"]["value"])
        }
        if (e['alert_metrics_options']["value"] === undefined || e['alert_metrics_options']["value"] === null) {
            validData = false;
            this.enableButton();
            this.props.showToast("alert-danger", "Add Valid Alert Conditions",'')
        } else if (e['alert_conditional_op']["value"] === undefined || e['alert_conditional_op']["value"] === null) {
            validData = false;
            this.enableButton();
            this.props.showToast("alert-danger", "Add Valid Alert Conditions",'')
        } else if (e['alert_threshold_val']["value"] === undefined || e['alert_threshold_val']["value"] === null) {
            validData = false;
            this.enableButton();
            this.props.showToast("alert-danger", "Add Valid Alert Conditions",'')
        } else {
            validData = true;
            if (e['alert_metrics_options']["value"] === "Recency") {
                gate_type = "recency_quality_gate";
            }
            quality_gate.push([e['alert_metrics_options']["value"],
            e['alert_conditional_op']["value"],
            e['alert_threshold_val']["value"]]);
        }
    }
    if (validData === true) {
        let trigger = this.state.selectedOptions;
        let consumeGroup = [];
        let pipeline = [];
        for (let i = 0; i < trigger.length; i++) {
            let triggerType = trigger[i]["type"];
            if(triggerType === "email" || triggerType === "slack" || triggerType === "pagerduty" || triggerType === "MicrosoftTeams") {
                let alertFormId = trigger[i]["value"]
                let alertId = alertFormId.split("_")
                let alertIdData = alertId[1]
                consumeGroup.push(alertIdData);
            }
            else if(triggerType === "webhook" || triggerType === "databricks" || triggerType === "airflow" ||
            triggerType === "sagemaker" || triggerType === "lambda" || triggerType === "cloudfunction" || triggerType === "kubeflow") {
                let alertFormId = trigger[i]["value"]
                let alertId = alertFormId.split("_")
                let alertIdData = alertId[1]
                pipeline.push(alertIdData);
            }
        }
        let alerts = this.state.alerts;
        if (trigger.length === 0 && alerts.mechanismOptions !== undefined) {
            let optionList = alerts.mechanismOptions;
            for (let i = 0; i < optionList.length; i++) {
                let optionListType = optionList[i]["type"];
                if(optionListType === "email" || optionListType === "slack" || optionListType === "pagerduty" || optionListType === "MicrosoftTeams") {
                    let alertFormId = optionList[i]["value"]
                    let alertId = alertFormId.split("_")
                    let alertIdData = alertId[1]
                    consumeGroup.push(alertIdData);
                } else if(optionListType === "webhook" || optionListType === "databricks" || optionListType === "airflow" ||
                optionListType === "sagemaker" || optionListType === "lambda" || optionListType === "cloudfunction" || optionListType === "kubeflow") {
                    let alertFormId = optionList[i]["value"]
                    let alertId = alertFormId.split("_")
                    let alertIdData = alertId[1]
                    pipeline.push(alertIdData);
                }
            }
        }
        let compute_type = null;
        if (this.state.alertComputeType !== null){
            compute_type = this.state.alertComputeType["value"];
        }

        let attribute_name = null;
        if (alert_form_data["attribute_name"] !== ""){
            attribute_name = alert_form_data["attribute_name"];
        }


        try {
            let tenant_id = localStorage.getItem('team_id');
            const alert_data = {
                "tenant_id": tenant_id,
                "mas_type": this.props.tab,
                "alert_name": alert_form_data['alert_name'],
                "data_set_id": alert_form_data['data_set_id'],
                "alert_schedule": "@once",
                "alert_period": this.state.alertPeriod,
                "metrics_definition": {
                    "alert_computation_type": compute_type,
                    "attribute_name": attribute_name,
                    "gates": quality_gate,
                    "model": this.state.model,
                    "integration": this.state.dataSource
                },
                "alert_type" : this.state.alertType,
                "consume_group_id":consumeGroup,
                "pipeline_trigger_id":pipeline,
                "types":this.props.tab,
                "metric_type": gate_type
            }

            if(this.props.src_page === "edit_alert") {
                alert_data["old_name"] = this.props.exDetails.alert_name;
                alert_data["old_data_set_id"] = this.props.exDetails.data_set_id;
                alert_data["old_alert_type"] = this.props.exDetails.alert_type;
                alert_data["old_alert_period"] = this.props.exDetails.alert_period;
                alert_data["alert_id"] = this.props.exDetails.alert_id;
                call_update_api(ui_service_ip, "alert", alert_data, session).then((response) => {
                if(response.code === 200) {
                    this.props.showToast("alert-success","alerts updated successfully",'');
                    this.props.closeModal();
                } else {
                    this.props.showToast("alert-danger",response.message,'')
                    this.props.closeModal();
                }
            });
            }else {
                call_post_api(ui_service_ip, "alert", alert_data).then((response) => {
                if (response.code === 200) {
                    this.props.showToast("alert-success", "alert added successfully", "");
                    this.props.handleClick();
                } else {
                    this.props.showToast("alert-danger", response.message, "");
                    this.props.handleClick();
                }
            });
            }


        } catch{
            this.props.showToast("alert-danger", "select all the fields", '');
        }
    }
    postMixpanel('Alert submitted', { });
}

export function handleSubmit(event){
    event.preventDefault();
    let errors = this.state.errors;
    const { name } = event.target;

    if (typeof(errors) !== 'undefined' || errors != null) {
        this.setState({formValid: validateForm(this.state.errors)});
        this.setState({errorCount: countErrors(this.state.errors)});

    }
    let session = localStorage.getItem("sessionToken");
    switch(name) {
        case "requestInvite":
            if ( this.props.userDetails["email"] === this.state.adminEmail ) {
                errors.msg = "Provide Admin's email";
                this.setState({errors: errors});
            } else {
                const requestData = { email: this.props.userDetails["email"], admin_email: this.state.adminEmail,
                                user_id: this.props.userDetails["user_id"], team_id: this.props.userDetails["team_id"] };
                call_post_api(iam_ip, "request/invite", requestData).then((response) => {
                    if ( response === undefined ) {
                        this.props.setEmailPage("Could not process your request.");
                    } else if( response.code === 200 ) {
                        this.props.setEmailPage(response.message);
                    } else {
                        errors.msg = response.message;
                        this.setState({errors:errors});
                    }
                });
            }
            break;
        case "login":
            const data = {email: this.state.email, password: this.state.password};
            call_post_api(iam_ip, "login", data).then((response) =>{
                if(response ===undefined){
                    errors.password = "Could not login";
                    this.setState({errors:errors});
                }else if(response.code === 200) {
                    const now = new Date();
                    const item = {
                                value: response['response']['auth_token'],
                                expiry: now.getTime() + 150*60000
                            };
                    setTimeout(() => browserHistory.push('/'), 9000000);
                    localStorage.setItem('sessionToken', JSON.stringify(item));
                    localStorage.setItem('team_id', response['response']['team_id']);
                    localStorage.setItem('user_name',response['response']['user_name']);
                    localStorage.setItem('user_id',response['response']['user_id']);
                    localStorage.setItem('emailId',response['response']['email']);
                    if (response['response']["setup_status"] === "completed") {
                        window.location = '/configure';
                    } else {
                        window.location = '/setup';
                    }
                } else {
                    errors.password = response.message;
                    this.setState({errors:errors});
                }
            });
            break;
        case "forgot-password":
            const forgot_data= {email: this.state.email};
            call_post_api(iam_ip, "forgot-password", forgot_data).then((response) => {
                errors.email = response.message;
                this.setState({errors:errors, isRedirectToThankYou :true});

            });
            break;
        case "reset-password":
            let token = localStorage.getItem('resetPwdToken');
            const password = {password:this.state.password};
            call_post_api(iam_ip, 'reset-confirm?token='+token, password).then((response) => {
                localStorage.removeItem("resetPwdToken");
                if (response.code === 200) {
                    window.location = '/';
                } else {
                    errors.tokenStatus = response.message;
                    this.setState({errors:errors});
                }
            });
            break
        case "google_signup_complete":
            const google_data = {email: this.state.email, phone: this.state.phone, company: this.state.company};
            const qualdoPlan = localStorage.getItem("qualdoPlan");
            const qualdoPlanCycle = localStorage.getItem("qualdoPlanCycle");
            if (qualdoPlan !== undefined && qualdoPlan !== null) {
                google_data["plan"] = qualdoPlan;
            }

            if (qualdoPlanCycle !== undefined && qualdoPlanCycle !== null) {
                google_data["cycle"] = qualdoPlanCycle;
            }

            call_post_api(iam_ip, 'update_google_user', google_data).then((response) => {
                if(response.code === 200) {
                    const now = new Date();
                    const item = {
                                value: response['response']['auth_token'],
                                expiry: now.getTime() + 150*60000
                            };

                    setTimeout(() => browserHistory.push('/'), 9000000);
                    localStorage.setItem('sessionToken', JSON.stringify(item));
                    localStorage.setItem('team_id', response['response']['team_id']);
                    localStorage.setItem('user_name', response['response']['user_name']);
                    localStorage.setItem('user_id', response['response']['user_id']);
                    localStorage.setItem("org_id", response['response']['org_id']);
                    localStorage.setItem('user_signup_type', response['response']['user_signup_type']);
                    let permissions_encrypt_data = encryptForLocalUsage(response["response"]["permissions"])
                    localStorage.setItem(PERMISSIONS_LOCAL_STORE_KEY, permissions_encrypt_data);
                    window.location = '/setup';
                } else {
                    errors.tokenStatus = response.message;
                    this.setState({errors:errors})
                }
            });
            break;
        case 'integration_test':
            break;
        case "test_integration":
            // TODO To be removed??. Check whether this case is used in code. Remove this case, if it is not used.
            this.closeTestConnection()
            let test_integration_details= this.state.integration_data;
            test_integration_details['env_id'] = this.props.env_id
            let test_integration_data = construct_integration_data(test_integration_details);

            call_post_api(ui_service_ip, 'test_integration', test_integration_data, session).then((response) => {
                if (response.status_code === 200) {
                    this.setState({submit_button:false});
                    this.props.showToast("alert-success",response.status_message,null)
                } else {
                    this.setState({submit_button:true});
                    this.props.showToast("alert-danger",response.status_message,null)
                }
                this.setState({testConnection:response});
            });
            break;
        case 'save_integration':
            this.setState({disableFinishSetup: true});
            let integration_details = this.state.integration_data;
            integration_details['env_id'] = this.props.env_id
            let integration_data = construct_integration_data(integration_details);
            integration_data["integration_id"] = integration_details["integration_id"]
            if(this.props.src_page === "edit_integration")
            {
                call_update_api(ui_service_ip, '/integrations', integration_data, session).then((response) => {
                    if(response.code === 200) {
                        // localStorage.removeItem("env_id");
                        this.props.setEnvData(null)
                        this.props.showToast("alert-success","Datasource updated successfully",'')
                        this.props.closeModal();
                    } else {
                        this.props.showToast("alert-danger",response.message,'')
                        this.setState({disableFinishSetup: false});
                    }

                });
            }else{

            call_post_api(ui_service_ip, 'integrations', integration_data, session).then((response) => {
                if(response.code === 200) {
                    let is_first_datasource = false

                    if (this.props.existingIntegrations === undefined ||
                        this.props.existingIntegrations === null ||
                        this.props.existingIntegrations.length === 0 ){
                        is_first_datasource = true
                    }
                    this.props.setEnvData(null)
                    // localStorage.removeItem("env_id");

                    if((this.props.src_page !== undefined && this.props.src_page ==="setup") || is_first_datasource )  {
                        let setup_update_data = {
                            "team_id": localStorage.getItem('team_id'),
                            "email_id": localStorage.getItem('emailId')
                        };

                        call_post_api(iam_ip, 'update_setup_status',setup_update_data).then((response) => {
                            if(response.code === 200) {
                             localStorage.setItem("DataSetupStatus", true);
                             this.props.setPage(false);
                             this.props.closeTestConnection()
                             this.props.showToast("alert-success","Datasource added successfully","");
                            }else{
                             this.props.showToast("alert-danger",response.message,"")
                            }
                        });
                    } else {
                           this.props.closeTestConnection()
                           this.props.setPage(false);
                           this.props.showToast("alert-success","Datasource added successfully","")
                    }
                } else {
                    this.props.showToast("alert-danger",response.message,'')
                    this.setState({disableFinishSetup: false});
                }
            });
            }
            postMixpanel('Saving Integrations', { });
            break;
        case 'save_pipeline_integration':
                this.setState({disableFinishSetup: true});
                let piepline_integration_details = this.state.integration_data;
                piepline_integration_details['env_id'] = this.props.env_id
                //let pipeline_integration_data = construct_integration_data(integration_details);
                let pipeline_integration_data = piepline_integration_details;
                pipeline_integration_data["pipeline_integration_id"] = piepline_integration_details["integration_id"]
                if(this.props.src_page === "edit_integration")
                {
                    call_update_api(ui_service_ip, '/pipelineintegrations', pipeline_integration_data, session).then((response) => {
                        if(response.code === 200) {
                            // localStorage.removeItem("env_id");
                            this.props.setEnvData(null)
                            this.props.showToast("alert-success","Pipelinesource updated successfully",'')
                            this.props.closeModal();
                        } else {
                            this.props.showToast("alert-danger",response.message,'')
                            this.setState({disableFinishSetup: false});
                        }

                    });
                }else{
                call_post_api(ui_service_ip, 'pipelineintegrations', pipeline_integration_data, session).then((response) => {
                    if(response.code === 200) {
                        let is_first_datasource = false

                        if (this.props.existingIntegrations === undefined ||
                            this.props.existingIntegrations === null ||
                            this.props.existingIntegrations.length === 0 ){
                            is_first_datasource = true
                        }
                        this.props.setEnvData(null)
                        // localStorage.removeItem("env_id");

                        if((this.props.src_page !== undefined && this.props.src_page ==="setup") || is_first_datasource )  {
                            let setup_update_data = {
                                "team_id": localStorage.getItem('team_id'),
                                "email_id": localStorage.getItem('emailId')
                            };

                            call_post_api(iam_ip, 'update_setup_status',setup_update_data).then((response) => {
                                if(response.code === 200) {
                                 localStorage.setItem("DataSetupStatus", true);
                                 this.props.setPage(false);
                                 this.props.closeTestConnection()
                                 this.props.showToast("alert-success","Pipline Source added successfully","");
                                }else{
                                 this.props.showToast("alert-danger",response.message,"")
                                }
                            });
                        } else {
                               this.props.closeTestConnection()
                               this.props.setPage(false);
                               this.props.showToast("alert-success","Pipline Source added successfully","")
                        }
                    } else {
                        this.props.showToast("alert-danger",response.message,'')
                        this.setState({disableFinishSetup: false});
                    }
                });
                }
                postMixpanel('Saving Pipeline Integrations', { });
        break;
        case "setup_add_environment":
            const set_env = new FormData(event.target);
            const set_env_data = {env_name: set_env.get('environment'), tenant_id: localStorage.getItem('team_id')};
            call_post_api(ui_service_ip, 'environment', set_env_data, session).then((response) => {
                if(response.code === 200) {
                    localStorage.setItem("env_id", response['env_data']['env_id']);
                    localStorage.getItem('env_id');
                    this.showToast("alert-success","Environment added successfully",'')
                } else if(response.code === 403 ) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                } else {
                   this.showToast("alert-danger",response.message,'')
                }
            });
            break;
        case "add_environment":
            const env_form_data = new FormData(event.target);
            const env_data = {env_name: env_form_data.get('environment'), tenant_id: localStorage.getItem('team_id')};
            call_post_api(ui_service_ip, 'environment', env_data, session).then((response) => {
                if(response.code === 200) {
                     this.form.reset()
                     this.props.showToast("alert-success","Environment added successfully",'')
                     let tenant_id = localStorage.getItem('team_id')
                     let key = `environments_${tenant_id}`
                     sessionStorage.removeItem(key);
                     this.setState({showButton:false})
                } else {
                    this.props.showToast("alert-danger",response.message,'')
                }
            });
            break;
        case "add_ml_model":
            // TODO This section is not called. We can remove this section after checking
            let modelForm = this.state;
            let formErrors = checkErrorsInAddMLForm(modelForm);
            if (formErrors){
                this.setState({errors: formErrors});
            } else {
            let features = [{"base_line_data_set":modelForm.datasets.trainingData.label,
                                 "target_data_set":modelForm.datasets.servingData.label,
                                 "type":"feature","model_info_details":{"target_column_name1":modelForm.featuresServing,
                                                       "target_column_name2":modelForm.featureTraining,
                                                       "base_line_data_set_id":modelForm.datasets.trainingData.value,
                                                       "target_data_set":modelForm.datasets.servingData.value
                                                       }
                                 },
                            {"target_data_set":"","base_line_data_set":"c",
                                 "type":"accuracy","model_info_details":{
                                                     "target_column_names":modelForm.accuracy,
                                                     "target_data_set":""}
                            },
                            {"target_data_set":modelForm.datasets.prediction.label,"base_line_data_set":"c",
                                 "type":"prediction","model_info_details":{"target_column_names":modelForm.prediction,
                                                                           "target_data_set":modelForm.datasets.prediction.value}
                            },
                            {"target_data_set":modelForm.datasets.response.label,"base_line_data_set":"c",
                                  "type":"response","model_info_details":{"target_column_names":modelForm.response,
                                                                          "target_data_set":modelForm.datasets.response.value}
                            }];
            if (modelForm.datasets.accuracy !== null) {
                features[1] = {"target_data_set":modelForm.datasets.accuracy.label,"base_line_data_set":"c",
                                 "type":"accuracy","model_info_details":{
                                                     "target_column_names":modelForm.accuracy,
                                                     "target_data_set":modelForm.datasets.accuracy.value}
                            };
            }

            let modelDetails = {
                                    "model_deployement":modelForm.modelDeployment
                                    }
            if (modelForm.datasets.rawData !== null) {
                modelDetails["raw_data"]= modelForm.datasets.rawData.label;
                modelDetails["raw_data_id"]=modelForm.datasets.rawData.value;
            }
            if (modelForm.algoType !== null) {
                modelDetails["ml_algo_type"]=modelForm.algoType.value;
            }
            if (this.state.checkedR !== false) {
               modelDetails["accuracy_ratcheting"] = modelForm.data.ratchetingAccuracy;
               modelDetails["precision_ratcheting"] = modelForm.data.ratchetingPrecision;
               modelDetails["recall_ratcheting"] = modelForm.data.ratchetingRecall;
               modelDetails["score_ratcheting"] = modelForm.data.ratchetingScore;
               modelDetails["silhouette_ratcheting"] = modelForm.data.thresholdSilhouette;

            }
            if (this.state.checkedT !== false) {
               modelDetails["accuracy_threshold"] = modelForm.data.thresholdAccuracy;
               modelDetails["rmse_threshold"] = modelForm.data.thresholdRMSE;
               modelDetails["precision_threshold"] = modelForm.data.thresholdPrecision;
               modelDetails["recall_threshold"] = modelForm.data.thresholdRecall;
               modelDetails["score_threshold"] = modelForm.data.thresholdScore;
               modelDetails["silhouette_threshold"] = modelForm.data.thresholdSilhouette;
            }

            let mlInput = {
                    "tenant_id": localStorage.getItem('team_id'),
                    "model_name": modelForm.modelName,
                    "author": localStorage.getItem('user_name'),
                    "version": "ver_0",
                    "ml_stack": modelForm.modelType.value,
                    "integration_id": modelForm.integrationId,
                    "env_id": localStorage.getItem("env_id"),
                    "ml_model_type": modelForm.mlType.value,
                    "features": features,
                    "model_details":modelDetails,
                };


            if (this.props.srcPage === "edit_ml_model") {
                let mlModelDetails = this.props.exMLData;
                let featureEx = mlModelDetails["features"]
                for (let k = 0; k < featureEx.length; k++) {
                    mlInput.features[k]["feature_id"]=featureEx[k]["feature_id"]
                }
                mlInput["model_id"] = mlModelDetails["ml_model_id"]
                call_update_api(ui_service_ip, '/ml-models', mlInput, session).then((response) => {
                    if (response.code === 200) {
                        this.props.showToast("alert-success", "Model is updated successfully", '');
                        this.props.closeModal();
                    } else {
                        this.props.showToast("alert-danger", response.message, '');
                        this.props.closeModal();
                    }
                });
            }
            else {
                call_post_api(ui_service_ip, "ml-models", mlInput, session).then(
                    (response) => {
                        if (response.code === 200) {
                            this.props.setPage();
                            this.props.showToast("alert-success", "ml-model added successfully", '');

                        } else {
                            this.props.setPage();
                            this.props.showToast("alert-danger", response.message, '');
                        }
                    });
            }
            }
            break;
        case "test_ml_model":
            // Not in use
            this.closeTestConnection();
            let mlModelDetails = this.state;
            let mlModelData = constructTestMlModelConnection(mlModelDetails);
            let testMlModelData = { "integration_id": mlModelDetails.integrationId, "file_name": mlModelData };
            call_post_api(ui_service_ip, 'test_dataset_integration', testMlModelData, session).then((response) => {
                if (response.status_code === 200) {
                    this.setState({ submit_button: false });
                    this.setState({ testConnection: response });
                    this.props.showToast("alert-success", response.status_message, null);
                } else {
                    this.setState({ submit_button: true });
                    this.props.showToast("alert-danger", response.status_message, null);
                }

            });
            break;
        case "notification_configure":
            const notificationConfigureData = this.formConsumeData(event);
            const isConsumeEdit = this.props.src_page === "edit_consume";
            let consumeSuccessProcess = isConsumeEdit? 'updated': 'added';
            let consumeErrorProcess = isConsumeEdit? 'update': 'add';
            let consumeResponseHandler = (response) => {
                if(response.code === 200) {
                    if (isConsumeEdit){
                        this.props.closeModal();
                    }

                    this.props.showToast("alert-success",
                        `Notification ${consumeSuccessProcess} successfully`,
                        "");
                }
                else if(response.code === 401){
                    this.props.showToast("alert-danger",response.msg, "");
                }
                else{
                    this.props.showToast("alert-danger",
                        `could not ${consumeErrorProcess} notification`,
                        "");
                }
            };
            if (isConsumeEdit){
                if (!this.requiresConsumeUpdate(notificationConfigureData)) {
                    this.props.showToast("alert-info", 'No changes made', "");
                    this.props.closeModal();
                    return;
                }

                const ex_consume_item = this.props.exConsumeDetails.consume_item;
                let consume_group_id = ex_consume_item.consume_group_id;

                // Update email configuration
                let updateConsumeEndpoint = "consume_group";
                notificationConfigureData["consume_group_id"] = consume_group_id;
                call_update_api(ui_service_ip, updateConsumeEndpoint,
                    notificationConfigureData, session).then(consumeResponseHandler);
            }else{
                call_post_api(ui_service_ip, "consume_group",
                    notificationConfigureData, session).then(consumeResponseHandler);
            }

            break;
        case "metricsForm":
            const metricData = this.constructMetricData(event)
            const isMetricEdit = this.props.src_page === "edit_metrics";

            let successProcess = isMetricEdit? "updated" :"added"
            let errorProcess = isMetricEdit? "update" :"add"

            const responseHandler = (response) => {
                if (response.code === 200) {
                    this.props.showToast("alert-success", `Metrics ${successProcess} successfully`,
                        '')

                    if (isMetricEdit) {
                        // Close the edit metric modal pop-up
                        this.props.closeModal();
                    }

                }
                else if (response.code === 401) {
                    this.props.showToast("alert-danger", `Could not ${errorProcess} Metrics`, '')
                }
                else if (response.code === 403) {
                    localStorage.removeItem("sessionToken");
                }
                else {
                    errors.password = response.message;
                    this.setState({errors: errors});
                }
            }

            if (isMetricEdit) {
                let require_update = this.requires_metric_definition_update(metricData);
                if (require_update === false) {
                    this.props.showToast("alert-info", `No changes made`, '');
                    this.props.closeModal();
                    return;
                }

                const exMetricDetails = this.props.ex_metric_details;

                if (exMetricDetails.job_id !== null) {
                    metricData.job_id = exMetricDetails.job_id
                }

                let metric_id = exMetricDetails.metrics_id
                let tenant_id = metricData.tenant_id
                let end_point = "metrics_meta/metrics";
                metricData["tenant_id"] = tenant_id;
                metricData["metrics_id"] = metric_id;
                call_update_api(ui_service_ip, end_point, metricData).then(responseHandler);
            }
            else {
                call_post_api(ui_service_ip, "metrics_meta/metrics", metricData).then(responseHandler);
            }

            break;
        default:
        break;
    }
}


export function profileMLFilterSubmit(data, startDate, endDate)
{

    let selectedModel = data["mlModels"];

    if (selectedModel !== null && selectedModel !== undefined && selectedModel.length > 0) {
        let firstmlModelId = selectedModel[0];
        let fetchLastMLProfilingTime = getLastMLProfilingTime.bind(this);
        fetchLastMLProfilingTime(firstmlModelId);
    }

    let session = localStorage.getItem("sessionToken");
    if (startDate !=null && endDate !=null) {
        data["payload_start_time"] = startDate;
        data["payload_end_time"] = endDate;
    } else {
        let startDate = moment().subtract(29, 'days').format('YYYY-MM-DD 00:00:00').toString();
        let endDate =  moment().utc().endOf('day').format('YYYY-MM-DD 23:59:59').toString();
        data["payload_start_time"] = startDate;
        data["payload_end_time"] = endDate;
    }
    data["tenant_id"] = localStorage.getItem('team_id');
    call_post_api(ui_service_ip, "ml/relationship/all", data, session).then((response) => {
            if (response === undefined) {
                this.setState({errorOccurred: true});
            } else if (response.code === 200) {
            data["data_set_ids"] = response["data_set_ids"];
            if (response["data_set_ids"].length === 0)
            {
                this.setDataInTab([], [], {"metrics":[]}, null, startDate,endDate, false);
                this.showToast("alert-success","Filter added successfully",'');
                this.setState({loadValue:false});
            } else {
                call_post_api(ui_service_ip, "ml/attributes", data, session).then((res_2) =>{
                    if (res_2["code"] === 200) {
                        call_post_api(ui_service_ip, "ml/metrics/charts", data, session).then((res_3) =>{
                            this.setDataInTab(response["relationships"], res_2["result"], res_3, null, startDate,endDate, false);
                            this.showToast("alert-success","Filter added successfully",'');
                            this.setState({loadValue:false});
                        });
                    }
                });
            }
        } else if(response.code === 401) {
            this.showToast("alert-danger","could not add Filter",'')
            this.closeFilterModal()
        } else if(response.code === 403) {
            localStorage.removeItem("sessionToken");
        } else {
            this.setState({errorOccurred: true});
        }
    });
}

export function profileMLTimeFilterSubmit(data, startDate, endDate)
{
    let session = localStorage.getItem("sessionToken");
    let d = {}
    d["payload_start_time"] = startDate;
    d["payload_end_time"] = endDate;
    d["tenant_id"] = localStorage.getItem('team_id');
    if ('type' in data && data["type"] === "mlModel") {
        d["mlModels"]=[data["value"]];
    } else {
        d["data_source"]= [data["value"]];
    }
    d["tenant_id"] = localStorage.getItem('team_id');
    call_post_api(ui_service_ip, "ml/relationship/all", d, session).then((response) => {
            if (response === undefined) {
                this.setState({errorOccurred: true});
            } else if (response.code === 200) {
            data["data_set_ids"] = response["data_set_ids"];
            if (response["data_set_ids"].length === 0)
            {
                this.setDataInTab([], [], {"metrics":[]}, null, startDate,endDate, false);
                this.showToast("alert-success","Filter added successfully",'');
                this.setState({loadValue:false});
            } else {
                call_post_api(ui_service_ip, "ml/attributes", d, session).then((res_2) =>{
                    if (res_2["code"] === 200) {
                        call_post_api(ui_service_ip, "ml/metrics/charts", d, session).then((res_3) =>{
                            this.setDataInTab(response["relationships"], res_2["result"], res_3, data, startDate, endDate, true);
                            this.showToast("alert-success","Filter added successfully",'');
                             this.setState({loadValue:false});
                        });
                    }
                });
           }
        } else if(response.code === 401) {
            this.showToast("alert-danger","could not add Filter",'')
        } else if(response.code === 403) {
            localStorage.removeItem("sessionToken");
        } else {
            this.setState({errorOccurred: true});
        }
    });
}


export function profileMLDropdownFilterSubmit(data, startDate, endDate)
{

    let selectedmlModel = data["mlModels"];
    if (selectedmlModel !== null && selectedmlModel !== undefined && selectedmlModel.length >0){
        let firstmlModelId = selectedmlModel[0];
        let fetchLastMLProfilingTime = getLastMLProfilingTime.bind(this);
        fetchLastMLProfilingTime(firstmlModelId);
    }

    let session = localStorage.getItem("sessionToken");
    if (startDate !=null && endDate !=null) {
               data["payload_start_time"] = startDate;
               data["payload_end_time"] = endDate;
    } else {
        let startDate = moment().subtract(29, 'days').format('YYYY-MM-DD 00:00:00').toString();
        let endDate =  moment().utc().endOf('day').format('YYYY-MM-DD 23:59:59').toString();
        data["payload_start_time"] = startDate;
        data["payload_end_time"] = endDate;
    }

    data["tenant_id"] = localStorage.getItem('team_id');
    call_post_api(ui_service_ip, "ml/relationship/all", data, session).then((response) => {
            if (response === undefined) {
                this.setState({errorOccurred: true});
            } else if (response.code === 200) {
            data["data_set_ids"] = response["data_set_ids"];
            if (response["data_set_ids"].length === 0)
            {
                this.setDataInTab([], [], {"metrics":[]}, null, startDate,endDate, false);
                this.showToast("alert-success","Filter added successfully",'');
                this.setState({loadValue:false});
            } else {
                call_post_api(ui_service_ip, "ml/attributes", data, session).then((res_2) =>{
                    if (res_2["code"] === 200) {
                        call_post_api(ui_service_ip, "ml/metrics/charts", data, session).then((res_3) =>{
                            this.changeChartBody(response["relationships"], res_2["result"], res_3, null, startDate,endDate);
                            this.showToast("alert-success","Filter added successfully",'');
                            this.setState({loadValue:false});
                        });
                    }
                });
            }
        } else if(response.code === 401) {
            this.showToast("alert-danger","could not add Filter",'')
        } else if(response.code === 403) {
            localStorage.removeItem("sessionToken");
        } else {
            this.setState({errorOccurred: true});
        }
    });
}


export function showTrigger() {
    let env=localStorage.getItem("env");
    if (env === "" || env==="none") {
    } else {
        $(".trigger__to-show").removeClass("d-none");
    }
}

export function joinExistingTeamSignUp(event){
   this.disableButton();
   let {company, email, username, password, phone} = event;
   if (company === '' || email === '' || username === '' || password === '') {
     this.setState({hideSubmitButton: false});
   } else {
       const data = {user_signup_type: 'join_team', company: company, email: email,
                        first_name: username, last_name: username, username: username,
                         password: password, phone_number: phone};
       let protocol = getProtocol(iam_ip);
       fetch(protocol+'//'+iam_ip+'/signup', {
                method: 'POST',
                body: JSON.stringify(data),
                headers:{ 'Content-Type': 'application/json', 'api-type':'qualdo_db_auth' }
            }).then(
                res => res.json()
            ).catch(
                error => console.error("signup api failed")
            ).then(
                  (response) => {
                    if(response.code === 200) {
                        this.props.setTeamPage(response);
                    } else if(response.code === 401 && response.message === "NAVIGATE_TO_NEXT_PAGE"){
                        this.props.setTeamPage(response);
                    } else {
                        this.setState({error: response.message, hideSubmitButton: false});
                    }
                  }
            )
    }
}

export function attributeProfileFilterSubmit(dataSetId,attributeId)
{
      if(dataSetId !== undefined && attributeId !== undefined && dataSetId !=="" && attributeId !== "") {
          let teamId = localStorage.getItem('team_id');
          let attData ={"tenant_id":teamId, "attribute_id":attributeId, "data_set_id":dataSetId};
            call_get_api(ui_service_ip, "attributes/dataprofile",attData).then((response) => {
        if (response === undefined) {
                this.setState({errorOccurred: true});
        } else if (response.code === 200) {
           let apiStatusCode = response.code;
           this.setApiResponse(response, apiStatusCode, true);
        } else {
            this.setState({errorOccurred: true});
        }
    });
      }
}

export function updateUserNotification(notificationId, selectedNotification) {
    let session = localStorage.getItem("sessionToken");
    let update_notification_data = {"read_status": true, "tenant_id": localStorage.getItem('team_id'),user_notification_id:notificationId}
    call_update_api(ui_service_ip, "user_notification",
        update_notification_data, session).then((response) => {
        if (response === undefined || response === null) {
            this.setState({errorOccurred: true});
            return;
        }

        if (response.code === 200) {
            // Update is successful
            selectedNotification["read_status"] = true;

            // Update to redux store unread list
            this.props.markNotificationAsRead(notificationId);

        } else if (response.code === 403) {
            localStorage.removeItem("sessionToken")
            window.location = '/';
        } else {
            this.setState({errorOccurred: true});
        }
    });

}


export function getEndPointWithPreviousDateInfo(all_user_notifications, unread_notifications) {
    let reqEndPoint ={"tenant_id": localStorage.getItem('team_id'), "count":MAX_NOTIFICATIONS_PER_PAGE};

    if (IS_NONE_CHECK.includes(all_user_notifications) || IS_NONE_CHECK.includes(unread_notifications)) {
        return reqEndPoint;
    }

    if (all_user_notifications.length === 0 && unread_notifications.length === 0) {
        return reqEndPoint;
    }

    if (all_user_notifications.length > 0) {
        const prevResult = all_user_notifications[all_user_notifications.length - 1];
        reqEndPoint["previous_date"] = prevResult.created_time;
    }

    if (unread_notifications.length === 0) {
        reqEndPoint["unread_previous_date"]="-1";
    } else {
        const prevResult = unread_notifications[unread_notifications.length - 1];
        reqEndPoint["unread_previous_date"]=prevResult.created_time;
    }

    return reqEndPoint;
}

function fetchNotificationForPageNumber(endPoint, prevDataQuery, page, setNotificationsToRedux) {
    return call_get_api(ui_service_ip, endPoint,prevDataQuery).then((response) => {
        if (response === undefined || response === null || response.code !== 200) {
            this.setState({errorOccurred: true});
            return null;
        }

        // Valid result. Set the result to redux
        response.page = page;
        setNotificationsToRedux(response);

        return response
    });
}


async function getNotifications(endPoint, prevDataQuery, totalPages, notificationReduxStore) {
    let reqEndPoint = endPoint;
    let prevData = prevDataQuery;
    // Get the notifications for the user batch by batch
    for (let currPage = 2; currPage <= totalPages; currPage++) {
        let result = await Promise.resolve(fetchNotificationForPageNumber(reqEndPoint, prevData, currPage, notificationReduxStore));
        if (result === undefined || result === null) {
            break;
        }

        // Form the end point for fetching next set of notifications
        const all_user_notifications = result.user_notification;
        const unread_notifications = result.unread_user_notification;
        let prevDataQuery = getEndPointWithPreviousDateInfo(all_user_notifications, unread_notifications);
        if (Object.keys(prevDataQuery).length === 0) {
            break;
        }

        reqEndPoint = "user_notification";
        prevData = prevDataQuery;
    }

    return true;
}


export function fetchAllUserNotifications(setNotificationsToRedux) {
    const teamID = localStorage.getItem('team_id');
    if (IS_NONE_CHECK.includes(teamID)) {
        // Invalid team id / tenant_id. Skip fetching process
        return;
    }
    let data ={tenant_id: teamID,count:MAX_NOTIFICATIONS_PER_PAGE};
    let endPoint = "user_notification";
    call_get_api(ui_service_ip, endPoint,data).then((response) => {
        if (response === undefined || response === null) {
            this.setState({errorOccurred: true});
            return;
        }

        if (response.code === 200) {
            response.resetData = true;
            response.page = 1;

            // Call redux store function for the storing the notifications
            setNotificationsToRedux(response);

            const all_user_notifications = response.user_notification;
            const unread_notifications = response.unread_user_notification;

            const allNotificationCount = response.all_notification_count;

            let prevDataQuery = getEndPointWithPreviousDateInfo(all_user_notifications, unread_notifications);
            if (Object.keys(prevDataQuery).length === 0) {
                return;
            }
            prevDataQuery = Object.assign({}, data, prevDataQuery);
            // Loop the API call to fetch all the notifications page by page
            // const totalPages = Math.ceil(allNotificationCount / MAX_NOTIFICATIONS_PER_PAGE);
            // This is temperory fix for demo purpose.
            let totalPages = Math.ceil(allNotificationCount / MAX_NOTIFICATIONS_PER_PAGE);
            if(allNotificationCount > 6000){
                totalPages = Math.ceil(6000 / MAX_NOTIFICATIONS_PER_PAGE);
            }

            getNotifications(endPoint, prevDataQuery, totalPages, setNotificationsToRedux).then((res => {
                if (res) {
                    // Fetching notification is success
                } else {
                    console.error("Error while fetching notifications")
                }
            }));

        } else if (response.code === 403) {
            localStorage.removeItem("sessionToken")
            window.location = '/';
        } else {
            this.setState({errorOccurred: true});
        }
    });

}


export function hideRequestNotification(notificationId) {
    let session = localStorage.getItem("sessionToken");
    let update_notification_data = {
        "read_status": true, "hide_status": true,
        "tenant_id": localStorage.getItem('team_id'),
        "user_notification_id":notificationId
    };

    const endPoint = "hide/user_notification";
    call_update_api(ui_service_ip, endPoint, update_notification_data, session).then((response) => {
        if (response === undefined || response === null) {
            // Server didn't return a response
            this.setState({errorOccurred: true});
            return;
        }

        if (response.code === 200) {
            // Status is updated in the server. So remove the current notification from
            // unread list and all notifications list
            this.props.removeNotificationFromList(notificationId);
        } else if (response.code === 403) {
            localStorage.removeItem("sessionToken")
            window.location = '/';
        } else {
            this.setState({errorOccurred: true});
        }
    });

}

export function checkErrorsInAddMLForm(prediction,response){
    let errors = {};

    if(Object.keys(prediction[0]).length === 0){
        errors.featurePrediction = "prediction column is required";
    }

    if(Object.keys(response[0]).length === 0){
        errors.featureResponse = "response column is required";
    }
    if(isEmpty(errors)){
        return {featureResponse:null,featurePrediction:null};
    }
    return errors;
}

export function handleDeleteTeamMate(userDetails,state_details, handleClose){
    handleClose();
    let org_id = localStorage.getItem("org_id");
    let inputData= {"teammate_user_id": userDetails["user_id"],
                    "org_id": org_id, "user_id": localStorage.getItem('user_id')};
    inputData["user_current_status"] = "deleted";
    call_update_api(iam_ip, "team/user", inputData).then((response) => {
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    this.props.showToast("alert-success","User deleted successfully.","");
                } else {
                    this.props.showToast("alert-danger","could not update user status",'');
                }
            });

    }

//export function handleEditTeamMate(userDetails,state_details, handleClose){
//    handleClose();
//    let org_id = localStorage.getItem("org_id");
//    let role_id = null;
//    let role_name = null;
//    if (state_details.role["value"] !== "") {
//        role_id = state_details.role["value"];
//        role_name = state_details.role["label"];
//    }
//    let inputData= {"user_id": userDetails["user_id"],
//                    "org_id": org_id,
//                    "organisation": state_details.organisation,
//                    "department": state_details.department,
//                    "role_id": role_id,
//                    "role_name": role_name,
//                    "team_id": localStorage.getItem('team_id')
//                    };
//
//    let msg = "could not update user status";
//    if (state_details.disableUser === true) {
//        inputData["user_current_status"] = "disabled";
//        msg = "User status changed to disabled successfully";
//    } else if(state_details.disableUser === false) {
//        inputData["user_current_status"] = "active";
//        msg = "User status changed to active successfully"
//    }
//    call_update_api(iam_ip, "team/user", inputData).then((response) => {
//            if (response === undefined) {
//                this.setState({errorOccurred: true});
//            } else if (response.code === 200) {
//                this.props.showToast("alert-success", response.message,"");
//            } else {
//                this.props.showToast("alert-danger",msg,'');
//            }
//    });
//
//
//}

export function postDatabricks (event) {
        this.disableButton();
        let {databricksName, jobId, params, token, url, type} = event;
        let session = localStorage.getItem("sessionToken");
        let databrickData = {
            "tenant_id": localStorage.getItem('team_id'),
            "pipeline_trigger_name": databricksName,
            "pipeline_trigger_type": "databricks",
            "configuration":
                {
                    "url": url,
                    "token": token,
                    "job_id":Number(jobId),
                    "params": params,
                    "type": type
                }

        };

        const is_pipeline_edit = this.props.src_page === "edit_pipeline";
        let pipeline_success_process = is_pipeline_edit ? 'updated' : 'added'
        let pipeline_error_process = is_pipeline_edit ? 'update' : 'add'

        let pipeline_resp_handler = (response) => {
            if (response === undefined) {
               this.props.showToast("alert-danger",
                        `could not ${pipeline_error_process} ${databrickData["pipeline_trigger_type"]}`,
                        "")
            } else if (response.code === 200) {
                    if (is_pipeline_edit) {
                        this.props.closeModal()
                    }
                    this.props.showToast("alert-success",
                        `${databrickData["pipeline_trigger_type"]} ${pipeline_success_process} successfully`,
                        "")
            } else {
                     this.props.showToast("alert-danger",
                        `could not ${pipeline_error_process} ${databrickData["pipeline_trigger_type"]}`,
                        "")
            }
            if(!is_pipeline_edit) {
               this.props.handleCancel();
            }
        }

        if (is_pipeline_edit) {
                if (!this.requires_pipeline_update(databrickData)) {
                    this.props.showToast("alert-info", 'No changes made', "");
                    this.props.closeModal();
                    return;
        }

                // Update pipeline trigger information
        const pipeline_trigger_id = this.props.ex_details.pipeline_trigger_id
        const endPoint = "pipeline_trigger";
        databrickData["pipeline_trigger_id"] = pipeline_trigger_id;
        call_update_api(ui_service_ip, endPoint,
                  databrickData, session).then(pipeline_resp_handler);
        }
        else {
                call_post_api(ui_service_ip, "pipeline_trigger",
                    databrickData, session).then(pipeline_resp_handler);
        }
    }

export function validateDatabricksUrl(event) {
            this.disableButton();
            const {url,token} = event;
            let dataBricks = this.state.dataBricks;
            dataBricks.url = url;
            dataBricks.token = token;
            this.setState({dataBricks:dataBricks});
            let data = {"url": url,"token": token};
            call_post_api(ui_service_ip, "databricks/jobs", data).then((response) => {
                    if (response === undefined) {
                        this.setState({errorOccurred: true});
                    } else if (response.code === 200) {
                           let optionArr = [];
                           let emptyList = []
                           if (response.jobs !== emptyList) {
                                optionArr = response["jobs"].map(function (row) {
                                                return {"label": row["job_name"], "value": row["job_id"], "type": row["task_type"]};
                                            });
                           }
                           if (this.props.ex_details !== undefined) {
                                let id = this.props.ex_details.configuration.job_id
                                let databricksMatch = optionArr.filter(x => x.value === Number(id));
                                    if (databricksMatch.length > 0) {
                                             this.setState({job:databricksMatch});
                                    }
                           }
                           this.setState({options:optionArr});
                           this.setState({check:true,hideSubmitButton:false});
                    } else {
                            this.setState({options: []});
                            this.setState({check:false,hideSubmitButton:false});
                            this.props.showToast("alert-danger","Enter the Valid Databricks","");
                    }
            });
    }


    export function postAWSLambda (event) {
        this.disableButton();
        let {awsLambdaName, awsLambdaFunctionName, awsAccessId, awsSecretKeyAccess, awsRegion, payload} = event;
        let session = localStorage.getItem("sessionToken");
        let lambdaData = {
            "tenant_id": localStorage.getItem('team_id'),
            "pipeline_trigger_name": awsLambdaName,
            "pipeline_trigger_type": "lambda",
            "configuration":
                {
                    "aws_access_key_id": awsAccessId,
                    "aws_secret_access_key": awsSecretKeyAccess,
                    "aws_region_name": awsRegion,
                    "url": awsLambdaFunctionName,
                    "payload": payload
                }
        };

        const is_pipeline_edit = this.props.src_page === "edit_pipeline";
        let pipeline_success_process = is_pipeline_edit ? 'updated' : 'added'
        let pipeline_error_process = is_pipeline_edit ? 'update' : 'add'

        let pipeline_resp_handler = (response) => {
            if (response === undefined) {
               this.setState({errorOccurred: true});
            } else if (response.code === 200) {
                    if (is_pipeline_edit) {
                        this.props.closeModal();
                    }
                    this.props.showToast("alert-success",
                        `${lambdaData["pipeline_trigger_type"]} ${pipeline_success_process} successfully`,
                        "")
            } else {
                     this.props.showToast("alert-danger",
                        `could not ${pipeline_error_process} ${lambdaData["pipeline_trigger_type"]}`,
                        "")
            }
            if(!is_pipeline_edit) {
               this.props.handleCancel();
            }
        }

        if (is_pipeline_edit) {
                if (!this.requires_pipeline_update(lambdaData)) {
                    this.props.showToast("alert-info", 'No changes made', "");
                    this.props.closeModal();
                    return;
        }

                // Update pipeline trigger information
        const pipeline_trigger_id = this.props.ex_details.pipeline_trigger_id
        const endPoint = "pipeline_trigger";
        lambdaData["pipeline_trigger_id"] = pipeline_trigger_id;
        call_update_api(ui_service_ip, endPoint,
                  lambdaData, session).then(pipeline_resp_handler);
        }
        else {
                call_post_api(ui_service_ip, "pipeline_trigger",
                    lambdaData, session).then(pipeline_resp_handler);
        }
    }



export function validateAWSCredentials(event) {
            this.disableButton();
            const {awsAccessId,awsSecretKeyAccess, awsRegion} = event;
            let awslambda = this.state.awsLambda;
            awslambda.awsAccessId = awsAccessId;
            awslambda.awsSecretKeyAccess = awsSecretKeyAccess;
            this.setState({awslambda:awslambda});
            let data = {"aws_access_key_id": awsAccessId,"aws_secret_access_key": awsSecretKeyAccess, "aws_region_name": awsRegion};
            call_post_api(ui_service_ip, "aws/lambda/functions", data).then((response) => {
                    if (response === undefined) {
                        this.setState({errorOccurred: true});
                    } else if (response.code === 200) {
                           let optionArr = [];
                           let emptyList = []
                           if (response.jobs !== emptyList) {
                                optionArr = response["functions"].map(function (row) {
                                                return {"label": row["function_name"], "value": row["function_arn"]};
                                            });
                           }
                           this.setState({check:true,hideSubmitButton:false});
                           if (this.props.ex_details !== undefined) {
                                let url = this.props.ex_details.configuration.url
                                let lambdaMatch = optionArr.filter(x => x.value === url);
                                    if (lambdaMatch.length > 0) {
                                             this.setState({lambda:lambdaMatch});
                                    }
                           }
                           this.setState({options:optionArr});
                           this.setState({check:true,hideSubmitButton:false});
                    } else {
                            this.setState({options: []});
                            this.setState({check:false,hideSubmitButton:false});
                            this.props.showToast("alert-info","Enter the valid AWS crdentials","");
                    }
            });
}


export function postWebhookData(event) {
        this.disableButton();
        let {webhookName,url,header,parameter,dataFormat,methods} = event;
        let session = localStorage.getItem("sessionToken");
        let webhookData = {
            "tenant_id": localStorage.getItem('team_id'),
            "pipeline_trigger_name": webhookName,
            "pipeline_trigger_type": "webhook",
            "configuration":
                {
                    "header": header,
                    "payload": parameter,
                    "url": url,
                    "http_method": methods,
                    "data_format": dataFormat,
                }
        };
        const is_pipeline_edit = this.props.src_page === "edit_pipeline";
        let pipeline_success_process = is_pipeline_edit ? 'updated' : 'added'
        let pipeline_error_process = is_pipeline_edit ? 'update' : 'add'

        let pipeline_resp_handler = (response) => {
            if (response === undefined) {
               this.setState({errorOccurred: true});
            } else if (response.code === 200) {
                    if (is_pipeline_edit) {
                        this.props.closeModal()
                    }
                    this.props.showToast("alert-success",
                        `${webhookData["pipeline_trigger_type"]} ${pipeline_success_process} successfully`,
                        "")
            } else {
                     this.props.showToast("alert-danger",
                        `could not ${pipeline_error_process} ${webhookData["pipeline_trigger_type"]}`,
                        "")
            }
            if(!is_pipeline_edit) {
               this.props.handleCancel();
            }
        }

        if (is_pipeline_edit) {
                if (!this.requires_pipeline_update(webhookData)) {
                    this.props.showToast("alert-info", 'No changes made', "");
                    this.props.closeModal();
                    return;
        }

        // Update pipeline trigger information
        const pipeline_trigger_id = this.props.ex_details.pipeline_trigger_id
        const endPoint = "pipeline_trigger";
        webhookData["pipeline_trigger_id"] = pipeline_trigger_id;
        call_update_api(ui_service_ip, endPoint,
                  webhookData, session).then(pipeline_resp_handler);
        }
        else {
                call_post_api(ui_service_ip, "pipeline_trigger",
                    webhookData, session).then(pipeline_resp_handler);
        }


    }

export function postCloudFunctionData(event) {
    this.disableButton();
    let {cloudFunctionName,url,header,parameter,dataFormat,methods} = event;
    let session = localStorage.getItem("sessionToken");
    let webhookData = {
        "tenant_id": localStorage.getItem('team_id'),
        "pipeline_trigger_name": cloudFunctionName,
        "pipeline_trigger_type": "cloudfunction",
        "configuration":
            {
                "header": header,
                "payload": parameter,
                "url": url,
                "http_method": methods,
                "data_format": dataFormat,
            }
    };

    const is_pipeline_edit = this.props.src_page === "edit_pipeline";
    let pipeline_success_process = is_pipeline_edit ? 'updated' : 'added'
    let pipeline_error_process = is_pipeline_edit ? 'update' : 'add'

    let pipeline_resp_handler = (response) => {
        if (response === undefined) {
            this.setState({errorOccurred: true});
        } else if (response.code === 200) {
                if (is_pipeline_edit) {
                    this.props.closeModal()
                }
                this.props.showToast("alert-success",
                    `${webhookData["pipeline_trigger_type"]} ${pipeline_success_process} successfully`,
                    "")
        } else {
                    this.props.showToast("alert-danger",
                    `could not ${pipeline_error_process} ${webhookData["pipeline_trigger_type"]}`,
                    "")
        }
        if(!is_pipeline_edit) {
            this.props.handleCancel();
        }
    }

    if (is_pipeline_edit) {
            if (!this.requires_pipeline_update(webhookData)) {
                this.props.showToast("alert-info", 'No changes made', "");
                this.props.closeModal();
                return;
    }

            // Update pipeline trigger information
    const pipeline_trigger_id = this.props.ex_details.pipeline_trigger_id
    const endPoint = "pipeline_trigger";
    webhookData["pipeline_trigger_id"] = pipeline_trigger_id;
    call_update_api(ui_service_ip, endPoint,
                webhookData, session).then(pipeline_resp_handler);
    }
    else {
            call_post_api(ui_service_ip, "pipeline_trigger",
                webhookData, session).then(pipeline_resp_handler);
    }


}

export function postEmailData(event) {
    if(this.state.emailData.email.length === 0) {

        this.setState({errorMsg:"Enter a valid Email Id"})

    }else {
        const {groupName,notificationType} = event;
        let schedule = this.state.emailData.schedule;
        this.disableButton();
        let scheduleValue = notificationType === "recurring" ? schedule  : '1hour';
        let emailData = {
        "tenant_id": localStorage.getItem('team_id'),
        "consume_group_name": groupName,
        "consume_group_type": "email",
        "config_details": {
            "email_id": this.state.emailData.email,
            "notification_type" : notificationType,
            "schedule" : scheduleValue,
            "is_default_error_enabled" : this.state.isChecked

            }
        };
        let session = localStorage.getItem("sessionToken");
        const isConsumeEdit = this.props.src_page === "edit_consume";
        let consumeSuccessProcess = isConsumeEdit? 'updated': 'added';
        let consumeErrorProcess = isConsumeEdit? 'update': 'add';
        let consumeResponseHandler = (response) => {
                if(response.code === 200) {
                if (isConsumeEdit){
                    this.props.closeModal();
                }

                this.props.showToast("alert-success",
                    `Notification ${consumeSuccessProcess} successfully`,
                    "");
            }
            else if(response.code === 401){
                this.props.showToast("alert-danger",response.msg, "");
            }
            else{
                this.props.showToast("alert-danger",
                    `could not ${consumeErrorProcess} notification`,
                    "");
            }
            if(!isConsumeEdit) {
                this.props.handleCancel();
            }
        };
        if (isConsumeEdit){
                if (!this.requiresConsumeUpdate(emailData)) {
                this.props.showToast("alert-info", 'No changes made', "");
                this.props.closeModal();
                return;
                }


                const ex_consume_item = this.props.exConsumeDetails.consume_item;
                let consume_group_id = ex_consume_item.consume_group_id;
            // Update email configuration
                let updateConsumeEndpoint = "consume_group";
                emailData["consume_group_id"] = consume_group_id;
                call_update_api(ui_service_ip, updateConsumeEndpoint,
                emailData, session).then(consumeResponseHandler);
        }else{
            call_post_api(ui_service_ip, "consume_group",
                emailData, session).then(consumeResponseHandler);
        }

      }

}

export function validateSlackToken(event) {
       this.disableButton();
       const {token} = event;
       let data = {"token": token}
       call_post_api(ui_service_ip, "slack/channel", data).then((response) => {
                    if (response === undefined) {
                        this.setState({errorOccurred: true});
                    } else if (response.code === 200) {
                           this.setState({channels:response.channels});
                           this.setState({check:true,hideSubmitButton:false});
                    } else {
                            this.setState({channels:[]});
                            this.setState({check:false,hideSubmitButton:false});
                            this.props.showToast("alert-info","Enter the Valid Slack Token","");
                    }
       });


}


export function postSlackData(event) {
    this.disableButton();
    const {token,consumeName,channelName,description} = event;
    let slackPostData = {"tenant_id": localStorage.getItem('team_id'),
            "consume_group_name": consumeName,
            "consume_group_type": "slack",
            "config_details": {
                "token": token,
                "channel_name": channelName,
                "description":description
            }
    };
    let session = localStorage.getItem("sessionToken");
    const isConsumeEdit = this.props.src_page === "edit_consume";
    let consumeSuccessProcess = isConsumeEdit? 'updated': 'added';
    let consumeErrorProcess = isConsumeEdit? 'update': 'add';
    let consumeResponseHandler = (response) => {
                if(response.code === 200) {
                    if (isConsumeEdit){
                        this.props.closeModal();
                    }

                    this.props.showToast("alert-success",
                        `Notification ${consumeSuccessProcess} successfully`,
                        "");
                }
                else if(response.code === 401){
                    this.props.showToast("alert-danger",response.msg, "");
                }
                else{
                    this.props.showToast("alert-danger",
                        `could not ${consumeErrorProcess} notification`,
                        "");
                }

                if(!isConsumeEdit) {
                    this.props.handleCancel();
                }

    };
    if (isConsumeEdit){
        if (!this.requiresConsumeUpdate(slackPostData)) {
                    this.props.showToast("alert-info", 'No changes made', "");
                    this.props.closeModal();
                    return;
        }

        const ex_consume_item = this.props.exConsumeDetails.consume_item;
        let consume_group_id = ex_consume_item.consume_group_id;

                // Update email configuration
        let updateConsumeEndpoint = "consume_group";
        slackPostData["consume_group_id"] = consume_group_id;
        call_update_api(ui_service_ip, updateConsumeEndpoint,
                    slackPostData, session).then(consumeResponseHandler);
    }else{
             call_post_api(ui_service_ip, "consume_group",
                    slackPostData, session).then(consumeResponseHandler);
    }



}


export function postMetricsData(event) {
    this.disableButton();
    const metricData = this.constructMetricData(event);
    const isMetricEdit = this.props.src_page === "edit_metrics";
    let successProcess = isMetricEdit ? "updated" : "added"
    //let errorProcess = isMetricEdit? "update" :"add"

    const responseHandler = (response) => {
        if (response.code === 200) {
            this.props.showToast("alert-success", `Metrics ${successProcess} successfully`,
                '')

            if (isMetricEdit) {
                // Close the edit metric modal pop-up
                this.props.closeModal();
            }

        } else if (response.code === 401) {
            this.props.showToast("alert-danger", response.message, '')
        } else if (response.code === 403) {
            localStorage.removeItem("sessionToken");
        }
        if (!isMetricEdit) {
            this.props.handleClick();
        }
    }
    if (isMetricEdit === false){
        const author = String(localStorage.getItem("user_name"));
        metricData['metrics_definition']['author'] = author;
    }

    if (isMetricEdit) {
        let require_update = this.requires_metric_definition_update(metricData);
        if (require_update === false) {
            this.props.showToast("alert-info", `No changes made`, '');
            this.props.closeModal();
            return;
        }

        const exMetricDetails = this.props.ex_metric_details;

        if (exMetricDetails.job_id !== null) {
            metricData.job_id = exMetricDetails.job_id
        }
        let metric_id = exMetricDetails.metrics_id
        let prevAuthor = exMetricDetails.metrics_definition.author
        let tenant_id = metricData.tenant_id
        let end_point = "metrics_meta/metrics";
        metricData['metrics_definition']['author'] = prevAuthor;
        metricData["tenant_id"] = tenant_id;
        metricData["metrics_id"] = metric_id;
        call_update_api(ui_service_ip, end_point, metricData).then(responseHandler);
    } else {
        call_post_api(ui_service_ip, "metrics_meta/metrics", metricData).then(responseHandler);
    }
    postMixpanel('Custom Metrics created', { });

}


export function formPipelinesource(integrationData) {
    let test_integration_data = {}
    if (integrationData.is_config_details !== undefined && integrationData.is_config_details !== null &&
    integrationData.is_config_details === false) {
       test_integration_data["tenant_id"] = localStorage.getItem('team_id')
       test_integration_data["is_config_details"] = false
       test_integration_data["pipeline_integration_id"] = integrationData.pipeline_integration_id
    } else {
       let data = '';
        switch(integrationData.integration_type) {
           case "azure_blob":
                 data = {"integration_name":integrationData.integration_name,
                                    "data_store_type":"filesystem",
                                    "error_storage": integrationData.integration_config.error_storage,
                                    "bucket_name": integrationData.integration_config.bucket,
                                    "sub_path": integrationData.integration_config.sub_path,
                                    "filetype": integrationData.integration_config.filetype,
                                    "storage_name": integrationData.integration_config.storage_name,
                                    "blob_secret": integrationData.auth_config.secret_key,
                                    "integration_type": "azure_blob"
                 };
                 break
       default:
            break
        }
        test_integration_data = construct_integration_data(data);
    }
    let session = localStorage.getItem("sessionToken");
    test_integration_data["env_id"] = integrationData["env_id"];
    test_integration_data["integration_id"] = integrationData["integration_id"]
    test_integration_data["isTestConnection"] = true;
    call_post_api(ui_service_ip, 'test_pipeline_integration', test_integration_data, session).then((response) => {
               this.setState({test_conn:response});
           });
}

export function formDatasource(integrationData) {
     let test_integration_data = {}
     if (integrationData.is_config_details !== undefined && integrationData.is_config_details !== null &&
     integrationData.is_config_details === false) {
        test_integration_data["tenant_id"] = localStorage.getItem('team_id')
        test_integration_data["is_config_details"] = false
     } else {
        let data = '';
         switch(integrationData.integration_type) {
            case "azure_blob":
                  data = {"integration_name":integrationData.integration_name,
                                     "data_store_type":"filesystem",
                                     "error_storage": integrationData.integration_config.error_storage,
                                     "bucket_name": integrationData.integration_config.bucket,
                                     "sub_path": integrationData.integration_config.sub_path,
                                     "filetype": integrationData.integration_config.filetype,
                                     "storage_name": integrationData.integration_config.storage_name,
                                     "blob_secret": integrationData.auth_config.secret_key,
                                     "integration_type": "azure_blob"
                  };
                  break
             case "azure_data_lake_gen2":
                  data = {"integration_name":integrationData.integration_name,
                                     "data_store_type":"filesystem",
                                     "error_storage": integrationData.integration_config.error_storage,
                                     "bucket_name": integrationData.integration_config.bucket,
                                     "sub_path": integrationData.integration_config.sub_path,
                                     "filetype": integrationData.integration_config.filetype,
                                     "storage_name": integrationData.integration_config.storage_name,
                                     "blob_secret": integrationData.auth_config.secret_key,
                                     "integration_type": "azure_data_lake_gen2"
                  };
                  break
            case "snow_flake":
                 data = {"integration_name":integrationData.integration_name,
                                 "data_store_type":"database",
                                 "integration_type":"snow_flake",
                                 "url": integrationData.integration_config.url,
                                 "database": integrationData.integration_config.database,
                                 "error_storage": integrationData.integration_config.error_storage,
                                 "schema": integrationData.integration_config.schema,
                                 "ware_house": integrationData.integration_config.ware_house,
                                 "user": integrationData.auth_config.user,
                                 "password": integrationData.auth_config.password,
                                 "private_key": integrationData.auth_config.private_key,
                                 "private_key_pwd": integrationData.auth_config.private_key_pwd,
                                 "table_name": integrationData.integration_config.table_name
                                 };
                 break
            case "azure_data_lake":
                data = {"integration_name":integrationData.integration_name,
                                     "data_store_type":"filesystem",
                                     "error_storage": integrationData.integration_config.error_storage,
                                     "bucket_name": integrationData.integration_config.bucket,
                                     "sub_path": integrationData.integration_config.sub_path,
                                     "filetype": integrationData.integration_config.filetype,
                                     "adl_tenant_id": integrationData.auth_config.tenant_id,
                                     "adl_client_id": integrationData.auth_config.adl_client_id,
                                     "secret_key": integrationData.auth_config.secret_key,
                                     "integration_type": "azure_data_lake"
                  };
                break
                case "azure_databricks_table":
                    data = {"integration_name":integrationData.integration_name,
                                         "data_store_type":"database",
                                         "error_storage": integrationData.integration_config.error_storage,
                                         "host": integrationData.integration_config.host,
                                         "secret_key": integrationData.auth_config.secret_key,
                                         "http_path": integrationData.integration_config.http_path,
                                         "catalog": integrationData.integration_config.catalog,
                                         "schema": integrationData.auth_config.schema,
                                         "table": integrationData.auth_config.table_name,
                                         "filetype": integrationData.integration_config.filetype,
                                         "integration_type": "azure_databricks_table"
                      };
                    break
            case "postgresql":
                 data = {"integration_name":integrationData.integration_name,
                                 "data_store_type":"database",
                                 "integration_type":"postgresql",
                                 "host_url": integrationData.integration_config.host_url,
                                 "database_name": integrationData.integration_config.database_name,
                                 "error_storage": integrationData.integration_config.error_storage,
                                 "schema_name": integrationData.integration_config.schema_name,
                                 "user_name": integrationData.auth_config.user_name,
                                 "password": integrationData.auth_config.password,
                                 "table_name": integrationData.integration_config.table_name
                                 };
                 break
            case "redshift":
                 data = {"integration_name":integrationData.integration_name,
                                 "data_store_type":"database",
                                 "integration_type":"redshift",
                                 "host_url": integrationData.integration_config.host_url,
                                 "database_name": integrationData.integration_config.database_name,
                                 "error_storage": integrationData.integration_config.error_storage,
                                 "schema_name": integrationData.integration_config.schema_name,
                                 "user_name": integrationData.auth_config.user_name,
                                 "password": integrationData.auth_config.password,
                                 "table_name": integrationData.integration_config.table_name
                                 };
                 break
            case "athena":
                 data = {"integration_name":integrationData.integration_name,
                                "data_store_type":"database",
                                "integration_type":"athena",
                                "output_location": integrationData.integration_config.output_location,
                                "database_name": integrationData.integration_config.database_name,
                                "region": integrationData.integration_config.region,
                                "accessKye": integrationData.auth_config.accessKye,
                                "secretAccessKey": integrationData.auth_config.password,
                                "table_name": integrationData.integration_config.table_name
                                };
            break
            case "mysql":
                 data = {"integration_name":integrationData.integration_name,
                                 "data_store_type":"database",
                                 "integration_type":"mysql",
                                 "host": integrationData.integration_config.host,
                                 "database": integrationData.integration_config.database,
                                 "error_storage": integrationData.integration_config.error_storage,
                                 "user": integrationData.auth_config.user,
                                 "password": integrationData.auth_config.password,
                                 "table_name": integrationData.integration_config.table_name
                                 };
                 break
            case "azure_sql":
                 data = {"integration_name":integrationData.integration_name,
                                 "data_store_type":"database",
                                 "integration_type":"azure_sql",
                                 "host": integrationData.integration_config.host,
                                 "database": integrationData.integration_config.database,
                                 "schema": integrationData.integration_config.schema,
                                 "error_storage": integrationData.integration_config.error_storage,
                                 "user": integrationData.auth_config.user,
                                 "password": integrationData.auth_config.password,
                                 "table_name": integrationData.integration_config.table_name,
                                 };
                 break
            case "gcp_sql":
                 data = {"integration_name":integrationData.integration_name,
                                 "data_store_type":"database",
                                 "integration_type":"gcp_sql",
                                 "host": integrationData.integration_config.host,
                                 "database": integrationData.integration_config.database,
                                 "schema": integrationData.integration_config.schema,
                                 "error_storage": integrationData.integration_config.error_storage,
                                 "user": integrationData.auth_config.user,
                                 "password": integrationData.auth_config.password,
                                 "table_name": integrationData.integration_config.table_name
                                 };
                 break
            case POWER_BI_CONNECTOR:
                 data = {"integration_name":integrationData.integration_name,
                                 "data_store_type":"database",
                                 "integration_type":POWER_BI_CONNECTOR,
                                 "error_storage": integrationData.integration_config.error_storage,
                                 "workspace": integrationData.integration_config.workspace,
                                 "database": integrationData.integration_config.database,
                                 "table_name": integrationData.integration_config.table_name,
                                 "user_name": integrationData.auth_config.user_name,
                                 "password": integrationData.auth_config.password,
                                 };
                 break
            default:
                break
         }
         test_integration_data = construct_integration_data(data);
     }
     let session = localStorage.getItem("sessionToken");
     test_integration_data["env_id"] = integrationData["env_id"];
     test_integration_data["integration_id"] = integrationData["integration_id"]
     test_integration_data["isTestConnection"] = true;
     call_post_api(ui_service_ip, 'test_integration', test_integration_data, session).then((response) => {
                this.setState({test_conn:response});
            });
}

export function getQualdoIps() {
    call_get_api(ui_service_ip, "/get_qualdo_ips_created").then((response) => {
            if (response.code !== undefined && response.code === 200) {
                let ip_address = response["ip_address"];
                this.setState({ip_address: ip_address});
            }
        });
}


export function postPipelinesource(integrationData) {
    let session = localStorage.getItem("sessionToken");
    if (integrationData) {
            let test_integration_data = integrationData;
            call_post_api(ui_service_ip, 'test_pipeline_integration', test_integration_data, session).then((response) => {
                if (response.status_code === 200) {
                    this.props.setSubmitButton(false);
                    this.props.showToast("alert-success",response.status_message,null);
                    this.setState({hideSubmitButton:false});
                } else {
                    this.props.setSubmitButton(true);
                    this.props.showToast("alert-danger",response.status_message,null);
                    this.setState({hideSubmitButton:false});

                //    this.props.setSubmitButton(false);
                //    this.setState({hideSubmitButton:false});
                }
                this.props.setData(integrationData,response)
               //this.props.setData(integrationData,test_integration_data)

            });
    }
    // else {
    //     // Edit Integration -> Test Connection
    //     let test_integration_data = construct_integration_data(integrationData);
    //     test_integration_data["integration_id"] = integrationData["integration_id"];
    //     call_post_api(ui_service_ip, 'test_integration', test_integration_data, session).then((response) => {
    //         if (response.status_code === 200) {
    //             this.props.setSubmitButton(false);
    //             /** Fix for issue - 1898. Commented the showToast calls since this reloads the page,
    //              editing integration from 2nd, 3rd page is not possible **/

    //             // this.props.showToast("alert-success",response.status_message,null);
    //             this.setState({hideSubmitButton:false});
    //         } else {
    //             this.props.setSubmitButton(true);
    //             // this.props.showToast("alert-danger",response.status_message,null);
    //             this.setState({hideSubmitButton:false});
    //         }
    //         this.props.setData(integrationData,response)
    //     });
    // }
    postMixpanel('New PipelineSource created', { });
}

export function postDatasource(integrationData) {
            //this.props.closeTestConnection();
            let session = localStorage.getItem("sessionToken");
            if (integrationData["error_storage"] !== '' && integrationData["error_storage"] !== undefined) {
                if (integrationData["data_store_type"] === "filesystem" && integrationData["error_storage"] === integrationData["sub_path"]) {
                    this.props.showToast("alert-danger", "Error storage path should not be same as dataset");
                    this.setState({hideSubmitButton:false});
                } else if (integrationData["data_store_type"] === "database" && integrationData["error_storage"] === integrationData["table_name"]){
                    this.props.showToast("alert-danger", "Error storage Table Name and Table Name should not be same");
                } else {
                    let test_integration_data = construct_integration_data(integrationData);
                    test_integration_data["integration_id"] = integrationData["integration_id"]
                    call_post_api(ui_service_ip, 'test_integration', test_integration_data, session).then((response) => {
                        if (response.status_code === 200) {
                            this.props.setSubmitButton(false);
                            this.props.showToast("alert-success",response.status_message,null);
                            this.setState({hideSubmitButton:false});
                        } else {
                            this.props.setSubmitButton(true);
                            this.props.showToast("alert-danger",response.status_message,null);
                            this.setState({hideSubmitButton:false});
                        }
                        this.props.setData(integrationData,response)
                    });
                }
            } else {
                // Edit Integration -> Test Connection
                let test_integration_data = construct_integration_data(integrationData);
                test_integration_data["integration_id"] = integrationData["integration_id"];
                call_post_api(ui_service_ip, 'test_integration', test_integration_data, session).then((response) => {
                    if (response.status_code === 200) {
                        this.props.setSubmitButton(false);
                        /** Fix for issue - 1898. Commented the showToast calls since this reloads the page,
                         editing integration from 2nd, 3rd page is not possible **/

                        // this.props.showToast("alert-success",response.status_message,null);
                        this.setState({hideSubmitButton:false});
                    } else {
                        this.props.setSubmitButton(true);
                        // this.props.showToast("alert-danger",response.status_message,null);
                        this.setState({hideSubmitButton:false});
                    }
                    this.props.setData(integrationData,response)
                });
            }
            postMixpanel('New DataSource created', { });
}

export function postModelData(event) {
    let modelForm = this.state;
    let responseDatasetName = "";
    let responseDataset = "";
    let trainingDatasetName = "";
    let trainingDataset = "";
    let session = localStorage.getItem("sessionToken");
    const {
        modelName,modelAuthored, mlStack, mlType, integrationId, testData, modelData,
        customAlgoType, customMlType, customMlStack,
        algoType, thresholdRecall, thresholdPrecision, thresholdScore,
        thresholdAccuracy, thresholdSilhouette, ratchetingRecall, ratchetingPrecision,
        ratchetingScore, ratchetingAccuracy, ratchetingSilhouette, accuracy, rawData, prediction, thresholdRMSE,
        response, trainingData, servingData, predictionColumn, responseColumn, version,
        recommendationKValue,  predictionGroupBY, predictionItemCol, responseGroupBY,
        responseItemCol,columnType
    } = event;

    this.disableButton();

    if (modelForm.datasets.response !== null && modelForm.datasets.response.label !== undefined){
        responseDatasetName = modelForm.datasets.response.label;
        responseDataset = response;
    }
    else {
        responseDatasetName = "-1";
        responseDataset = "-1";
    }

    if (modelForm.datasets.trainingData !== null && modelForm.datasets.response.label !== undefined){
        trainingDatasetName = modelForm.datasets.trainingData.label;
        trainingDataset = trainingData
    }
    else {
        trainingDatasetName = "-1";
        trainingDataset = "-1";
    }

    const predictionData = {
        "target_data_set": modelForm.datasets.prediction.label, "base_line_data_set": "c",
        "type": "prediction", "model_info_details": {
            "target_column_names": [{"Prediction1": predictionColumn}],
            "target_data_set": prediction
        }
    };

    const responseData = {
            "target_data_set": responseDatasetName, "base_line_data_set": "c",
            "type": "response", "model_info_details": {
                "target_column_names": [{"Response1": responseColumn}],
                "target_data_set": responseDataset
        }
    };


    let features = [{
        "base_line_data_set": trainingDatasetName,
        "target_data_set": modelForm.datasets.servingData.label,
        "type": "feature", "model_info_details": {
            "target_column_serving": modelForm.featuresServing,
            "target_column_training": modelForm.featuresTraining,
            "base_line_data_set_id": trainingDataset,
            "target_data_set": servingData
        }
    },
        {
            "target_data_set": "", "base_line_data_set": "c",
            "type": "accuracy", "model_info_details": {
                "target_column_names": modelForm.accuracy,
                "target_data_set": ""
            }
        },
        predictionData,
        responseData];

    if (accuracy !== '') {
        features[1] = {
            "target_data_set": modelForm.datasets.accuracy.label, "base_line_data_set": "c",
            "type": "accuracy", "model_info_details": {
                "target_column_names": modelForm.accuracy,
                "target_data_set": accuracy
            }
        };
    }

    let modelDetails = {
        "model_deployment": (this.state.modelDeployment === "others" ? this.state.custom_deployment : this.state.modelDeployment),
        "ml_algo_type": algoType === "others" ? customAlgoType : algoType
    }
    if (rawData !== '') {
        modelDetails["raw_data"] = modelForm.datasets.rawData.label;
        modelDetails["raw_data_id"] = rawData;
    }

    if(columnType !== null){
        modelDetails["pred_res_column_type"] = columnType;
    }

    if (testData !== '') {
        modelDetails["test_data"] = modelForm.datasets.testData.label;
        modelDetails["test_data_id"] = testData;
    }

    if (modelData !== '') {
        modelDetails["model_data"] = modelForm.datasets.modelData.label;
        modelDetails["model_data_id"] = modelData;
    }

    if (this.state.checkedR !== false) {
        modelDetails["accuracy_ratcheting"] = ratchetingAccuracy;
        modelDetails["precision_ratcheting"] = ratchetingPrecision;
        modelDetails["recall_ratcheting"] = ratchetingRecall;
        modelDetails["score_ratcheting"] = ratchetingScore;
        modelDetails["silhouette_ratcheting"] = ratchetingSilhouette;
    }
    if (this.state.checkedT !== false) {
        // Keys should be in sync with :
        // qualdo/compute_engine/compute_measure/estimators/ml_constants.py
        // Variables: ACCURACY_THRESHOLD_NAME, other related variables next to it
        modelDetails["accuracy_threshold"] = thresholdAccuracy;
        modelDetails["precision_threshold"] = thresholdPrecision;
        modelDetails["recall_threshold"] = thresholdRecall;
        modelDetails["score_threshold"] = thresholdScore;
        modelDetails["rmse_threshold"] = thresholdRMSE;
        modelDetails["silhouette_threshold"] = thresholdSilhouette;
    }

    //modelDetails["serving_into_prediction"] = modelForm.servingIntoPrediction;

    const selectedMLType = mlType === "others" ? customMlType : mlType;

    if (selectedMLType === "recommendation") {
       modelDetails["recommendation_k_value"] = (recommendationKValue === "" ? null: recommendationKValue);
       if (recommendationKValue !== "" && recommendationKValue !== null ){
           predictionData["model_info_details"].group_by = (predictionGroupBY === "" ? null : predictionGroupBY);
           predictionData["model_info_details"].item_col = (predictionItemCol === "" ? null : predictionItemCol);
           responseData["model_info_details"].group_by = (responseGroupBY === "" ? null : responseGroupBY);
           responseData["model_info_details"].item_col = (responseItemCol === "" ? null : responseItemCol);
       }
    }

    let mlInput = {
        "tenant_id": localStorage.getItem('team_id'),
        "model_name": modelName,
        "author": modelAuthored,
        "version": version,
        "ml_stack": mlStack === "others" ? customMlStack : mlStack,
        "integration_id": integrationId,
        "env_id": localStorage.getItem("env_id"),
        "ml_model_type": selectedMLType,
        "features": features,
        "model_details": modelDetails,
        "username": localStorage.getItem('user_name'),
        "email": localStorage.getItem('emailId')
    };


    if (this.props.srcPage === "edit_ml_model") {
        let mlModelDetails = this.props.exMLData;
        let featureEx = mlModelDetails["features"]
        for (let k = 0; k < featureEx.length; k++) {
            mlInput.features[k]["feature_id"] = featureEx[k]["feature_id"]
        }
        mlInput["model_id"] = mlModelDetails["ml_model_id"]
        call_update_api(ui_service_ip, '/ml-models', mlInput, session).then((response) => {
            if (response.code === 200) {
                this.props.showToast("alert-success", "Model is updated successfully", '');
                this.props.closeModal();
            } else {
                this.props.showToast("alert-danger", response.message, '');
                this.props.closeModal();
            }
        });
    } else {
        call_post_api(ui_service_ip, "ml-models", mlInput, session).then(
            (response) => {
                if (response.code === 200) {
                    this.props.setPage();
                    this.props.showToast("alert-success", "Model added successfully", '');
                    if (response.response !== undefined){
                        let ml_model_id = response.response["ml_model_id"];
                        let ml_model_name =  response.response["ml_model_name"];
                        let details = {"ml_model_id":ml_model_id, "action_type":"add", "ml_model_name":ml_model_name}
                        UpdateReduxStore("Model", this.props.prefetch, details);
                    }
                } else {
                    this.props.setPage();
                    this.props.showToast("alert-danger", response.message, '');
                }
            });
    }
    postMixpanel('New Model Configured', { });
}

export function postDefaultMetrics(event) {
    let { metricsThreshold, windowCount, checkValue } = event;
    this.disableButton();
    let masType = this.props.exMetricDetails.metrics_definition.mas_type;
    const temp_gates = [];
    if (masType === "dqm" || masType === "mlm"){
        let session = localStorage.getItem("sessionToken");
        var date = new Date();
        var currentTime =  date.getUTCFullYear() + ":" + ("0" + (date.getUTCMonth()+1)).slice(-2) + ":" + ("0" + date.getUTCDate()).slice(-2) + " " +
                                ("0" + date.getUTCHours()).slice(-2) + ":" + ("0" + date.getUTCMinutes()).slice(-2) + ":" + ("0" + date.getUTCSeconds()).slice(-2);

        if(this.props.metricName === "Fill Rate"){
        temp_gates.push([this.props.metricName, "<", metricsThreshold]);
        }
        else{
        temp_gates.push([this.props.metricName, ">", metricsThreshold]);
        }
        const metricsUpdated = {
                "metric_name": this.props.metricName,
                "metrics_definition": {
                        "name": this.props.metricName,
                        "version": "1",
                        "dimension": "dataset",
                        "compute_drift": true,
                        "drift_threshold": this.state.driftThreshold,
                        "metrics_threshold": metricsThreshold,
                        "window_count": windowCount,
                        "gates": temp_gates,
                        "checks": (this.state.selectedCheck !== null ? [{"name": this.state.selectedCheck.value, "value": checkValue}]:this.state.existingChecks),
                        "sources": [],
                        "timestamp": currentTime,
                        "formula": this.props.exMetricDetails.metrics_definition.formula,
                        "mas_type": this.props.exMetricDetails.metrics_definition.mas_type,
                        "measures": this.props.exMetricDetails.metrics_definition.measures
                },
                "status": this.state.activeStatus,
                "metric_type": this.props.exMetricDetails.metric_type,
        };

        if ("is_template" in this.props.exMetricDetails.metrics_definition) {
            metricsUpdated["metrics_definition"]["is_template"] =  this.props.exMetricDetails.metrics_definition.is_template;
            metricsUpdated["metrics_definition"]["requires_target"] = this.props.exMetricDetails.metrics_definition.requires_target;
        }
        const team_id = localStorage.getItem('team_id');
        let end_point = "metrics_meta/metrics";
        metricsUpdated["tenant_id"] = team_id;
        metricsUpdated["metrics_id"] = this.props.metricId;
        call_update_api(ui_service_ip, end_point, metricsUpdated, session).then(
                (response) => {
                    if (response === undefined) {
                        this.setState({errorOccurred: true});
                    } else if (response.code === 200) {
                    this.props.updateEnableButton(this.state.activeStatus);
                    this.props.closeModal();
                    this.props.showToast("alert-success", 'metrics updated successfully', "")
                    } else {
                        this.props.closeModal();
                        this.props.showToast("alert-danger", response.message, "")
                    }
                }
        );
    }

}


export function submitPrimaryCardRequest(cardDetail) {
    let paymentID = cardDetail.id;
    let last4 = cardDetail.lastFourDigits;
    let cardType = cardDetail.brand;
    let session = localStorage.getItem("sessionToken");
    let teamID = localStorage.getItem('team_id');
    let data = {"payment_id":paymentID,"tenant_id":teamID}
    let end_point = "customer/payment/primary";

    call_get_api(ui_service_ip, end_point, data, session).then(
        (response) => {
            if (response === undefined) {
                this.showAlertSection("Error!", "Failed to update the primary payment card", false, "danger");
                return;
            }

            if (response.code === 200) {
                let successMsg = `${cardType} (ending with #${last4}) is now your primary payment`;
                this.showAlertSection("Success!", successMsg, false, "success");
                this.updatePrimaryStatusOfCards(paymentID);
            } else {
                let message = "Error while updating primary card";
                this.showAlertSection("Error!", message, false, "danger");
            }
        });
}
export function deleteCardDetails(cardDetails) {
    let session = localStorage.getItem("sessionToken");
    let teamID = localStorage.getItem('team_id');
    let cardID = cardDetails.id;
    let brand = cardDetails.brand;
    let last4Digits = cardDetails.lastFourDigits;
    let end_point = "customer/payment";
    let data = {"tenant_id":teamID,"card_detail_id":cardID};
    call_delete_api(ui_service_ip, end_point, data, session).then(
        (response) => {
            if (response === undefined) {
                this.props.showAlertSection("Error!", "Failed to delete the card details", true, "danger");
                return;
            }

            if (response.code === 200) {
                let successMsg = `${brand} (Card #${last4Digits}) has been removed successfully.`;
                this.props.showAlertSection("Success!", successMsg, false, "success");
                this.props.removeCardDetailFromList(cardID);
            } else {
                let message = response.message;
                let errorMessage = "Failed to delete the card details";
                postUIlogs(`${errorMessage}: ${message}`);
                this.props.showAlertSection("Error!", errorMessage, true, "danger");
            }
        });
}


export function updatePaymentCard(cardDetails) {
    let session = localStorage.getItem("sessionToken");
    let teamID = localStorage.getItem('team_id');
    let end_point = "customer/payment";
    let brand = cardDetails.brand;
    let last4Digits = cardDetails.lastFourDigits;
    cardDetails["tenant_id"] = teamID
    call_update_api(ui_service_ip, end_point, cardDetails, session).then(
        (response)=>{

                if (response === undefined) {
                this.props.showAlertSection("Error!", "Failed to update the card details", true, "danger");
                return;
            }

            if (response.code === 200) {
                let successMsg = `${brand} (Card #${last4Digits}) has been updated successfully.`;
                this.props.showAlertSection("Success!", successMsg, false, "success");
                this.props.updateCardDetailToList(cardDetails);
            } else {
                let message = "Error while updating card detail";
                this.props.showAlertSection("Error!", message, true, "danger");
            }
            this.setState({disableCardButton: false});
    });
}

export function postPaymentNonce(cardDataToPost) {
    let session = localStorage.getItem("sessionToken");
    let teamID = localStorage.getItem('team_id');
    let end_point = "customer/payment";
    cardDataToPost["tenant_id"] = teamID;
    call_post_api(ui_service_ip, end_point, cardDataToPost, session).then(
        (response) => {
            if (response === undefined) {
                this.props.showAlertSection("Error!", "Failed to add the given card", true,"danger");
                return;
            }

            if (response.code === 200) {
                let detail = response.detail;
                detail["id"] = response.payment_detail_id;
                let cardType = detail.card_type;
                let last4 = detail.last_4;
                let successMsg = `${cardType} (Card #${last4}) has been added successfully.`;
                detail["isPrimary"] = cardDataToPost["make_default"];
                this.props.showAlertSection("Success!", successMsg, false, "success");
                this.props.addNewCardDetailToList(detail);
            } else {
                let message = "Error while adding card information. Please recheck the card details";
                let submitBtn = document.getElementById('btn-add-card');
                submitBtn.removeAttribute('disabled');
                this.props.showAlertSection("Error!", message, true, "danger");
            }
        });
}

export function postCustomMetrics (event) {
            this.disableButton();
            const {targetDataSetId,sourceDataset,scheduleInterval} = event;
            let datasetName = this.state.selectedDataSetOption.label;
            let session = localStorage.getItem("sessionToken");

            if (String(sourceDataset) === String(targetDataSetId)) {
                const err = "Target dataset cannot be same as current dataset";
                this.props.closeModal();
                this.props.showToast("alert-danger", err, "");
                return;
            }

            const exMetricDetails = this.props.exMetricDetails;
            const metricsId = exMetricDetails.metrics_id;
            const metricName = exMetricDetails.metric_name;
            let teamId = localStorage.getItem('team_id');

            let data = {
                "metrics_id": metricsId,
                "data_set_id": sourceDataset,
                "target_data_set_id": targetDataSetId,
                "tenant_id": teamId,
                "schedule_interval": scheduleInterval
            }
            const isMetricsEdit = this.props.src_page === "edit_metrics";
            let editProcess = isMetricsEdit ? 'updated' : 'enabled';

            let responseHandler = (response) => {
                if (response === undefined) {
                    this.props.showToast("alert-danger", "Unable to process request", "");
                }
                else if (response.code === 200) {
                    const message = `Metric ${metricName} is ${editProcess} for dataset ${datasetName} successfully`;
                    this.props.closeModal();
                    this.props.showToast("alert-success", message, "");
                }
                else {
                    this.props.closeModal();
                    this.props.showToast("alert-danger", response.message, "");
                }
            };
            const metricEditEndPoint = "metrics/custom";
            if (isMetricsEdit) {
                if (!this.requires_metrics_update(data)) {
                    this.props.showToast("alert-info", 'No changes made', "");
                    this.props.closeModal();
                    return;
                }
                data["job_id"] = exMetricDetails.job_id;
                call_update_api(ui_service_ip, metricEditEndPoint, data, session).then(
                    responseHandler
                );
            }
            else {
                call_post_api(ui_service_ip, metricEditEndPoint, data, session).then(
                    responseHandler
                );
            }

}


export function postConsistencyMetrics (event) {
        this.disableButton();
        let ConsistencyDataSet = this.state.selectedDataSetOption;
        let ConsistencyTargetDataSet= this.state.targetDataSetOption;
        let ConsistencyMetrics = this.state.consistencyTypes;
        if (ConsistencyDataSet === null || ConsistencyTargetDataSet === null) {
            const err = "Select a dataset to associate with metric";
            this.props.closeModal();
            this.props.showToast("alert-danger", err, "");
            return;
        }

        let ConsistencyDataSetId = ConsistencyDataSet["value"];
//        let datasetName = ConsistencyDataSet["label"]
        let ConsistencyTargetDataSetId = ConsistencyTargetDataSet["value"];
        if (String(ConsistencyDataSetId) === String(ConsistencyTargetDataSetId)) {
            const err = "Target dataset cannot be same as current dataset";
            this.props.closeModal();
            this.props.showToast("alert-danger", err, "");
            return;
        }
        let ConsistencyMetricsList = ConsistencyMetrics.map(function (metric) { return metric["value"]; });
        let metrics_list = [];
        let has_all = false;
        if (ConsistencyMetricsList.length > 0) {
            let all_metrics = this.state.consistencyMetricOptions;
            if (ConsistencyMetricsList.includes("all")) {
                 has_all = true;
                 ConsistencyMetricsList = all_metrics.filter(x => x["value"] !== "all");
                 ConsistencyMetricsList = ConsistencyMetricsList.map(function (metric) { return metric["value"]; });
            }
            ConsistencyMetricsList.map(function (id) {
                 let consistency_data = {
                    "metrics_id": id,
                    "data_set_id": ConsistencyDataSetId,
                    "target_data_set_id": ConsistencyTargetDataSetId,
                    "tenant_id": localStorage.getItem('team_id')
                    }
                metrics_list.push(consistency_data);
                return 1;
            });
        }

        let data = {"tenant_id": localStorage.getItem('team_id'), "metrics_list": metrics_list}

        let toastMessage = "Successfully Updated"
        if (has_all === true || metrics_list.length > 1){
            toastMessage = `Data Consistency metrics are enabled successfully`;
        } else if (metrics_list.length === 1) {
            toastMessage = `${ConsistencyMetrics[0]["label"]} enabled successfully`;
        }

        let responseHandler = (response) => {

            if (response === undefined) {
                this.props.showToast("alert-danger", "Unable to process request", "");
            }
            else if (response.code === 200) {
                const message = toastMessage;
                this.props.closeModal();
                this.props.showToast("alert-success", message, "");
            }
            else {
                this.props.closeModal();
                this.props.showToast("alert-danger", response.message, "");
            }
        };
        const metricEditEndPoint = "metrics/consistency";
        let session = localStorage.getItem("sessionToken");
        call_post_api(ui_service_ip, metricEditEndPoint, data, session).then(
            responseHandler
        );

}

export function updateAutoDebitStatus(auto_debit_status) {
    let session = localStorage.getItem("sessionToken");
    let teamId = localStorage.getItem('team_id');

    let postError = {"auto_debit": auto_debit_status,"tenant_id":teamId};
    const autoDebitEndPoint = "payment/autodebit";
    call_update_api(ui_service_ip, autoDebitEndPoint, postError, session).then((res) => {

        // Server is not reachable. We did not receive any response from server
        if (res === undefined) {
            this.props.showToast("alert-danger", "Unable to reach server", "");
            return;
        }

        // Server response is 200. Successful update
        if (res.code === 200) {
            this.setState({autoDebit: auto_debit_status});
            this.props.showToast("alert-success", "Successfully updated", "");
            return;
        }

        // Server response is NOT 200. Unsuccessful update
        this.props.showToast("alert-danger", "Error while updating the auto-debit status", "");
    });

}

export function getIntegrationDetail(integration_id, formType) {
    if (integration_id !== undefined && integration_id !== null) {
        let teamId = localStorage.getItem('team_id');
        let data = {integration_id:integration_id, tenant_id:teamId}
        call_get_api(ui_service_ip, "/integration", data).then((response) => {
            if (response === undefined) {
                this.setState({errorOccurred: true});
            } else if (response.code === 200) {
                let integration_details = response["integration"];
                this.setState({ex_edit_integration_details: integration_details});
            }
            else {
                this.setState({errorOccurred: true});
            }
        });
    }
}



export function getPipelineIntegrationDetail(integration_id, formType) {
    if (integration_id !== undefined && integration_id !== null) {
        let teamId = localStorage.getItem('team_id');
        let data = {pipeline_integration_id:integration_id, tenant_id:teamId}
        call_get_api(ui_service_ip, "/pipelineintegrations", data).then((response) => {
            if (response === undefined) {
                this.setState({errorOccurred: true});
            } else if (response.code === 200) {
                let integration_details = response["integrations"];
                for(let loop=0;loop<integration_details.length;loop++){
                 //   console.log("NEWAPI")
                    if(integration_details[loop]["pipeline_integration_id"] === integration_id) {
                   //     console.log("NEWAPIEnters"+formType)
                        let piparr = [];
                        piparr.push(integration_details[loop])
                     //   console.log(piparr[0]['pipeline_type'])
                        this.setState({
                            ex_edit_integration_details:piparr,
                           // formType:piparr[0]['pipeline_type']
                        });
                    }
                }
            }
            else {
                this.setState({errorOccurred: true});
            }
        });
    }
}



export function submitPlanChangeRequest(data, showToast) {
    const teamId = localStorage.getItem('team_id');
    const session = localStorage.getItem("sessionToken");

    let endPoint = `customer/choose_model?tenant_id=${teamId}`;

    call_post_api(ui_service_ip, endPoint, data, session).then((response) => {
        if (response === undefined) {
            showToast("alert-danger", "Unable to contact server. Try after sometime", "");
        } else if (response.code === 200) {
            this.props.closeDowngradePopup();
            if (showToast !== undefined) {
                showToast("alert-success", response.message, "plan");
            } else {
                showToast("alert-success", response.message, "plan");
            }
        } else {
            this.props.closeDowngradePopup();
            if (showToast !== undefined) {
                showToast("alert-danger", response.message, "plan");
            } else {
                showToast("alert-danger", response.message, "plan");
            }

        }
    });
}


export function deletePlanChangeRequest(change_request_id){
    let end_point = "plan_change_requests";
    let data = {"tenant_id": localStorage.getItem('team_id'),"change_request_id":change_request_id};
    call_delete_api(ui_service_ip, end_point, data).then((response) => {
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    this.props.showToast("alert-success","plan change request deleted successfully.","plan");
                } else {
                    this.props.showToast("alert-danger","could not delete plan change request",'');
                }
            });

    }

export function postModelABConfiguration(data, showToastFunction, isEdit=false) {
    this.disableButton();
    let session = localStorage.getItem("sessionToken");

    if (isEdit) {
         call_update_api(ui_service_ip, "model_ab_data", data, session).then(
        (response) => {
            this.setState({hideSubmitButton: false});
            this.props.closeModalFunction();

            if (response === undefined) {
                // Unable to reach server
                showToastFunction("alert-danger", "Unable to update Model AB", '', '', false);
                return;
            }

            if (response.code !== 200) {
                // We are getting response, but response code is not 200
                showToastFunction("alert-danger", response.message, '', '', false);
                return;
            }

            // Successful post
            showToastFunction("alert-success", "Model AB updated successfully",
                '', '', false);
            let model_ab_data = response.model_ab_data;
            let model_info_map = response.model_info_map;

            // Corresponding function definition: handleModelABAddition() in configure/index.js
            this.props.handleModelABAddition(model_ab_data, model_info_map, isEdit);
        });
        return;
    }

    call_post_api(ui_service_ip, "model_ab_data", data, session).then(
        (response) => {
            this.setState({hideSubmitButton: false});
            this.props.closeModalFunction();

            if (response === undefined) {
                // Unable to reach server
                showToastFunction("alert-danger", "Unable to configure Model AB", '', '', false);
                return;
            }

            if (response.code !== 200) {
                // We are getting response, but response code is not 200
                showToastFunction("alert-danger", response.message, '', '', false);
                return;
            }

            // Successful post
            showToastFunction("alert-success", "Model AB configured successfully",
                '', '', false);
            let model_ab_data = response.model_ab_data;
            let model_info_map = response.model_info_map;

            // Corresponding function definition: handleModelABAddition() in configure/index.js
            this.props.handleModelABAddition(model_ab_data, model_info_map, isEdit);
        });
}


export function validateAirflowUrl(event) {
            this.disableButton();
            const {url, username, password} = event;
            let airflow = this.state.airflow;
            airflow.url = url;
            airflow.username = username;
            airflow.password = password;
            this.setState({airflow:airflow});
            let data = {"url": url, "username": username, "password": password};
            call_post_api(ui_service_ip, "airflow/dags", data).then((response) => {
                    if (response === undefined) {
                        this.props.showToast("alert-danger","Enter the Valid Airflow credentials","");
                    } else if (response.code === 200) {
                           let optionArr = [];
                           let emptyList = []
                           if (response.dags !== emptyList) {
                                optionArr = response["dags"].map(function (row) {
                                                return {"label": row["dag_id"], "value": row["dag_id"]};
                                            });
                           }
                           if (this.props.ex_details !== undefined) {
                                let id = this.props.ex_details.configuration.dag_id
                                let airflowMatch = optionArr.filter(x => x.value === Number(id));
                                    if (airflowMatch.length > 0) {
                                             this.setState({dag:airflowMatch});
                                    }
                           }
                           this.setState({options:optionArr});
                           this.setState({check:true,hideSubmitButton:false});
                    } else {
                            this.setState({options: []});
                            this.setState({check:false,hideSubmitButton:false});
                            this.props.showToast("alert-danger","Enter the Valid Airflow","");
                    }
            });
    }



 export function postAirflow (event) {
        this.disableButton();
        let {airflowName, dagId, payload, url, username, password} = event;
        let session = localStorage.getItem("sessionToken");
        let airflowData = {
            "tenant_id": localStorage.getItem('team_id'),
            "pipeline_trigger_name": airflowName,
            "pipeline_trigger_type": "airflow",
            "configuration":
                {
                    "url": url,
                    "dag_id":dagId,
                    "payload": payload,
                    "username": username,
                    "password": password
                }

        };

        const is_pipeline_edit = this.props.src_page === "edit_pipeline";
        let pipeline_success_process = is_pipeline_edit ? 'updated' : 'added'
        let pipeline_error_process = is_pipeline_edit ? 'update' : 'add'

        let pipeline_resp_handler = (response) => {
            if (response === undefined) {
               this.setState({errorOccurred: true});
            } else if (response.code === 200) {
                    if (is_pipeline_edit) {
                        this.props.closeModal()
                    }
                    this.props.showToast("alert-success",
                        `${airflowData["pipeline_trigger_type"]} ${pipeline_success_process} successfully`,
                        "")
            } else {
                     this.props.showToast("alert-danger",
                        `could not ${pipeline_error_process} ${airflowData["pipeline_trigger_type"]}`,
                        "")
            }
            if(!is_pipeline_edit) {
               this.props.handleCancel();
            }
        }

        if (is_pipeline_edit) {
                if (!this.requires_pipeline_update(airflowData)) {
                    this.props.showToast("alert-info", 'No changes made', "");
                    this.props.closeModal();
                    return;
                }

        // Update pipeline trigger information
        const pipeline_trigger_id = this.props.ex_details.pipeline_trigger_id
        const endPoint = "pipeline_trigger";
        airflowData["pipeline_trigger_id"] = pipeline_trigger_id;
        call_update_api(ui_service_ip, endPoint,
                  airflowData, session).then(pipeline_resp_handler);
        }
        else {
                call_post_api(ui_service_ip, "pipeline_trigger",
                    airflowData, session).then(pipeline_resp_handler);
        }
    }


export function validateAWSSagemakerCredentials(event) {
            this.disableButton();
            const {awsAccessId,awsSecretKeyAccess, awsRegion} = event;
            let awsSagemaker = this.state.awsSagemaker;
            awsSagemaker.awsAccessId = awsAccessId;
            awsSagemaker.awsSecretKeyAccess = awsSecretKeyAccess;
            this.setState({awsSagemaker:awsSagemaker});
            let data = {"aws_access_key_id": awsAccessId,"aws_secret_access_key": awsSecretKeyAccess,
            "aws_region_name": awsRegion};
            call_post_api(ui_service_ip, "aws/sagemaker", data).then((response) => {
                    if (response === undefined) {
                        this.props.showToast("alert-danger","Enter the Valid Sagemaker credentials","");
                    } else if (response.code === 200) {
                           let optionArr = [];
                           let emptyList = []
                           if (response.jobs !== emptyList) {
                                optionArr = response["end_points"].map(function (row) {
                                                return {"label": row["endpoint_name"], "value": row["endpoint_name"]};
                                            });
                           }
                           this.setState({check:true,hideSubmitButton:false});
                           if (this.props.ex_details !== undefined) {
                                let url = this.props.ex_details.configuration.url
                                let sagemakerMatch = optionArr.filter(x => x.value === url);
                                    if (sagemakerMatch.length > 0) {
                                             this.setState({job:sagemakerMatch});
                                    }
                           }
                           this.setState({options:optionArr});
                           this.setState({check:true,hideSubmitButton:false});
                    } else {
                            this.setState({options: []});
                            this.setState({check:false,hideSubmitButton:false});
                            this.props.showToast("alert-info","Enter the valid AWS crdentials","");
                    }
            });
}


export function postAWSSagemaker (event) {
        this.disableButton();
        let {awsSagemakerName, awsAccessId, awsSecretKeyAccess, awsRegion, endpointName, payload} = event;
        let session = localStorage.getItem("sessionToken");
        let sagemakerData = {
            "tenant_id": localStorage.getItem('team_id'),
            "pipeline_trigger_name": awsSagemakerName,
            "pipeline_trigger_type": "sagemaker",
            "configuration":
                {
                    "aws_access_key_id": awsAccessId,
                    "aws_secret_access_key": awsSecretKeyAccess,
                    "aws_region_name": awsRegion,
                    "url": endpointName,
                    "payload": payload
                }
        };

        const is_pipeline_edit = this.props.src_page === "edit_pipeline";
        let pipeline_success_process = is_pipeline_edit ? 'updated' : 'added'
        let pipeline_error_process = is_pipeline_edit ? 'update' : 'add'

        let pipeline_resp_handler = (response) => {
            if (response === undefined) {
               this.setState({errorOccurred: true});
            } else if (response.code === 200) {
                    if (is_pipeline_edit) {
                        this.props.closeModal();
                    }
                    this.props.showToast("alert-success",
                        `${sagemakerData["pipeline_trigger_type"]} ${pipeline_success_process} successfully`,
                        "")
            } else {
                     this.props.showToast("alert-danger",
                        `could not ${pipeline_error_process} ${sagemakerData["pipeline_trigger_type"]}`,
                        "")
            }
            if(!is_pipeline_edit) {
               this.props.handleCancel();
            }
        }

        if (is_pipeline_edit) {
                if (!this.requires_pipeline_update(sagemakerData)) {
                    this.props.showToast("alert-info", 'No changes made', "");
                    this.props.closeModal();
                    return;
        }

        // Update pipeline trigger information
        const pipeline_trigger_id = this.props.ex_details.pipeline_trigger_id
        const endPoint = "pipeline_trigger";
        sagemakerData["pipeline_trigger_id"] = pipeline_trigger_id;
        call_update_api(ui_service_ip, endPoint,
                  sagemakerData, session).then(pipeline_resp_handler);
        }
        else {
                call_post_api(ui_service_ip, "pipeline_trigger",
                    sagemakerData, session).then(pipeline_resp_handler);
        }
    }


export function getAttributeData(dataSetId, attributeId) {
       let team_id = localStorage.getItem('team_id');
       if(dataSetId !== undefined && attributeId !== undefined && dataSetId !== -1 && attributeId !== -1) {
        let attData ={"tenant_id":team_id, "attribute_id":attributeId, "data_set_id":dataSetId};
               call_get_api(ui_service_ip, "attributes/dataprofile", attData).then((response) => {
                if (response === undefined) {
                        this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    let profileData = (response["data_profile"] !== null && response["data_profile"] !== undefined && response["data_profile"].length > 0)? response["data_profile"][0]: null;
                    let size = response["size"]
                    if (size === undefined) {
                        size = 0;
                    }
                    let digits_size = size.toString().length;
                    if (digits_size <= 5) {
                        size = (size / 1000).toFixed(2) + " KB"
                    } else {
                        size = (size / 1000000).toFixed(2) + " MB"
                    }
                    let distributionData = (response["data_distribution"] !== null && response["data_distribution"] !== undefined && response["data_distribution"].length > 0)? response["data_distribution"]: null;
                    if(distributionData !== null) {
                       distributionData['attribute_name'] = response["attribute_name"];
                       distributionData['datasetName'] = response["data_set_name"];
                    }
                    this.convertDataForDiscoverCharts(response["metrics"][0], response["data_set_name"])
                    this.setState({
                        profileData: profileData,
                        profileDataDiscover:profileData,
                        outlier: response["outlier"],
                        businessRule: response["business_rule"],
                        dataType: response["data_type"],
                        nativeType: response["native_type"],
                        size: size, count: response["count"],
                        distributionData: distributionData,
                        isFetched: true,
                        metricsChartData: response["metrics"][0]
                    });
                } else {
                    this.setState({errorOccurred: true});
                }
            });
            }
}


export function getDiscoverPageDataset(dataSetId, datasetName, sectionName) {
    let team_id = localStorage.getItem('team_id');

    if (dataSetId === undefined || dataSetId === null) {
        return;
    }

    let reqInpData = { "tenant_id": team_id, "data_set_id": dataSetId };

    call_get_api(ui_service_ip, "chart_data", reqInpData).then((response) => {
        if (response === undefined) {
            this.setState({ errorOccurred: true });
            return;
        }

        if (response.code !== 200) {
            this.setState({ errorOccurred: true });
            return;
        }


        let data = response["data"];
        let convertedData = [];
        for (let i = 0; i < data.length; i++) {
            convertedData.push(data[i]["chart_data"]);
        }
        let datasetRow;
        for(let i=0;i<convertedData.length;i++){
            if(convertedData[i]["name"] === "Row Count"){
            datasetRow = convertedData[i]["value"][convertedData[i]["value"].length - 1]
            }
        }
        let uniqueRows;
        let duplicateRows;
        for(let i=0;i<convertedData.length;i++){
            if(convertedData[i]["name"] === "Unique Values"){
            uniqueRows = convertedData[i]["value"][convertedData[i]["value"].length - 1]
            duplicateRows = datasetRow - uniqueRows
            }
        }
        this.convertDataForDiscoverCharts(convertedData, datasetName, sectionName)
        let metricsOptions = [];
        // eslint-disable-next-line
        // let datasetRow;
        let selectedData = [];
        let metricData = convertDataForListView(data, datasetName, "a_rec_model_1")
        for(let i=0;i<metricData.length;i++) {
               if(metricData[i]["name"] === "Row Count") {
                 datasetRow = metricData[i]["value"][metricData[i]["value"].length - 1]
               }
              if(metricData[i]["hasDrift"] === false) {
                continue
              }
               selectedData.push(metricData[i]);
               metricsOptions.push({"label": metricData[i]["name"], "value": metricData[i]["name"]});
        }
        this.setState({
            isFetched: true,
            metricsChartData: data,
            datasetRow:datasetRow,
            duplicateRows:duplicateRows
        });

    });
}


export function validateKubeflowUrl(event) {
            this.disableButton();
            const {url,username,password} = event;
            let kubeflow = this.state.kubeflow;
            kubeflow.url = url;
            kubeflow.username = username;
            kubeflow.password = password;
            this.setState({kubeflow:kubeflow});
            let data = {"url": url,"username": username, "password":password};
            call_post_api(ui_service_ip, "kubeflow_pipelines", data).then((response) => {
                    if (response === undefined) {
                        this.props.showToast("alert-danger","Enter the Valid Kubeflow credentials","");
                    } else if (response.code === 200) {
                           let optionArr = [];
                           let emptyList = []
                           if (response.pipelines !== emptyList) {
                                optionArr = response["pipelines"].map(function (row) {
                                                return {"label": row["pipeline_name"], "value": row["pipeline_id"]};
                                            });
                           }
                           if (this.props.ex_details !== undefined) {
                                let id = this.props.ex_details.configuration.pipeline_id
                                let kubeflowMatch = optionArr.filter(x => x.value === Number(id));
                                    if (kubeflowMatch.length > 0) {
                                             this.setState({pipeline:kubeflowMatch});
                                    }
                           }
                           this.setState({options:optionArr});
                           this.setState({check:true,hideSubmitButton:false});
                    } else {
                            this.setState({options: []});
                            this.setState({check:false,hideSubmitButton:false});
                            this.props.showToast("alert-danger","Enter the Valid Kubeflow details","");
                    }
            });
}


export function postKubeflow (event) {
        this.disableButton();
        let {kubeflowName, pipelineId, payload, username, password, url, experiment} = event;
        let session = localStorage.getItem("sessionToken");
        let kubeflowData = {
            "tenant_id": localStorage.getItem('team_id'),
            "pipeline_trigger_name": kubeflowName,
            "pipeline_trigger_type": "kubeflow",
            "configuration":
                {
                    "url": url,
                    "username": username,
                    "password": password,
                    "pipeline_id":pipelineId,
                    "payload": payload,
                    "experiment": experiment
                }

        };

        const is_pipeline_edit = this.props.src_page === "edit_pipeline";
        let pipeline_success_process = is_pipeline_edit ? 'updated' : 'added'
        let pipeline_error_process = is_pipeline_edit ? 'update' : 'add'

        let pipeline_resp_handler = (response) => {
            if (response === undefined) {
               this.props.showToast("alert-danger",
                        `could not ${pipeline_error_process} ${kubeflowData["pipeline_trigger_type"]}`,
                        "")
            } else if (response.code === 200) {
                    if (is_pipeline_edit) {
                        this.props.closeModal()
                    }
                    this.props.showToast("alert-success",
                        `${kubeflowData["pipeline_trigger_type"]} ${pipeline_success_process} successfully`,
                        "")
            } else {
                     this.props.showToast("alert-danger",
                        `could not ${pipeline_error_process} ${kubeflowData["pipeline_trigger_type"]}`,
                        "")
            }
            if(!is_pipeline_edit) {
               this.props.handleCancel();
            }
        }

        if (is_pipeline_edit) {
                if (!this.requires_pipeline_update(kubeflowData)) {
                    this.props.showToast("alert-info", 'No changes made', "");
                    this.props.closeModal();
                    return;
        }

                // Update pipeline trigger information
        const pipeline_trigger_id = this.props.ex_details.pipeline_trigger_id
        const endPoint = "pipeline_trigger";
        kubeflowData["pipeline_trigger_id"] = pipeline_trigger_id;
        call_update_api(ui_service_ip, endPoint,
                  kubeflowData, session).then(pipeline_resp_handler);
        }
        else {
                call_post_api(ui_service_ip, "pipeline_trigger",
                    kubeflowData, session).then(pipeline_resp_handler);
        }
    }


export function postPagerdutyData(event) {
    this.disableButton();
    const {token,consumeName} = event;
    let pagerdutyPostData = {"tenant_id": localStorage.getItem('team_id'),
            "consume_group_name": consumeName,
            "consume_group_type": "pagerduty",
            "config_details": {
                "token": token
            }
    };
    let session = localStorage.getItem("sessionToken");
    const isConsumeEdit = this.props.src_page === "edit_consume";
    let consumeSuccessProcess = isConsumeEdit? 'updated': 'added';
    let consumeErrorProcess = isConsumeEdit? 'update': 'add';
    let consumeResponseHandler = (response) => {
                if(response.code === 200) {
                    if (isConsumeEdit){
                        this.props.closeModal();
                    }

                    this.props.showToast("alert-success",
                        `Notification ${consumeSuccessProcess} successfully`,
                        "");
                }
                else if(response.code === 401){
                    this.props.showToast("alert-danger",response.msg, "");
                }
                else{
                    this.props.showToast("alert-danger",
                        `could not ${consumeErrorProcess} notification`,
                        "");
                }

                if(!isConsumeEdit) {
                    this.props.handleCancel();
                }

    };
    if (isConsumeEdit){
        if (!this.requiresConsumeUpdate(pagerdutyPostData)) {
                    this.props.showToast("alert-info", 'No changes made', "");
                    this.props.closeModal();
                    return;
        }

        const ex_consume_item = this.props.exConsumeDetails.consume_item;
        let consume_group_id = ex_consume_item.consume_group_id;

                // Update email configuration
        let updateConsumeEndpoint = "consume_group";
        pagerdutyPostData["consume_group_id"] = consume_group_id;
        call_update_api(ui_service_ip, updateConsumeEndpoint,
                    pagerdutyPostData, session).then(consumeResponseHandler);
    }else{
             call_post_api(ui_service_ip, "consume_group",
                    pagerdutyPostData, session).then(consumeResponseHandler);
    }

}


export function postmicrosoftTeamsData(event) {
    this.disableButton();
    const {url,consumeName} = event;
    let microsoftTeamsPostData = {"tenant_id": localStorage.getItem('team_id'),
            "consume_group_name": consumeName,
            "consume_group_type": "MicrosoftTeams",
            "config_details": {
                "url": url
            }
    };
    let session = localStorage.getItem("sessionToken");
    const isConsumeEdit = this.props.src_page === "edit_consume";
    let consumeSuccessProcess = isConsumeEdit? 'updated': 'added';
    let consumeErrorProcess = isConsumeEdit? 'update': 'add';
    let consumeResponseHandler = (response) => {
                if(response.code === 200) {
                    if (isConsumeEdit){
                        this.props.closeModal();
                    }

                    this.props.showToast("alert-success",
                        `Notification ${consumeSuccessProcess} successfully`,
                        "");
                }
                else if(response.code === 401){
                    this.props.showToast("alert-danger",response.msg, "");
                }
                else{
                    this.props.showToast("alert-danger",
                        `could not ${consumeErrorProcess} notification`,
                        "");
                }

                if(!isConsumeEdit) {
                    this.props.handleCancel();
                }

    };
    if (isConsumeEdit){
        if (!this.requiresConsumeUpdate(microsoftTeamsPostData)) {
                    this.props.showToast("alert-info", 'No changes made', "");
                    this.props.closeModal();
                    return;
        }

        const ex_consume_item = this.props.exConsumeDetails.consume_item;
        let consume_group_id = ex_consume_item.consume_group_id;

        let updateConsumeEndpoint = "consume_group";
        microsoftTeamsPostData["consume_group_id"] = consume_group_id;
        call_update_api(ui_service_ip, updateConsumeEndpoint,
                    microsoftTeamsPostData, session).then(consumeResponseHandler);
    }else{
             call_post_api(ui_service_ip, "consume_group",
                    microsoftTeamsPostData, session).then(consumeResponseHandler);
    }

}
