import { METRIC_ORDER } from "./constants"

export function sortData(data) {
    let orderedData = []
    METRIC_ORDER.map((data1,index) => {
      let got = false
      data.map((data2) => {
        if (data1 === data2['metrics_name']) {
          got = true
          orderedData.push(data2)
        }
        return null
      })
      if (got === false) {
        let NA = { "metrics_name": data1, "is_enable": "NA", dummyId:index }
        orderedData.push(NA)
      }
      return null
    })
    return orderedData
}

// export function UpdateScheduleTimeValues(data){
//     let orderedTime = []

//     for(let i in data){
//         data[i].map((time)=>{
//         time = Number(time.split(":")[0])

//         let isPM = parseInt(time, 10) >= 12
//         if(time===0 && isPM===false){
//             time = "12 AM"
//         }
//         else if(time === 12){
//             time = "12 PM"
//         }
//         else if (time > 12) {
//             time = time-12+" PM"
//         }
//         else{
//             time = time+" AM"
//         }
//         orderedTime.push({"time":time, "day":i})
//     })
//     }
//     return orderedTime
// }

// export function getNextScheduledTime(timeData){

//     //Start : Get Current Day and time
//     // const IST_FORMATE = 5.5 * 60 * 60 * 1000;
//     let now = new Date();
//     let nowIST = new Date(now.getTime());
//     // let nowIST = new Date(now.getTime()+IST_FORMATE);

//     let timeSplit = String(nowIST).split(":")[0].split(' ')

//     let currentDay = timeSplit[0]
//     currentDay = FULL_DAY_FORMAT[currentDay]
//     // End : Get Current day and time


//     let currentTime = timeSplit[4]
//     if(currentTime > 12){
//         currentTime = currentTime-12+" PM"
//     }
//     else if( currentTime===0){
//         currentTime = "12 AM"
//     }
//     else{
//         currentTime = currentTime+" AM"
//     }

//     let nextDay = null;
//     let weekDays = []
//     let startIndex = WEEKDAYS.indexOf(currentDay);
//     for (let i = startIndex; i < startIndex + 7; i++) {
//     weekDays.push(WEEKDAYS[i % 7]);
//     }
//     weekDays.map((day)=>{
//         timeData.map((data)=>{
//             if(nextDay===null){
//                 if(data.day===day && currentTime < data.time){
//                     nextDay = data
//                 }
//             }
//         })
//     })
// return nextDay
// }
